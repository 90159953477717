import classNames from 'classnames';
import {gsap, TimelineLite} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import Lottie from 'react-lottie-player';
import {render} from 'storyblok-rich-text-react-renderer';

import garantie from '@/assets/components/iconsSection/garantie.json';
import sablier from '@/assets/components/iconsSection/sablier.json';
import zen from '@/assets/components/iconsSection/zen.json';

import * as styles from './IconsSection.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

const OneIcon = ({lottieName, play}) => {
  const lottieData = useRef({});
  const [playingSegment, setPlayingSegment] = useState('intro');
  const [introPlayed, setIntroPlayed] = useState(false);

  const handleComplete = () => {
    if (!introPlayed) {
      setIntroPlayed(true);
    }
  };

  useEffect(() => {
    if (introPlayed && playingSegment === 'intro') {
      setPlayingSegment('loop');
    }
  }, [introPlayed, playingSegment]);

  switch (lottieName) {
    case 'sablier':
      lottieData.current.data = sablier;
      lottieData.current.sequence = {intro: [0, 120], loop: [120, 240]};
      break;
    case 'zen':
      lottieData.current.data = zen;
      lottieData.current.sequence = {intro: [0, 25], loop: [25, 500]};
      break;
    case 'garantie':
      lottieData.current.data = garantie;
      lottieData.current.sequence = {intro: [0, 180], loop: [180, 360]};
      break;
    default:
      lottieData.current.data = sablier;
      lottieData.current.sequence = {intro: [0, 120], loop: [120, 340]};
  }

  return (
    <div className={styles.lottieWrapper}>
      <Lottie
        animationData={lottieData.current.data}
        segments={lottieData.current.sequence[playingSegment]}
        onLoopComplete={() => handleComplete()}
        play={play}
        loop
      />
    </div>
  );
};

const IconsSection = ({lang, title, lotties, noPaddingTop, noPaddingBottom}) => {
  const section = useRef(null);
  const apparitionAnimPlayed = useRef(false);
  const animatedElementsRefs = useRef([]);
  const [lottiePlay, setLottiePlay] = useState(false);

  const addElement = (el) => {
    if (el && !animatedElementsRefs.current.includes(el)) {
      animatedElementsRefs.current.push(el);
    }
  };

  useEffect(() => {
    gsap.to(animatedElementsRefs.current, 0, {y: '+=20', opacity: 0});

    ScrollTrigger.create({
      trigger: section.current,
      start: 'top bottom-=300',
      // markers: true,
      onEnter: function () {
        if (!apparitionAnimPlayed.current) {
          let tl = new TimelineLite();
          tl.to(animatedElementsRefs.current, 0.4, {
            y: '0',
            opacity: 1,
            stagger: 0.1,
            ease: 'power4.inOut',
          });
          tl.add(function () {
            apparitionAnimPlayed.current = true;
            setLottiePlay(true);
          });
        }
      },
    });
  }, []);

  return (
    <section
      className={classNames(
        styles.iconsSection,
        {[styles.noPaddingTop]: noPaddingTop},
        {[styles.noPaddingBottom]: noPaddingBottom}
      )}
      ref={section}
    >
      <div className='container'>
        {title && (
          <div className={`row ${styles.titleRow}`} ref={addElement}>
            <div className='col-8'>{render(title)}</div>
          </div>
        )}
        <div className={`row ${styles.iconsRow}`}>
          {lotties &&
            lotties.map(({isHidden, title, content, lottie}, i) => {
              if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
                return null;
              } else {
                return (
                  <div
                    key={i}
                    className={`col-12 col-md-4 col-lg-3 ${styles.oneIconWrapper}`}
                    ref={addElement}
                  >
                    <OneIcon lottieName={lottie} play={lottiePlay} />
                    <div className={styles.lottieTitleWrapper}>{render(title)}</div>
                    <div className={styles.lottieContentWrapper}>{render(content)}</div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </section>
  );
};

export default IconsSection;

IconsSection.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.object,
  lotties: PropTypes.array,
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
};

OneIcon.propTypes = {
  lottieName: PropTypes.string,
  play: PropTypes.bool,
};
