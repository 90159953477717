import PropTypes from 'prop-types';
import React from 'react';
export const TagDocumentation = ({mode}) => {
  let color = '#FF414D';
  if (mode === 'selected') {
    color = '#ffffff';
  } else if (mode === 'basicDarkBlue' || mode === 'neutral') {
    color = '#003951';
  }
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.0597 4.49512C10.8463 3.67857 12.1537 3.67857 12.9403 4.49512L13.7143 5.29854C14.0465 5.6433 14.4912 5.8575 14.9679 5.90221L16.0786 6.00642C17.2075 6.11233 18.0226 7.13451 17.8747 8.25864L17.7291 9.3647C17.6666 9.83932 17.7765 10.3206 18.0387 10.7211L18.6498 11.6545C19.2708 12.6031 18.9799 13.8778 18.0087 14.463L17.0532 15.0388C16.6432 15.2859 16.3354 15.6718 16.1858 16.1266L15.837 17.1862C15.4826 18.2632 14.3046 18.8305 13.2416 18.4361L12.1957 18.0481C11.7468 17.8816 11.2532 17.8816 10.8043 18.0481L9.75841 18.4361C8.69538 18.8305 7.51744 18.2632 7.16299 17.1862L6.81424 16.1266C6.66458 15.6718 6.35679 15.2859 5.94676 15.0388L4.99125 14.463C4.02013 13.8778 3.7292 12.6031 4.35024 11.6545L4.96129 10.7211C5.2235 10.3206 5.33335 9.83932 5.27089 9.3647L5.12532 8.25864C4.97738 7.13451 5.79254 6.11233 6.92141 6.00642L8.03212 5.90221C8.50875 5.8575 8.95353 5.6433 9.28566 5.29854L10.0597 4.49512Z'
        stroke={color}
        strokeWidth='1.66667'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.0596 15.4951C21.8463 14.6786 23.1537 14.6786 23.9403 15.4951L24.0722 15.632C24.4043 15.9767 24.8491 16.1909 25.3257 16.2356L25.5149 16.2534C26.6438 16.3593 27.459 17.3815 27.311 18.5056L27.2862 18.694C27.2238 19.1687 27.3336 19.6499 27.5958 20.0505L27.6999 20.2095C28.3209 21.1581 28.03 22.4327 27.0589 23.0179L26.8961 23.116C26.4861 23.3631 26.1783 23.7491 26.0287 24.2038L25.9692 24.3843C25.6148 25.4613 24.4369 26.0286 23.3738 25.6342L23.1957 25.5681C22.7468 25.4016 22.2532 25.4016 21.8043 25.5681L21.6262 25.6342C20.5631 26.0286 19.3852 25.4613 19.0308 24.3843L18.9713 24.2038C18.8217 23.7491 18.5139 23.3631 18.1039 23.116L17.9411 23.0179C16.97 22.4327 16.6791 21.1581 17.3001 20.2095L17.4042 20.0505C17.6664 19.6499 17.7762 19.1687 17.7138 18.694L17.689 18.5056C17.541 17.3815 18.3562 16.3593 19.4851 16.2534L19.6743 16.2356C20.1509 16.1909 20.5957 15.9767 20.9278 15.632L21.0596 15.4951Z'
        stroke={color}
        strokeWidth='1.66667'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='11.5' cy='11.5' r='2.75' fill={color} stroke={color} strokeWidth='1.5' />
      <circle cx='22.5' cy='20.5' r='1.75' fill={color} stroke={color} strokeWidth='1.5' />
    </svg>
  );
};

export default TagDocumentation;

TagDocumentation.propTypes = {
  mode: PropTypes.string,
};
