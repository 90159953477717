import PropTypes from 'prop-types';
import React from 'react';

export const RightArrow = ({className}) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.9375 5.84375L12 7.875H2.75C2.3125 7.875 2 8.21875 2 8.625V9.375C2 9.8125 2.3125 10.125 2.75 10.125H12L9.9375 12.1875C9.65625 12.4688 9.65625 12.9688 9.9375 13.25L10.4688 13.7812C10.75 14.0625 11.25 14.0625 11.5312 13.7812L15.75 9.53125C16.0625 9.25 16.0625 8.78125 15.75 8.5L11.5312 4.25C11.25 3.96875 10.75 3.96875 10.4688 4.25L9.9375 4.78125C9.65625 5.0625 9.65625 5.5625 9.9375 5.84375Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default RightArrow;

RightArrow.propTypes = {
  className: PropTypes.string,
};
