import 'swiper/swiper.min.css';

import classNames from 'classnames';
import {gsap} from 'gsap';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import Select from 'react-select';
import SbEditable from 'storyblok-react';
import {render} from 'storyblok-rich-text-react-renderer';
import {Swiper, SwiperSlide} from 'swiper/react';

import CircleSvg from '@/assets/assetsJsx/CircleSvg';
import RightArrow from '@/assets/assetsJsx/RightArrow';
import GlobalFeaturesItem, {Link} from '@/components/GlobalFeaturesItem';

import * as styles from './GlobalFeatures.module.scss';
import {getOptions, Null, Option, ValueContainer} from './GlobalFeaturesHelpers';

const GlobalFeatures = ({title, content, countries = [], link, linkLabel}) => {
  const [active, setActive] = useState(0);
  const options = useMemo(() => getOptions(countries), [countries]);
  const slides = countries[active] && countries[active].items ? countries[active].items : undefined;

  const imagesRefs = useRef([]);
  const illustrationsWrapper = useRef();
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const istransitionning = useRef(false);

  const hasHeader = title || content;
  const hasLink = link && linkLabel;

  const addImage = (el) => {
    if (el && !imagesRefs.current.includes(el)) {
      imagesRefs.current.push(el);
      if (countries.length === imagesRefs.current.length) {
        setImagesLoaded(true);
      }
    }
  };

  function handleChange(option) {
    const index = countries.findIndex(({country}) => country === option.value.country);
    setActive(index > -1 ? index : 0);
    const prevIndex = active;
    switchIllustration(prevIndex, index);
  }

  function switchIllustration(prevIndex, nextIndex) {
    if (!istransitionning.current) {
      istransitionning.current = true;

      //Switch Slide Animation
      const currentSlide = imagesRefs.current[prevIndex];
      const nextSlide = imagesRefs.current[nextIndex];

      let tl = gsap.timeline();
      tl.add(function () {
        gsap.to(illustrationsWrapper.current, {
          rotate: '+=360',
          duration: 1,
          ease: 'Linear.easeNone',
        });
      });
      tl.to(currentSlide, {opacity: 0, duration: 0.2});
      tl.to(nextSlide, {opacity: 1, x: 0, duration: 0.4}, '+=0.2');
      tl.eventCallback('onComplete', function () {
        istransitionning.current = false;
      });
    }
  }

  useEffect(() => {
    if (imagesLoaded) {
      gsap.to(imagesRefs.current[0], {opacity: 1, x: 0, duration: 0});
    }
  }, [imagesLoaded]);

  return (
    <section className={styles.section}>
      <div className={classNames(styles.circleWrapper)}>
        <CircleSvg className={styles.shape} />
      </div>

      <div className={styles.illustrationsWrapper} ref={illustrationsWrapper}>
        {countries &&
          countries[0] &&
          countries.map(({illustration}, i) => {
            if (!illustration) return null;
            return (
              <div key={i} className={classNames(styles.illustrationInnerWrapper)}>
                <img
                  className={classNames(styles.illustration)}
                  src={illustration.filename}
                  alt={illustration.alt}
                  ref={(el) => addImage(el)}
                />
              </div>
            );
          })}
      </div>

      <div className={styles.gridwrapper}>
        {hasHeader && (
          <div className={styles.header}>
            <div className={styles.heading}>{render(title)}</div>
            <div className={styles.content}>{render(content)}</div>
          </div>
        )}

        <Select
          options={options}
          className={styles.select}
          classNamePrefix='react-select'
          isSearchable={false}
          components={{
            IndicatorSeparator: Null,
            Placeholder: Null,
            Input: Null,
            Option,
            ValueContainer,
          }}
          onChange={handleChange}
          value={options[active]}
        />

        {slides && (
          <div className={styles.features}>
            <Swiper
              spaceBetween={10}
              slidesPerView={1.5}
              allowSlideNext={slides.length > 1}
              allowSlidePrev={slides.length > 1}
              breakpoints={{
                992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                  allowSlideNext: false,
                  allowSlidePrev: false,
                },
              }}
            >
              {slides.map((item, index) => {
                if (index >= 3 || item.component !== 'globalFeaturesItem') return null;
                return (
                  <SwiperSlide key={index}>
                    <SbEditable key={item._uid} content={item}>
                      <GlobalFeaturesItem {...item} />
                    </SbEditable>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}

        {hasLink && (
          <div className={styles.link}>
            <RightArrow className={styles.arrow} />
            <Link label={linkLabel} link={link} />
          </div>
        )}
      </div>
    </section>
  );
};

export default GlobalFeatures;

GlobalFeatures.propTypes = {
  title: PropTypes.object,
  content: PropTypes.object,
  countries: PropTypes.array,
  // countries: PropTypes.arrayOf(
  //   PropTypes.exact({
  //     country: PropTypes.string,
  //     illustration: PropTypes.object,
  //     items: PropTypes.arrayOf(PropTypes.object),
  //   })
  // ),
  linkLabel: PropTypes.string,
  link: PropTypes.object,
};
