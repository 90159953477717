import PropTypes from 'prop-types';
import React from 'react';
export const TagPaiement1012 = ({mode}) => {
  let color = '#FF7E55';
  if (mode === 'selected') {
    color = '#ffffff';
  } else if (mode === 'basicDarkBlue' || mode === 'neutral') {
    color = '#003951';
  }
  return (
    <svg width='33' height='30' viewBox='0 0 33 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        width='7.30435'
        height='7.30435'
        rx='3.65217'
        transform='matrix(0 -1 -1 0 7.30435 28)'
        fill={color}
      />
      <rect
        width='7.30435'
        height='7.30435'
        rx='3.65217'
        transform='matrix(0 -1 -1 0 7.30435 9.3913)'
        fill={color}
      />
      <rect
        width='7.30435'
        height='7.30435'
        rx='3.65217'
        transform='matrix(0 -1 -1 0 7.30435 18.6956)'
        fill={color}
      />
      <rect
        width='7.30435'
        height='7.30435'
        rx='3.65217'
        transform='matrix(0 -1 -1 0 15.6087 28)'
        fill={color}
      />
      <rect
        x='-0.5'
        y='-0.5'
        width='6.30435'
        height='6.30435'
        rx='3.15217'
        transform='matrix(0 -1 -1 0 14.6087 8.3913)'
        fill={color}
      />
      <rect
        x='-0.5'
        y='-0.5'
        width='6.30435'
        height='6.30435'
        rx='3.15217'
        transform='matrix(0 -1 -1 0 14.6087 8.3913)'
        stroke={color}
      />
      <rect
        width='7.30435'
        height='7.30435'
        rx='3.65217'
        transform='matrix(0 -1 -1 0 15.6087 18.6956)'
        fill={color}
      />
      <rect
        x='-0.5'
        y='-0.5'
        width='6.30435'
        height='6.30435'
        rx='3.15217'
        transform='matrix(0 -1 -1 0 22.9131 27)'
        fill={color}
      />
      <rect
        x='-0.5'
        y='-0.5'
        width='6.30435'
        height='6.30435'
        rx='3.15217'
        transform='matrix(0 -1 -1 0 22.9131 27)'
        stroke={color}
      />
      <rect
        x='-0.5'
        y='-0.5'
        width='6.30435'
        height='6.30435'
        rx='3.15217'
        transform='matrix(0 -1 -1 0 22.9131 8.3913)'
        fill={color}
      />
      <rect
        x='-0.5'
        y='-0.5'
        width='6.30435'
        height='6.30435'
        rx='3.15217'
        transform='matrix(0 -1 -1 0 22.9131 8.3913)'
        stroke={color}
      />
      <rect
        x='-0.5'
        y='-0.5'
        width='6.30435'
        height='6.30435'
        rx='3.15217'
        transform='matrix(0 -1 -1 0 22.9131 17.6956)'
        fill={color}
      />
      <rect
        x='-0.5'
        y='-0.5'
        width='6.30435'
        height='6.30435'
        rx='3.15217'
        transform='matrix(0 -1 -1 0 22.9131 17.6956)'
        stroke={color}
      />
      <rect
        x='-0.5'
        y='-0.5'
        width='6.30435'
        height='6.30435'
        rx='3.15217'
        transform='matrix(0 -1 -1 0 31.2174 27)'
        fill={color}
      />
      <rect
        x='-0.5'
        y='-0.5'
        width='6.30435'
        height='6.30435'
        rx='3.15217'
        transform='matrix(0 -1 -1 0 31.2174 27)'
        stroke={color}
      />
      <rect
        x='-0.5'
        y='-0.5'
        width='6.30435'
        height='6.30435'
        rx='3.15217'
        transform='matrix(0 -1 -1 0 31.2174 8.3913)'
        stroke={color}
      />
      <rect
        x='-0.5'
        y='-0.5'
        width='6.30435'
        height='6.30435'
        rx='3.15217'
        transform='matrix(0 -1 -1 0 31.2174 17.6956)'
        stroke={color}
      />
    </svg>
  );
};

export default TagPaiement1012;

TagPaiement1012.propTypes = {
  mode: PropTypes.string,
};
