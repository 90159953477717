// extracted by mini-css-extract-plugin
export var pricingPlansSection = "PrincingPlansSection-module--pricingPlansSection--2aSjW";
export var backgroundwhite = "PrincingPlansSection-module--backgroundwhite--2RI3S";
export var backgroundbeige = "PrincingPlansSection-module--backgroundbeige--2NYI6";
export var backgroundblue = "PrincingPlansSection-module--backgroundblue--2OKjC";
export var backgrounddarkblue = "PrincingPlansSection-module--backgrounddarkblue--2J-yX";
export var backgroundvertProduit = "PrincingPlansSection-module--backgroundvertProduit--1X5Y1";
export var backgroundjauneProduit = "PrincingPlansSection-module--backgroundjauneProduit--3dCiJ";
export var backgroundorangeProduit = "PrincingPlansSection-module--backgroundorangeProduit--1pQVo";
export var backgroundrougeDistrib = "PrincingPlansSection-module--backgroundrougeDistrib--EfTP8";
export var rowPricingPlans = "PrincingPlansSection-module--rowPricingPlans--2ny72";
export var planSelectorCol = "PrincingPlansSection-module--planSelectorCol--3jKlX";
export var planSelectorWrapper = "PrincingPlansSection-module--planSelectorWrapper--13c9F";
export var onePricingPlanName = "PrincingPlansSection-module--onePricingPlanName--3ECG_";
export var icon = "PrincingPlansSection-module--icon--1E9Ox";
export var name = "PrincingPlansSection-module--name--3VWbf";
export var highlighted = "PrincingPlansSection-module--highlighted--2er6n";
export var pricingPlanColorblue = "PrincingPlansSection-module--pricingPlanColorblue--3IcX9";
export var pricingPlanColorlightBlue = "PrincingPlansSection-module--pricingPlanColorlightBlue--QcV0k";
export var pricingPlanColorvertProduit = "PrincingPlansSection-module--pricingPlanColorvertProduit--1YY8p";
export var pricingPlanColorjauneProduit = "PrincingPlansSection-module--pricingPlanColorjauneProduit--34gu0";
export var pricingPlanColororangeProduit = "PrincingPlansSection-module--pricingPlanColororangeProduit--3bw8Z";
export var pricingPlanColorrougeDistrib = "PrincingPlansSection-module--pricingPlanColorrougeDistrib--3h0HV";
export var planSelectorButtonLink = "PrincingPlansSection-module--planSelectorButtonLink--1Y0Aw";
export var planDetailCol = "PrincingPlansSection-module--planDetailCol--VYYi9";
export var onePricingPlanDetail = "PrincingPlansSection-module--onePricingPlanDetail--2RgjD";
export var onePricingPlanDetailItem = "PrincingPlansSection-module--onePricingPlanDetailItem--19_H9";
export var onePricingPlanDetailTag = "PrincingPlansSection-module--onePricingPlanDetailTag--3dHc9";
export var onePricingPlanDetailItemRow = "PrincingPlansSection-module--onePricingPlanDetailItemRow--2WJda";
export var contentCol = "PrincingPlansSection-module--contentCol--2_5iV";
export var keyFigureCol = "PrincingPlansSection-module--keyFigureCol--32pN0";
export var keyFigure = "PrincingPlansSection-module--keyFigure--1SKI1";
export var subKeyFigureContent = "PrincingPlansSection-module--subKeyFigureContent--3dbHk";