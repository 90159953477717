import classNames from 'classnames';
import {gsap} from 'gsap';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import HalfCircleSvg from '@/assets/assetsJsx/HalfCircleSvg';
import Button from '@/components/Button/Button';

import * as styles from './CtaBannerPrimary.module.scss';

const CtaBannerPrimary = ({
  backgroundColorBanner,
  title,
  subtitle,
  CTA,
  list,
  listType,
  themeColor,
  circleStyle,
}) => {
  const svgCircle = useRef(null);
  const handleMouseEnter = () => {
    gsap.to(svgCircle.current, 0.4, {scale: 1.4, x: '-=40', ease: 'power4.inOut'});
  };
  const handleMouseLeave = () => {
    gsap.to(svgCircle.current, 0.4, {scale: 1, x: 0, ease: 'power4.inOut'});
  };

  const lightThemes = ['beige', 'white'];
  return (
    <div
      className={classNames(styles.ctaBannerPrimary, {
        [styles.lightMode]: lightThemes.includes(backgroundColorBanner),
      })}
    >
      <div className={styles.wrapper}>
        <div
          className={classNames(styles.innerWrapper, styles['background' + backgroundColorBanner])}
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          {(title || subtitle || CTA[0]) && (
            <div className={styles.contentWrapper}>
              {title && <div className={styles.title}>{render(title)}</div>}
              {subtitle && <div className={styles.subtitle}>{render(subtitle)}</div>}
              {CTA[0] && (
                <div className={styles.cta}>
                  {CTA[0].button.map((cta) => (
                    <Button key={cta._uid} {...cta} />
                  ))}
                </div>
              )}
            </div>
          )}

          {list && (
            <div className={styles.listWrapper}>
              <div className={classNames(styles.list, styles[listType], styles[themeColor])}>
                {render(list)}
              </div>
            </div>
          )}

          <div className={styles.circleSvg} ref={svgCircle}>
            <HalfCircleSvg {...{themeColor, circleStyle}} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaBannerPrimary;

CtaBannerPrimary.propTypes = {
  backgroundColorBanner: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.object.isRequired,
  CTA: PropTypes.array,
  list: PropTypes.object,
  listType: PropTypes.string,
  circleStyle: PropTypes.string,
  themeColor: PropTypes.string,
};
