import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {getSvg} from '@/common/svgMap';

import * as styles from './GlobalTag.module.scss';

const GlobalTag = ({title, icon, mode, color}) => {
  let svgIcon = false;
  if (icon) {
    svgIcon = getSvg(icon, mode);
  }
  return (
    <span
      className={classNames(
        styles.globalTagComponent,
        styles['background' + color],
        styles['border' + color],
        styles[mode],
        {[styles.withIcon]: svgIcon}
      )}
    >
      {svgIcon && <span className={styles.icon}>{svgIcon}</span>}
      <span className={classNames(styles.title, styles['textcolor' + color])}>{title}</span>
    </span>
  );
};

export default GlobalTag;

GlobalTag.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  mode: PropTypes.string,
  color: PropTypes.string,
};
