import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import loadingIcon from '@/assets/components/form/loadingIcon.svg';

import * as styles from './SendingForm.module.scss';

const SendingForm = ({currentlyBeingSent, title, subtitle, CTA}) => {
  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <div className={styles.currentlyBeingSent} ref={currentlyBeingSent}>
      <div className={classNames('row')}>
        <div className={classNames('col-12', styles.confirmIconCol)}>
          <img src={loadingIcon} alt='loading icon' className={styles.loader} />
        </div>
        <div className={classNames('col-12', styles.confirmTextCol)}>
          {render(title)}
          {render(subtitle)}
        </div>
        <div className={classNames('col-12', styles.confirmButtonCol)}>
          {CTA &&
            CTA[0] &&
            CTA[0].button.map((cta) => (
              <button key={cta._uid} onClick={refreshPage}>
                {cta.label}
              </button>
            ))}
          {/* <button onClick={refreshPage}>{'Rafraichir la page'}</button> */}
        </div>
      </div>
    </div>
  );
};

export default SendingForm;

SendingForm.propTypes = {
  currentlyBeingSent: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  CTA: PropTypes.array,
};
