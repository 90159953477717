// extracted by mini-css-extract-plugin
export var TestimonialsComponent = "Testimonials-module--TestimonialsComponent--21hJt";
export var backgroundtransparent = "Testimonials-module--backgroundtransparent--1muRw";
export var backgroundblack = "Testimonials-module--backgroundblack--3dDUK";
export var backgroundwhite = "Testimonials-module--backgroundwhite--2ZPJc";
export var backgroundbeige = "Testimonials-module--backgroundbeige--gY3iI";
export var backgroundgrey = "Testimonials-module--backgroundgrey--1jRUW";
export var backgroundgreyLabel = "Testimonials-module--backgroundgrey-label--21g73";
export var backgroundblue = "Testimonials-module--backgroundblue--2vhxJ";
export var backgrounddarkBlue = "Testimonials-module--backgrounddark-blue--3UV0a";
export var backgroundlightBlue = "Testimonials-module--backgroundlight-blue--qK9fH";
export var backgroundpressedBlue = "Testimonials-module--backgroundpressed-blue--3oD32";
export var backgroundorangeProduit = "Testimonials-module--backgroundorange-produit--14-sm";
export var backgroundjauneProduit = "Testimonials-module--backgroundjaune-produit--1cWwl";
export var backgroundvertProduit = "Testimonials-module--backgroundvert-produit--1rQeP";
export var backgroundrougeDistrib = "Testimonials-module--backgroundrouge-distrib--33wu5";
export var wrapper = "Testimonials-module--wrapper--1pmv4";
export var sliderWrapper = "Testimonials-module--sliderWrapper--cCw1K";
export var sliderSwitches = "Testimonials-module--sliderSwitches--2yHF1";
export var selectedButton = "Testimonials-module--selectedButton--3BtfE";
export var sliderInnerWrapper = "Testimonials-module--sliderInnerWrapper--Unq6g";
export var slider = "Testimonials-module--slider--1RmU8";
export var sliderTrack = "Testimonials-module--sliderTrack--s7izg";
export var one__slide = "Testimonials-module--one__slide--2vzgE";
export var one__slide__inner = "Testimonials-module--one__slide__inner--121uZ";
export var quoteSvgWrapper = "Testimonials-module--quoteSvgWrapper--2p7vh";
export var quoteWrapper = "Testimonials-module--quoteWrapper--2HkM0";
export var quoteAuthor = "Testimonials-module--quoteAuthor--dzEKE";
export var imgWrapper = "Testimonials-module--imgWrapper--mZtA7";
export var imgInnerWrapper = "Testimonials-module--imgInnerWrapper--Ik0Xo";
export var canvasWrapper = "Testimonials-module--canvasWrapper--3LDgr";