import React from 'react';

import TagBoutiqueSvg from '@/assets/assetsJsx/tags/TagBoutique';
import TagDocumentationSvg from '@/assets/assetsJsx/tags/TagDocumentation';
import TagEnligneSvg from '@/assets/assetsJsx/tags/TagEnligne';
import TagFaqSvg from '@/assets/assetsJsx/tags/TagFaq';
import TagPaiement234Svg from '@/assets/assetsJsx/tags/TagPaiement234';
import TagPaiement1012Svg from '@/assets/assetsJsx/tags/TagPaiement1012';
import TagPartnersSvg from '@/assets/assetsJsx/tags/TagPartenaires';
import TagParticuliersSvg from '@/assets/assetsJsx/tags/TagParticuliers';
import TagPayLaterSvg from '@/assets/assetsJsx/tags/TagPayLater';

const svgsMap = {
  payLater: TagPayLaterSvg,
  particuliers: TagParticuliersSvg,
  partners: TagPartnersSvg,
  paiement1012: TagPaiement1012Svg,
  paiement234: TagPaiement234Svg,
  faq: TagFaqSvg,
  enLigne: TagEnligneSvg,
  documentation: TagDocumentationSvg,
  enBoutique: TagBoutiqueSvg,
};

export const getSvg = (name, mode) => {
  const Svg = svgsMap[name];
  return <Svg mode={mode} />;
};
