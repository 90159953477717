// extracted by mini-css-extract-plugin
export var simpleText = "SimpleText-module--simpleText--243Gq";
export var backgroundtransparent = "SimpleText-module--backgroundtransparent--2ukos";
export var backgroundblack = "SimpleText-module--backgroundblack--3crJf";
export var backgroundwhite = "SimpleText-module--backgroundwhite--3_u73";
export var backgroundbeige = "SimpleText-module--backgroundbeige--1UfIZ";
export var backgroundgrey = "SimpleText-module--backgroundgrey--FJkzr";
export var backgroundgreyLabel = "SimpleText-module--backgroundgrey-label---rrMS";
export var backgroundblue = "SimpleText-module--backgroundblue--lXvD8";
export var backgrounddarkBlue = "SimpleText-module--backgrounddark-blue--wFTKN";
export var backgroundlightBlue = "SimpleText-module--backgroundlight-blue--3uMex";
export var backgroundpressedBlue = "SimpleText-module--backgroundpressed-blue--8vu1B";
export var backgroundorangeProduit = "SimpleText-module--backgroundorange-produit--fyxdR";
export var backgroundjauneProduit = "SimpleText-module--backgroundjaune-produit--3LNmX";
export var backgroundvertProduit = "SimpleText-module--backgroundvert-produit--15tE6";
export var backgroundrougeDistrib = "SimpleText-module--backgroundrouge-distrib--ygyb9";
export var contentRow = "SimpleText-module--contentRow--3iK0I";
export var contentCol = "SimpleText-module--contentCol--3_zpm";