import classNames from 'classnames';
import Img from 'gatsby-image';
import {getFluidGatsbyImage} from 'gatsby-storyblok-image';
import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import {getColorHex, getTag} from '@/common/utils';
import Button from '@/components/Button/Button';
import GlobalTag from '@/components/GlobalTag/GlobalTag';
import useWindowSize from '@/hooks/UseWindowSize';

import * as styles from './CarouselProduit.module.scss';

const ProductImage = ({productColor, productImage, addImage, addCircle}) => {
  let fluidImage = null;
  let themeColorHex = '#00425D';

  if (productColor) {
    themeColorHex = getColorHex(productColor);
  }

  if (!productImage || !productImage.filename) {
    return null;
  } else {
    fluidImage = getFluidGatsbyImage(productImage.filename, {
      maxWidth: 666,
    });
  }

  return (
    <div className={styles.imgContent}>
      <div className={styles.imgInnerWrapper} ref={(el) => addImage(el)}>
        <Img
          fluid={fluidImage}
          style={{width: '100%'}}
          // imgStyle={{objectFit: 'cover'}}
        />
      </div>
      <div className={styles.circleWrapper}>
        <svg
          width='963'
          height='965'
          viewBox='0 0 963 965'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          ref={(el) => addCircle(el)}
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0 482.187C0 748.665 215.608 965 481.521 965C747.435 965 963 748.977 963 482.5C963 216.023 747.435 0 481.521 0C215.608 0 0 215.71 0 482.187ZM140.692 482.187C140.692 670.798 293.31 824.01 481.521 824.01C669.733 824.01 822.308 671.111 822.308 482.5C822.308 293.889 669.69 140.678 481.479 140.678C293.267 140.678 140.692 293.577 140.692 482.187Z'
            fill={themeColorHex}
          />
        </svg>
      </div>
    </div>
  );
};

const CarouselProduit = ({lang, backgroundColor, productsList}) => {
  const windowSize = useWindowSize();
  const filteredProductsList = productsList.filter((product) => {
    if (product.isHidden) {
      if (
        product.productImage &&
        product.productImage.filename &&
        !product.isHidden.includes(lang)
      ) {
        return product;
      }
    } else if (!product.isHidden) {
      if (product.productImage && product.productImage.filename) {
        return product;
      }
    }
  });

  const [textsLoaded, setTextsLoaded] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const textRefs = useRef([]);
  const textHeights = useRef([]);
  const imagesRefs = useRef([]);
  const circlesRefs = useRef([]);
  const slidesRefs = useRef([]);
  // const indexesRefs = useRef([]);
  const istransitionning = useRef(false);

  const addText = (el) => {
    if (el && !textRefs.current.includes(el)) {
      textRefs.current.push(el);
      textHeights.current.push(el.clientHeight);
      if (filteredProductsList.length === textRefs.current.length) {
        setTextsLoaded(true);
      }
    }
  };
  const addImage = (el) => {
    if (el && !imagesRefs.current.includes(el)) {
      imagesRefs.current.push(el);
      if (filteredProductsList.length === imagesRefs.current.length) {
        setImagesLoaded(true);
      }
    }
  };
  const addCircle = (el) => {
    if (el && !circlesRefs.current.includes(el)) {
      circlesRefs.current.push(el);
    }
  };
  const addSlide = (el) => {
    if (el && !slidesRefs.current.includes(el)) {
      slidesRefs.current.push(el);
    }
  };
  const switchSlide = (nextSlideIndex) => {
    const currentSlideIndex = activeSlide;
    if (!istransitionning.current && currentSlideIndex !== nextSlideIndex) {
      istransitionning.current = true;

      //switch slide
      const currentSlide = slidesRefs.current[currentSlideIndex];
      const nextSlide = slidesRefs.current[nextSlideIndex];
      const currentImage = imagesRefs.current[currentSlideIndex];
      const nextImage = imagesRefs.current[nextSlideIndex];
      const currentTxt = textRefs.current[currentSlideIndex];
      const nextTxt = textRefs.current[nextSlideIndex];
      const currentCircle = circlesRefs.current[currentSlideIndex];
      const nextCircle = circlesRefs.current[nextSlideIndex];

      let tl = gsap.timeline();
      tl.add(() => {
        setActiveSlide(nextSlideIndex);
      });
      tl.to([currentCircle, nextCircle], 0.1, {opacity: 0, x: 100});
      tl.to(currentCircle, 0, {x: 0});
      tl.to([currentTxt, nextTxt], 0.1, {opacity: 0});
      tl.to([currentImage, nextImage], 0.1, {opacity: 0, y: 100});
      tl.to(currentImage, 0, {y: 0});
      tl.to(nextSlide, 0, {opacity: 1});
      tl.to(currentSlide, 0.1, {opacity: 0});
      tl.to(nextCircle, 0.1, {opacity: 1, x: 0});
      tl.to(nextImage, 0.1, {opacity: 1, y: 0});
      tl.to(nextTxt, 0.2, {opacity: 1});
      tl.eventCallback('onComplete', function () {
        istransitionning.current = false;
      });
    }
  };

  useEffect(() => {
    if ((textsLoaded, imagesLoaded)) {
      textHeights.current.sort(function (a, b) {
        return b - a;
      });
      let biggestSlideHeight = textHeights.current[0];
      if (windowSize.width < 990) {
        biggestSlideHeight = biggestSlideHeight + 340;
      }
      gsap.to(slidesRefs.current, 0, {height: biggestSlideHeight});
      gsap.to(slidesRefs.current[0], 0, {
        css: {zIndex: '2', opacity: 1},
      });
    }
  }, [textsLoaded, imagesLoaded]);

  return (
    <section
      className={classNames(
        styles.carouselProduitComponent,
        styles['background' + backgroundColor]
      )}
    >
      <div className={styles.sliderIndexesWrapper}>
        {filteredProductsList &&
          filteredProductsList.map(({_uid, tag}, i) => {
            const selectedTag = getTag(tag);
            if (selectedTag) {
              {
                activeSlide === i
                  ? (selectedTag.mode = 'selected')
                  : (selectedTag.mode = 'neutral');
              }
              return (
                <button className={styles.oneSlideIndex} key={_uid} onClick={() => switchSlide(i)}>
                  <GlobalTag {...selectedTag} />
                </button>
              );
            }
          })}
      </div>
      <div className={styles.slidesWrapper}>
        {filteredProductsList &&
          filteredProductsList.map(({_uid, tag, productColor, productImage, productTitle, CTA}) => {
            const selectedTag = getTag(tag);
            return (
              <div className={styles.oneSlide} key={_uid} ref={(el) => addSlide(el)}>
                <ProductImage {...{productColor, productImage, addImage, addCircle}} />

                <div className={styles.txtContent} ref={(el) => addText(el)}>
                  <div className={styles.rappelProduit}>
                    {selectedTag && <p>{selectedTag.title}</p>}
                  </div>
                  <div className={styles.title}>{render(productTitle)}</div>
                  {CTA &&
                    CTA[0] &&
                    CTA[0].button.map((cta) => {
                      return (
                        <div key={cta._uid} className={styles.cta}>
                          <Button {...cta} />
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default CarouselProduit;

CarouselProduit.propTypes = {
  lang: PropTypes.string,
  backgroundColor: PropTypes.string,
  productsList: PropTypes.array.isRequired,
};
ProductImage.propTypes = {
  productColor: PropTypes.string,
  productImage: PropTypes.object,
  addImage: PropTypes.func,
  addCircle: PropTypes.func,
};
