import classNames from 'classnames';
import {gsap, TimelineLite} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
// import {Helmet} from 'react-helmet';
import {render} from 'storyblok-rich-text-react-renderer';

import ButtonAndLink from '@/components/ButtonAndLink/ButtonAndLink';

import * as styles from './LogoLarge.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

const TrustBox = ({lang}) => {
  const ref = useRef(null);
  let locale = 'fr-FR';
  if (lang) {
    let language = lang;
    if (language === 'default') {
      language = 'fr';
    } else {
      language = language.substring(lang.length - 2, lang.length);
    }
    locale = language;
  }

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref}
      className='trustpilot-widget'
      data-locale={locale}
      data-template-id='53aa8807dec7e10d38f59f32'
      data-businessunit-id='5e4e8e831f96930001914d6b'
      data-style-height='150px'
      data-style-width='250px'
      data-theme='light'
      data-text-color='#00425E'
    >
      <a
        href='https://www.trustpilot.com/review/example.com'
        target='_blank'
        rel='noopener noreferrer'
      >
        Trustpilot
      </a>
    </div>
  );
};

const LogoLarge = ({
  lang,
  title,
  buttonAndLink,
  logos,
  noPaddingTop,
  noPaddingBottom,
  backgroundColor,
}) => {
  const trigger = useRef(null);
  const logoWrappersRefs = useRef([]);
  const textContent = useRef(null);
  const apparitionAnimPlayed = useRef(false);

  const addLogoWrapper = (el) => {
    if (el && !logoWrappersRefs.current.includes(el)) {
      logoWrappersRefs.current.push(el);
    }
  };

  useEffect(() => {
    ScrollTrigger.create({
      trigger: trigger.current,
      start: 'top bottom-=300',
      // markers: true,
      onEnter: function () {
        if (!apparitionAnimPlayed.current) {
          let tl = new TimelineLite();
          tl.fromTo(
            textContent.current,
            0.4,
            {
              y: '200',
              opacity: 0,
              ease: 'power4.inOut',
            },
            {
              y: '0',
              opacity: 1,
              ease: 'power4.inOut',
            }
          );
          tl.fromTo(
            logoWrappersRefs.current,
            0.4,
            {
              x: '300',
              opacity: 0,
              ease: 'power4.inOut',
            },
            {
              x: '0',
              opacity: 1,
              ease: 'power4.inOut',
              stagger: 0.05,
              delay: -0.4,
            }
          );
          tl.add(function () {
            apparitionAnimPlayed.current = true;
          });
        }
      },
    });
  }, []);

  return (
    <>
      {/* <Helmet>
        <script src='/trustpilot.js' />
      </Helmet> */}

      <section
        className={classNames(
          styles.logoLargeComponent,
          {[styles.noPaddingTop]: noPaddingTop},
          {[styles.noPaddingBottom]: noPaddingBottom},
          styles['background' + backgroundColor]
        )}
        ref={trigger}
      >
        <div className={classNames('container', styles.logoLargeComponentContainer)}>
          <div className={classNames('row', styles.logoLargeComponentRow)}>
            <div className={`col-12 col-md-5 offset-md-1 ${styles.contentCol}`} ref={textContent}>
              <span className={classNames(styles.titleRichText)}>{render(title)}</span>

              <TrustBox lang={lang} />
              <div className={`${styles.buttonlinkWrapper}`}>
                {buttonAndLink[0] &&
                  buttonAndLink.map((item) => <ButtonAndLink key={item._uid} {...item} />)}
              </div>
            </div>
            <div className={`col-12 col-md-6 ${styles.logosCol}`}>
              {logos[0] &&
                logos.map(({filename, alt, id}) => {
                  return (
                    <div
                      key={id}
                      className={classNames(styles.oneLogo)}
                      ref={(el) => addLogoWrapper(el)}
                    >
                      <img src={filename} alt={alt} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LogoLarge;

TrustBox.propTypes = {
  lang: PropTypes.string,
};

LogoLarge.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.object.isRequired,
  buttonAndLink: PropTypes.array,
  logos: PropTypes.array,
  backgroundColor: PropTypes.string,
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
};

LogoLarge.defaultProps = {
  buttonAndLink: [],
  backgroundColor: 'white',
  logos: [],
};
