import classNames from 'classnames';
import {gsap, TimelineLite} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';

import BackgroundCanvas from '@/components/BackgroundCanvas/BackgroundCanvas';
import CtaBannerPrimary from '@/components/CtaBannerPrimary/CtaBannerPrimary';
import CtaBannerSecondary from '@/components/CtaBannerSecondary/CtaBannerSecondary';

import * as styles from './CtaBannerSection.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

const CtaBannerSection = ({
  mode,
  themeColor,
  backgroundColorBanner,
  title,
  subtitle,
  CTA,
  list,
  listType,
  circleStyle,
  canvasPathPoints,
  noPaddingTop,
  noPaddingBottom,
  backgroundColorSection,
}) => {
  const [canvasPoints, setCanvasPoints] = useState([]);
  const section = useRef(null);
  const containerCtaBanner = useRef(null);
  const apparitionAnimPlayed = useRef(false);

  useEffect(() => {
    gsap.to(containerCtaBanner.current, 0, {y: '+=50', opacity: 0});

    ScrollTrigger.create({
      trigger: section.current,
      start: 'top bottom-=300',
      // markers: true,
      onEnter: function () {
        if (!apparitionAnimPlayed.current) {
          let tl = new TimelineLite();
          tl.to(containerCtaBanner.current, 0.4, {
            y: '0',
            opacity: 1,
            ease: 'power4.inOut',
          });
          tl.add(function () {
            apparitionAnimPlayed.current = true;
          });
        }
      },
    });
  }, []);

  useEffect(() => {
    if (canvasPoints.length === 0) {
      if (canvasPathPoints.tbody) {
        const pointsTable = canvasPathPoints.tbody;
        let pathPoints = [];
        for (let i = 0; i < pointsTable.length; i++) {
          pathPoints.push({x: pointsTable[i].body[0].value, y: pointsTable[i].body[1].value});
        }
        setCanvasPoints(pathPoints);
      }
    }
  }, [canvasPoints]);

  return (
    <section
      className={classNames(
        styles.ctaBannerComponent,
        {[styles.noPaddingTop]: noPaddingTop},
        {[styles.noPaddingBottom]: noPaddingBottom},
        styles['background' + backgroundColorSection]
      )}
      ref={section}
    >
      <div className={styles.ctaBannerContainer} ref={containerCtaBanner}>
        {mode === 'primary' ? (
          <CtaBannerPrimary
            {...{
              backgroundColorBanner,
              title,
              subtitle,
              CTA,
              list,
              listType,
              themeColor,
              circleStyle,
            }}
          />
        ) : (
          <CtaBannerSecondary
            {...{backgroundColorBanner, title, subtitle, CTA, themeColor, circleStyle}}
          />
        )}
      </div>
      <div className={classNames(styles.canvasWrapper)}>
        <BackgroundCanvas pathPoints={canvasPoints} fillStyle='#FFF7F0' />
      </div>
    </section>
  );
};

export default CtaBannerSection;

CtaBannerSection.propTypes = {
  mode: PropTypes.string,
  backgroundColorBanner: PropTypes.string,
  title: PropTypes.object,
  subtitle: PropTypes.object,
  CTA: PropTypes.array,
  list: PropTypes.object,
  listType: PropTypes.string,
  circleStyle: PropTypes.string,
  themeColor: PropTypes.string,
  canvasPathPoints: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  backgroundColorSection: PropTypes.string,
};
CtaBannerSection.defaultProps = {
  mode: 'primary',
  backgroundColorBanner: 'blue',
  title: '',
  subtitle: '',
  CTA: [],
  list: '',
  listType: 'borderList',
  themeColor: 'rougeDistrib',
  backgroundColorSection: 'white',
  canvasPathPoints: {},
};
