import classNames from 'classnames';
import Img from 'gatsby-image';
import {getFluidGatsbyImage} from 'gatsby-storyblok-image';
import {gsap, Power4} from 'gsap';
import {Draggable} from 'gsap/Draggable';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

// import arrowLeft from '@/assets/components/testimonials/arrow-left.svg';
// import arrowRight from '@/assets/components/testimonials/arrow-right.svg';
// import quoteSvg from '@/assets/glyphs/quote.svg';
import quoteSvg from '@/assets/glyphs/redquote.svg';
import BackgroundCanvas from '@/components/BackgroundCanvas/BackgroundCanvas';

import * as styles from './Testimonials.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(Draggable);
}

const Testimonials = ({lang, testimonialsList, canvasFillColor, backgroundColor}) => {
  const filterTestimonials = testimonialsList.filter((testimonial) => {
    if (testimonial.isHidden) {
      if (
        testimonial.quoteImage &&
        testimonial.quoteImage.filename &&
        !testimonial.isHidden.includes(lang)
      ) {
        return testimonial;
      }
    } else if (!testimonial.isHidden) {
      if (testimonial.quoteImage && testimonial.quoteImage.filename) {
        return testimonial;
      }
    }
  });
  const slideTrack = useRef(null);
  const slidesRefs = useRef([]);
  const sliderWrap = useRef();
  const istransitionning = useRef(false);

  const [sliderCurrentIndex, setSliderCurrentIndex] = useState(0);
  const [sliderPrevPosition, setSliderPrevPosition] = useState(0);
  const [overflowingSlidesState, setOverflowingSlides] = useState([]);

  const switchSlide = (slideIndex) => {
    const slideWidth = slidesRefs.current[0].clientWidth;
    const sliderWrapWidth = sliderWrap.current.clientWidth - 16;
    const allSlidesWidth = slideWidth * filterTestimonials.length;
    const draggableWidth = allSlidesWidth - sliderWrapWidth;
    const overflowingSlides = filterTestimonials.length - Math.floor(sliderWrapWidth / slideWidth);
    const draggableWidthEachSlide = draggableWidth / overflowingSlides;

    if (!istransitionning.current) {
      istransitionning.current = true;
      const sliderNextPosition = -(draggableWidthEachSlide * slideIndex);

      let sliderAnim = gsap.timeline();
      sliderAnim.to(slideTrack.current, {
        x: sliderNextPosition,
        duration: 0.6,
        ease: Power4.easeOut,
      });
      sliderAnim.add((istransitionning.current = false));
      sliderAnim.add(setSliderCurrentIndex(slideIndex));
      sliderAnim.add(setSliderPrevPosition(sliderNextPosition));
    }
  };

  useEffect(() => {
    let slideWidth;
    if (window.innerWidth > 992) {
      slideWidth = 666;
    } else {
      slideWidth = window.innerWidth * 0.9;
    }
    gsap.to(slidesRefs.current, {width: slideWidth});

    const sliderWrapWidth = sliderWrap.current.clientWidth - 16;
    const allSlidesWidth = slideWidth * filterTestimonials.length;
    const draggableWidth = allSlidesWidth - sliderWrapWidth;
    const overflowingSlides = filterTestimonials.length - Math.floor(sliderWrapWidth / slideWidth);
    const draggableWidthEachSlide = draggableWidth / overflowingSlides;

    const overflowingSlidesArray = [];
    for (let i = 0; i < overflowingSlides; i++) {
      overflowingSlidesArray.push(i);
    }
    setOverflowingSlides(overflowingSlidesArray);

    gsap.to(slideTrack.current, {width: allSlidesWidth});

    if (sliderWrapWidth <= allSlidesWidth) {
      Draggable.create(slideTrack.current, {
        type: 'x',
        onDragEnd: function () {
          const dragX = gsap.getProperty(slideTrack.current, 'x');
          const sliderMovement = dragX - sliderPrevPosition;
          const draggedAbsValue = Math.abs(sliderMovement);
          let sliderNextPosition;
          let sliderNextIndex;

          if (sliderMovement > 0) {
            sliderNextPosition = sliderPrevPosition + draggableWidthEachSlide;
            if (draggedAbsValue > draggableWidthEachSlide / 2) {
              sliderNextIndex = sliderCurrentIndex - 1;
            }
          } else {
            sliderNextPosition = sliderPrevPosition - draggableWidthEachSlide;
            if (draggedAbsValue > draggableWidthEachSlide / 2) {
              sliderNextIndex = sliderCurrentIndex + 1;
            }
          }

          let sliderAnim = gsap.timeline();
          if (
            draggedAbsValue > draggableWidthEachSlide / 2 &&
            sliderNextIndex <= overflowingSlides &&
            sliderNextIndex >= 0 &&
            sliderNextIndex < allSlidesWidth
          ) {
            sliderAnim.to(slideTrack.current, {
              x: sliderNextPosition,
              duration: 0.4,
              ease: Power4.easeOut,
            });
            sliderAnim.add(setSliderCurrentIndex(sliderNextIndex));
            sliderAnim.add(setSliderPrevPosition(sliderNextPosition));
          } else {
            sliderAnim.to(slideTrack.current, {
              x: sliderPrevPosition,
              duration: 0.2,
              ease: Power4.easeOut,
            });
          }
        },
      });
    }
  }, [sliderPrevPosition, sliderCurrentIndex]);

  return (
    <section
      className={classNames(styles.TestimonialsComponent, styles['background' + backgroundColor])}
    >
      <div className={styles.wrapper}>
        <div className={styles.sliderSwitches}>
          {overflowingSlidesState && (
            <button
              onClick={() => {
                switchSlide(0);
              }}
              className={classNames({[styles.selectedButton]: sliderCurrentIndex === 0})}
            ></button>
          )}

          {overflowingSlidesState &&
            overflowingSlidesState.map((slide) => {
              return (
                <button
                  onClick={() => {
                    switchSlide(slide + 1);
                  }}
                  className={classNames({
                    [styles.selectedButton]: sliderCurrentIndex === slide + 1,
                  })}
                  key={slide}
                ></button>
              );
            })}
        </div>
      </div>

      <div className={styles.sliderWrapper} ref={sliderWrap}>
        <div className={styles.slider}>
          <div className={styles.sliderTrack} ref={slideTrack}>
            {filterTestimonials &&
              filterTestimonials.map((testimonial, index) => {
                const testimonialImage = getFluidGatsbyImage(testimonial.quoteImage.filename, {
                  maxWidth: 120,
                });

                return (
                  <div
                    key={testimonial._uid}
                    className={styles.one__slide}
                    ref={(element) => (slidesRefs.current[index] = element)}
                  >
                    <div className={styles.one__slide__inner}>
                      <div className={styles.quoteSvgWrapper}>
                        <img src={quoteSvg} alt='' />
                      </div>
                      <div className={styles.quoteWrapper}>
                        {render(testimonial.quote)}
                        <span className={styles.quoteAuthor}>
                          <b>{testimonial.author}</b> - {testimonial.authorRole}
                        </span>
                      </div>
                      <div className={styles.imgWrapper}>
                        <div className={styles.imgInnerWrapper}>
                          <Img
                            fluid={testimonialImage}
                            style={{height: '100%', width: '100%'}}
                            imgStyle={{objectFit: 'cover'}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className={classNames(styles.canvasWrapper)}>
        <BackgroundCanvas
          pathPoints={[
            {x: 0, y: 0},
            {x: 1, y: 0},
            {x: 1, y: 1},
            {x: 0, y: 1},
          ]}
          fillStyle={canvasFillColor}
        />
      </div>
    </section>
  );
};

export default Testimonials;

Testimonials.propTypes = {
  lang: PropTypes.string,
  backgroundColor: PropTypes.string,
  testimonialsList: PropTypes.array.isRequired,
  canvasFillColor: PropTypes.string,
};

Testimonials.defaultProps = {
  testimonialsList: [],
  canvasFillColor: '',
};
