import classNames from 'classnames';
import Img from 'gatsby-image';
import {getFluidGatsbyImage} from 'gatsby-storyblok-image';
import {Bounce, gsap, TimelineLite} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import CircleSvg from '@/assets/assetsJsx/CircleSvg.js';
import BackgroundCanvas from '@/components/BackgroundCanvas/BackgroundCanvas';
import Button from '@/components/Button/Button';

import * as styles from './Split5050.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

export const Split5050 = ({
  layout,
  componentImage,
  title,
  titleSize,
  content,
  CTA,
  listType,
  contentColorTheme,
  shadowToggle,
  circleToggle,
  backgroundCirclePosition,
  canvasPathPoints,
  noPaddingTop,
  noPaddingBottom,
}) => {
  const [canvasPoints, setCanvasPoints] = useState([]);

  const trigger = useRef(null);
  const textContent = useRef(null);
  const imgContent = useRef(null);
  const circleSvg = useRef(null);
  const apparitionAnimPlayed = useRef(false);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: trigger.current,
      start: 'top bottom-=300',
      // markers: true,
      onEnter: function () {
        if (!apparitionAnimPlayed.current) {
          let tl = new TimelineLite();
          tl.fromTo(
            textContent.current,
            0.4,
            {
              y: '200',
              opacity: 0,
              ease: 'power4.inOut',
            },
            {
              y: '0',
              opacity: 1,
              ease: 'power4.inOut',
            }
          );
          tl.fromTo(
            imgContent.current,
            0.4,
            {
              y: '200',
              opacity: 0,
              ease: 'power4.inOut',
            },
            {
              y: '0',
              opacity: 1,
              ease: 'power4.inOut',
            },
            '-=0.4'
          );
          tl.fromTo(
            circleSvg.current,
            0.6,
            {
              scale: 0.6,
              opacity: 0,
              ease: 'power4.inOut',
            },
            {
              scale: 1,
              opacity: 1,
              ease: Bounce.easeOut,
            },
            '-=0.4'
          );
          tl.add(function () {
            apparitionAnimPlayed.current = true;
          });
        }
      },
    });
  }, []);

  useEffect(() => {
    if (canvasPoints.length === 0) {
      if (canvasPathPoints.tbody) {
        const pointsTable = canvasPathPoints.tbody;
        let pathPoints = [];
        for (let i = 0; i < pointsTable.length; i++) {
          pathPoints.push({x: pointsTable[i].body[0].value, y: pointsTable[i].body[1].value});
        }
        setCanvasPoints(pathPoints);
      }
    }
  }, [canvasPoints]);

  const fluidImage = getFluidGatsbyImage(componentImage.filename, {
    maxWidth: 600,
  });

  return (
    <section
      className={classNames(
        styles.split5050Component,
        {[styles.noPaddingTop]: noPaddingTop},
        {[styles.noPaddingBottom]: noPaddingBottom}
      )}
      ref={trigger}
    >
      <div className={classNames('container', styles.contentWrapper)}>
        <div className={classNames('row', styles.row, styles[layout])}>
          <div
            className={classNames(
              'col-12 col-md-6',
              styles.imgCol,
              styles['circlePos' + backgroundCirclePosition]
            )}
          >
            <div
              className={classNames(styles.imgWrapper, {[styles.shadow]: shadowToggle})}
              ref={imgContent}
            >
              <Img fluid={fluidImage} />
            </div>

            {circleToggle && (
              <div
                className={classNames(styles.roundSvgWrapper, styles[contentColorTheme])}
                ref={circleSvg}
              >
                <CircleSvg />
              </div>
            )}
          </div>

          <div className={classNames('col-lg-1', styles.spacer)}></div>
          <div
            className={classNames(
              'col-12 col-md-6 col-lg-5',
              styles.textCol,
              styles[listType],
              styles[contentColorTheme]
            )}
            ref={textContent}
          >
            <div className={classNames(styles.title, styles[titleSize])}>{render(title)}</div>
            <div className={classNames(styles.content)}>{render(content)}</div>

            {CTA[0] && CTA[0].button.map((cta) => <Button key={cta._uid} {...cta} />)}
          </div>
        </div>
      </div>

      <div className={classNames(styles.canvasWrapper)}>
        <BackgroundCanvas pathPoints={canvasPoints} fillStyle='#FFF7F0' />
      </div>
    </section>
  );
};

export default Split5050;

Split5050.propTypes = {
  layout: PropTypes.string,
  componentImage: PropTypes.object,
  title: PropTypes.object.isRequired,
  contentColorTheme: PropTypes.string,
  titleSize: PropTypes.string,
  content: PropTypes.object.isRequired,
  CTA: PropTypes.array,
  listType: PropTypes.string,
  backgroundCirclePosition: PropTypes.string,
  shadowToggle: PropTypes.bool,
  circleToggle: PropTypes.bool,
  canvasPathPoints: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
};

Split5050.defaultProps = {
  layout: 'textLeft',
  componentImage: {},
  title: 'Main title',
  contentColorTheme: 'rougeDistrib',
  titleSize: 'bigTitle',
  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas a hendrerit neque.',
  CTA: [],
  listType: 'borderList',
};
