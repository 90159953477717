import classNames from 'classnames';
import {Link} from 'gatsby';
import {gsap, TimelineLite, TweenMax} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import {Helmet} from 'react-helmet';

// import {render} from 'storyblok-rich-text-react-renderer';
// import CircleSvg from '@/assets/assetsJsx/CircleSvg.js';
import logo from '@/assets/local-cms/footer/logo.svg';
import LanguageSelector from '@/components/LanguageSelector/LanguageSelector';
import LanguageTooltip from '@/components/LanguageTooltip/LanguageTooltip';

import * as styles from './Footer.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

const TrustBox = ({lang}) => {
  const ref = useRef(null);
  let locale = 'fr-FR';
  if (lang) {
    let language = lang;
    if (language === 'default') {
      language = 'fr';
    } else {
      language = language.substring(lang.length - 2, lang.length);
    }
    locale = language;
  }

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref}
      className={classNames('trustpilot-widget', styles.trustpilot_wrapper)}
      data-locale={locale}
      data-template-id='53aa8807dec7e10d38f59f32'
      data-businessunit-id='5e4e8e831f96930001914d6b'
      data-style-height='150px'
      data-style-width='250px'
      data-theme='light'
      data-text-color='#00425E'
    >
      <a
        href='https://www.trustpilot.com/review/example.com'
        target='_blank'
        rel='noopener noreferrer'
      >
        Trustpilot
      </a>
    </div>
  );
};

const Item = ({label, href: {cached_url, linktype}, nofollowNoopener, targetBlank}) => {
  if (cached_url && linktype === 'story' && cached_url.startsWith('/')) {
    cached_url = cached_url.substring(1);
  }

  if (cached_url && cached_url.includes('home')) {
    cached_url.replace('home', '');
  }
  return (
    <li>
      {linktype === 'story' && <Link to={`/${cached_url}`}>{label}</Link>}
      {linktype === 'url' &&
        (targetBlank ? (
          <a href={cached_url} rel={`noreferrer ${nofollowNoopener}`} target='_blank'>
            {label}
          </a>
        ) : (
          <a href={cached_url} rel={`${nofollowNoopener}`}>
            {label}
          </a>
        ))}
    </li>
  );
};

const Footer = ({lang, columns, pageLangs, backgroundColor, hideFooterLang}) => {
  const trigger = useRef(null);
  const footercontent = useRef(null);
  const apparitionAnimPlayed = useRef(false);

  const [displayTooltip, setDisplayTooltip] = useState(false);
  const displayHideTooltip = (showTootltip) => {
    if (showTootltip && !displayTooltip) {
      setDisplayTooltip(true);
    }
    if (!showTootltip && displayTooltip) {
      setDisplayTooltip(false);
    }
  };

  useEffect(() => {
    TweenMax.to(footercontent.current, 0, {y: '+=100', opacity: 0});
    let tl = new TimelineLite();
    tl.to(footercontent.current, 0.1, {y: '-=100', opacity: 1}).add(function () {
      apparitionAnimPlayed.current = true;
    });
    // ScrollTrigger.create({
    //   trigger: trigger.current,
    //   start: 'top bottom-=300',
    //   // markers: true,
    //   onEnter: function () {
    //     if (!apparitionAnimPlayed.current) {
    //       let tl = new TimelineLite();
    //       tl.to(footercontent.current, 0.1, {y: '-=100', opacity: 1}).add(function () {
    //         apparitionAnimPlayed.current = true;
    //       });
    //     }
    //   },
    // });
  }, []);

  return (
    <>
      <Helmet>
        <script src='/trustpilot.js' />
      </Helmet>

      <footer
        className={classNames(styles.footer, styles['background' + backgroundColor])}
        ref={trigger}
      >
        <div className={`container ${styles.footerContainer}`} ref={footercontent}>
          <div className='row'>
            <div className={`col-12 col-md-3 ${styles.footerLogoCol}`}>
              <a href='/' className={styles.logoLink}>
                <div>
                  <img className='img-fluid' src={logo} alt='logo alma' />
                </div>
              </a>
              <TrustBox lang={lang} />
            </div>
            <ul className={`col-12 col-md-2`}>
              {columns[0] &&
                columns[0].items.map((item) => {
                  const isHidden = item.isHidden;
                  if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
                    return null;
                  } else {
                    return <Item key={item._uid} {...item} />;
                  }
                })}
            </ul>
            <ul className='col-12 col-md-2'>
              {columns[1] &&
                columns[1].items.map((item) => {
                  const isHidden = item.isHidden;
                  if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
                    return null;
                  } else {
                    return <Item key={item._uid} {...item} />;
                  }
                })}
            </ul>
            <ul className='col-12 col-md-2'>
              {columns[2] &&
                columns[2].items.map((item) => {
                  const isHidden = item.isHidden;
                  if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
                    return null;
                  } else {
                    return <Item key={item._uid} {...item} />;
                  }
                })}
            </ul>
            <div className={`col-12 col-md-3 ${styles.languageCol}`}>
              {!hideFooterLang && pageLangs && pageLangs.translations && (
                <>
                  <LanguageSelector displayHideTooltip={displayHideTooltip} pageLangs={pageLangs} />
                  <LanguageTooltip
                    displayTooltip={displayTooltip}
                    pageLangs={pageLangs}
                    displayPosition={'topRight'}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

TrustBox.propTypes = {
  lang: PropTypes.string,
};

Item.propTypes = {
  label: PropTypes.string,
  href: PropTypes.object,
  nofollowNoopener: PropTypes.string,
  targetBlank: PropTypes.bool,
};

Footer.propTypes = {
  lang: PropTypes.string,
  columns: PropTypes.array,
  backgroundColor: PropTypes.string,
  circleColor: PropTypes.string,
  pageLangs: PropTypes.object,
  hideFooterLang: PropTypes.bool,
};
