import PropTypes from 'prop-types';
import React from 'react';
export const TagBoutique = ({mode}) => {
  let color = '#FF414D';
  if (mode === 'selected') {
    color = '#ffffff';
  } else if (mode === 'basicDarkBlue' || mode === 'neutral') {
    color = '#003951';
  }
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='12.9722'
        y='17.4167'
        width='8.68519'
        height='8.68519'
        rx='4.34259'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M2.85938 12.6562L5.14885 24.2977C5.42551 25.7044 6.65881 26.7188 8.09247 26.7188H21.9156C23.3456 26.7188 24.5769 25.7094 24.8573 24.3071L27.1875 12.6562'
        stroke={color}
        strokeWidth='1.66667'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.85417 3.28125L3.79688 12.6563M21.1458 3.28125L26.25 12.6563'
        stroke={color}
        strokeWidth='1.66667'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.9375 12.6562H29.0625'
        stroke={color}
        strokeWidth='1.66667'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='0.75'
        y='-0.75'
        width='8.68519'
        height='8.68519'
        rx='4.34259'
        transform='matrix(1 0 0 -1 9.07407 13.5)'
        fill={color}
      />
      <rect
        x='0.75'
        y='-0.75'
        width='8.68519'
        height='8.68519'
        rx='4.34259'
        transform='matrix(1 0 0 -1 9.07407 13.5)'
        stroke={color}
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default TagBoutique;

TagBoutique.propTypes = {
  mode: PropTypes.string,
};
