import PropTypes from 'prop-types';
import React from 'react';
export const TagParticuliers = ({mode}) => {
  let color = '#FF414D';
  if (mode === 'selected') {
    color = '#ffffff';
  } else if (mode === 'basicDarkBlue' || mode === 'neutral') {
    color = '#003951';
  }
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.3848 5C20.2209 5.24927 20.9621 5.81548 21.4913 6.60936C22.0206 7.40324 22.3078 8.37964 22.3078 9.38462C22.3078 10.3896 22.0206 11.366 21.4913 12.1599C20.9621 12.9537 20.2209 13.52 19.3848 13.7692'
        stroke={color}
        strokeWidth='1.66667'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.077 13.7692C14.4986 13.7692 16.4616 11.8062 16.4616 9.38462C16.4616 6.96306 14.4986 5 12.077 5C9.65544 5 7.69238 6.96306 7.69238 9.38462C7.69238 11.8062 9.65544 13.7692 12.077 13.7692Z'
        stroke={color}
        strokeWidth='1.66667'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.1487 24C17.2438 23.7969 18.1711 23.5152 18.8009 23.1549C19.1881 22.9333 19.3804 22.5029 19.3841 22.0568C19.388 21.5957 19.3646 21.1459 19.3547 20.9794C19.352 20.9339 19.3507 20.8889 19.3497 20.8434C19.3291 19.8961 18.8819 18.9914 18.1001 18.3202C15.5718 16.1497 7.12034 16.1497 4.59207 18.3202C3.81026 18.9914 3.36301 19.8961 3.34245 20.8434C3.34146 20.8889 3.34018 20.9339 3.33748 20.9794C3.32761 21.1459 3.30417 21.5957 3.30805 22.0568C3.3118 22.5029 3.50405 22.9333 3.89128 23.1549C4.521 23.5152 5.44836 23.7969 6.54346 24'
        stroke={color}
        strokeWidth='1.66667'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.769 24C24.7153 23.8296 25.5229 23.5962 26.0892 23.2997C26.4914 23.089 26.6924 22.6523 26.6921 22.1982C26.6919 21.8388 26.6743 21.5028 26.6658 21.3617C26.663 21.3149 26.6616 21.2682 26.6604 21.2213C26.6387 20.3944 26.2351 19.6051 25.5318 19.019C25.1067 18.6647 24.496 18.3763 23.769 18.1538'
        stroke={color}
        strokeWidth='1.66667'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TagParticuliers;

TagParticuliers.propTypes = {
  mode: PropTypes.string,
};
