import PropTypes from 'prop-types';
import React from 'react';

import TagBoutique from '@/assets/assetsJsx/tags/TagBoutique';
import TagDocumentation from '@/assets/assetsJsx/tags/TagDocumentation';
import TagEnligne from '@/assets/assetsJsx/tags/TagEnligne';
import TagFaq from '@/assets/assetsJsx/tags/TagFaq';
import TagPaiement234 from '@/assets/assetsJsx/tags/TagPaiement234';
import TagPaiement1012 from '@/assets/assetsJsx/tags/TagPaiement1012';
import TagPartners from '@/assets/assetsJsx/tags/TagPartenaires';
import TagParticuliers from '@/assets/assetsJsx/tags/TagParticuliers';
import TagPayLater from '@/assets/assetsJsx/tags/TagPayLater';

export const Tag = ({tag, mode}) => {
  switch (tag) {
    case 'enBoutique':
      return <TagBoutique mode={mode} />;
    case 'enLigne':
      return <TagEnligne mode={mode} />;
    case 'paiement1012':
      return <TagPaiement1012 mode={mode} />;
    case 'payLater':
      return <TagPayLater mode={mode} />;
    case 'paiement234':
      return <TagPaiement234 mode={mode} />;
    case 'documentation':
      return <TagDocumentation mode={mode} />;
    case 'faq':
      return <TagFaq mode={mode} />;
    case 'particuliers':
      return <TagParticuliers mode={mode} />;
    case 'partenaires':
      return <TagPartners mode={mode} />;
    default:
      return null;
  }
};

Tag.propTypes = {
  tag: PropTypes.string,
  mode: PropTypes.string,
};
