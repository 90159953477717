import PropTypes from 'prop-types';
import React from 'react';
export const TagPartenaires = ({mode}) => {
  let color = '#FF414D';
  if (mode === 'selected') {
    color = '#ffffff';
  } else if (mode === 'basicDarkBlue' || mode === 'neutral') {
    color = '#003951';
  }
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.85898 8.05862C9.48485 7.68603 7.00107 7.2824 5.80594 7.12715C3.93531 7.62393 3.15589 11.474 3 13.3369C4.14316 13.7171 6.77244 15.0567 8.14423 17.3733C9.01719 19.2362 11.0021 21.6684 11.8855 22.6516C12.2596 23.3967 16.1464 22.8068 16.5621 22.1858M24.824 15.6656C27.1935 14.4236 27.1623 12.2502 26.8505 11.3187C26.227 8.71065 23.5769 9.09358 22.3298 9.61106C19.368 10.5425 18.1209 8.05862 13.6002 7.12715C9.98369 6.38198 9.07955 9.09358 9.07955 10.5425C9.39132 11.5257 10.7319 12.9022 13.6002 10.5425C14.0679 10.3873 15.6891 10.4804 18.4327 12.095C21.8622 14.1131 24.2005 16.1313 24.2005 17.3733C24.2313 17.8954 23.918 18.465 22.7975 18.2184M19.2121 16.2866C20.8847 17.498 22.0303 18.0495 22.7975 18.2184M16.5621 18.2184C17.2899 19.1429 18.6505 20.1793 19.9916 20.2689M22.7975 18.2184C22.0846 19.8264 21.0441 20.3393 19.9916 20.2689M13.6002 19.5467C14.9512 21.1149 18.1209 23.4549 19.9916 20.2689'
        stroke={color}
        strokeWidth='1.6'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TagPartenaires;

TagPartenaires.propTypes = {
  mode: PropTypes.string,
};
