import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';

import * as styles from './BackgroundCanvas.module.scss';

const BackgroundCanvas = ({pathPoints, fillStyle}) => {
  const canvasRef = useRef(null);

  const scaleCanvas = (canvas, rect) => {
    var dpr = window.devicePixelRatio || 1;
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;

    var ctx = canvas.getContext('2d');
    ctx.scale(dpr, dpr);
    return ctx;
  };

  const draw = (ctx, rect) => {
    ctx.beginPath();
    ctx.moveTo(pathPoints[0].x * rect.width, pathPoints[0].y * rect.height);
    for (let i = 1; i < pathPoints.length; i++) {
      ctx.lineTo(pathPoints[i].x * rect.width, pathPoints[i].y * rect.height);
    }
    ctx.closePath();
    ctx.clip();

    ctx.fillStyle = fillStyle;
    ctx.fillRect(0, 0, rect.width, rect.height);
  };

  useEffect(() => {
    if (pathPoints[0]) {
      const rect = canvasRef.current.getBoundingClientRect();
      const context = scaleCanvas(canvasRef.current, rect);
      draw(context, rect);
    }
  }, [draw]);

  return <canvas className={styles.keyFigureCanvas} ref={canvasRef} />;
};

export default BackgroundCanvas;

BackgroundCanvas.propTypes = {
  pathPoints: PropTypes.array,
  fillStyle: PropTypes.string,
};

BackgroundCanvas.defaultProps = {
  pathPoints: [],
  fillStyle: '#ffffff',
};
