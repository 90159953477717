// extracted by mini-css-extract-plugin
export var doubleCardComponent = "DoubleCardNumbers-module--doubleCardComponent--MJ-HD";
export var backgroundwhite = "DoubleCardNumbers-module--backgroundwhite--1DI-o";
export var backgroundbeige = "DoubleCardNumbers-module--backgroundbeige--2y5Sv";
export var backgroundblue = "DoubleCardNumbers-module--backgroundblue--10X0D";
export var backgrounddarkblue = "DoubleCardNumbers-module--backgrounddarkblue--Ojlc4";
export var backgroundvertProduit = "DoubleCardNumbers-module--backgroundvertProduit--15Mq4";
export var backgroundjauneProduit = "DoubleCardNumbers-module--backgroundjauneProduit--rNIFy";
export var backgroundorangeProduit = "DoubleCardNumbers-module--backgroundorangeProduit--1J0co";
export var backgroundrougeDistrib = "DoubleCardNumbers-module--backgroundrougeDistrib--2-QCm";
export var noPaddingTop = "DoubleCardNumbers-module--noPaddingTop--ryUaf";
export var noPaddingBottom = "DoubleCardNumbers-module--noPaddingBottom--1CWnp";
export var doubleCardComponentContainer = "DoubleCardNumbers-module--doubleCardComponentContainer--1YkVj";
export var doubleCardComponentRow = "DoubleCardNumbers-module--doubleCardComponentRow--3f17P";
export var cardNumberWrapper = "DoubleCardNumbers-module--cardNumberWrapper--yd2D2";
export var canvasWrapper = "DoubleCardNumbers-module--canvasWrapper--3oqhQ";