// extracted by mini-css-extract-plugin
export var ctaBannerComponent = "CtaBannerSection-module--ctaBannerComponent--3kOfH";
export var backgroundtransparent = "CtaBannerSection-module--backgroundtransparent--3yXdf";
export var backgroundblack = "CtaBannerSection-module--backgroundblack--1sfyI";
export var backgroundwhite = "CtaBannerSection-module--backgroundwhite--3UsG7";
export var backgroundbeige = "CtaBannerSection-module--backgroundbeige--sRco1";
export var backgroundgrey = "CtaBannerSection-module--backgroundgrey--3qfV_";
export var backgroundgreyLabel = "CtaBannerSection-module--backgroundgrey-label--1A3Ce";
export var backgroundblue = "CtaBannerSection-module--backgroundblue--trGFa";
export var backgrounddarkBlue = "CtaBannerSection-module--backgrounddark-blue--lLQiK";
export var backgroundlightBlue = "CtaBannerSection-module--backgroundlight-blue--1e3Mq";
export var backgroundpressedBlue = "CtaBannerSection-module--backgroundpressed-blue--1ofx-";
export var backgroundorangeProduit = "CtaBannerSection-module--backgroundorange-produit--n4KdX";
export var backgroundjauneProduit = "CtaBannerSection-module--backgroundjaune-produit--3KRhR";
export var backgroundvertProduit = "CtaBannerSection-module--backgroundvert-produit--3aeGf";
export var backgroundrougeDistrib = "CtaBannerSection-module--backgroundrouge-distrib--3wVIr";
export var noPaddingTop = "CtaBannerSection-module--noPaddingTop--3I61v";
export var noPaddingBottom = "CtaBannerSection-module--noPaddingBottom--fuMWR";
export var ctaBannerContainer = "CtaBannerSection-module--ctaBannerContainer--VWa1I";
export var canvasWrapper = "CtaBannerSection-module--canvasWrapper--3m7H-";