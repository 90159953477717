import PropTypes from 'prop-types';
import React from 'react';
export const TagPayLater = ({mode}) => {
  let color = '#1DCDA3';
  if (mode === 'selected') {
    color = '#ffffff';
  } else if (mode === 'basicDarkBlue' || mode === 'neutral') {
    color = '#003951';
  }
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        y='-1.06066'
        width='8.23748'
        height='8.23748'
        rx='4.11874'
        transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 6.13544 28.1893)'
        stroke={color}
        strokeWidth='1.5'
      />
      <rect
        y='-1.06066'
        width='8.23748'
        height='8.23748'
        rx='4.11874'
        transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 14.25 20.0748)'
        stroke={color}
        strokeWidth='1.5'
      />
      <rect
        y='-2.59273'
        width='6.07081'
        height='6.07081'
        rx='3.03541'
        transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 21.2812 9.34481)'
        fill={color}
      />
      <rect
        y='-2.59273'
        width='6.07081'
        height='6.07081'
        rx='3.03541'
        transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 21.2812 9.34481)'
        stroke={color}
        strokeWidth='3.66667'
      />
    </svg>
  );
};

export default TagPayLater;

TagPayLater.propTypes = {
  mode: PropTypes.string,
};
