import classNames from 'classnames';
import {gsap} from 'gsap';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {useState} from 'react';

import LanguageSelectOption from '@/components/LanguageSelectOption/LanguageSelectOption';

import * as styles from './LanguageTooltip.module.scss';

const LanguageTooltip = ({displayTooltip, pageLangs, displayPosition}) => {
  const [hoverTooltip, setHoverTooltip] = useState(false);
  const [isTooltipOn, setIsTooltipOn] = useState(false);
  const tooltip = useRef(null);

  const handleMouseEnter = () => {
    setHoverTooltip(true);
  };
  const handleMouseLeave = () => {
    setHoverTooltip(false);
  };

  useEffect(() => {
    let tl = gsap.timeline();
    if (
      (displayTooltip && !hoverTooltip && !isTooltipOn) ||
      (!displayTooltip && hoverTooltip && !isTooltipOn)
    ) {
      tl.to(tooltip.current, {visibility: 'visible', duration: 0});
      tl.to(tooltip.current, {opacity: 1, duration: 0.1});
      tl.add(() => {
        setIsTooltipOn(true);
      });
    } else if (!displayTooltip && !hoverTooltip && isTooltipOn) {
      tl.to(tooltip.current, {opacity: 0, duration: 0.1});
      tl.to(tooltip.current, {visibility: 'hidden', duration: 0});
      tl.add(() => {
        setIsTooltipOn(false);
      });
    }
  }, [displayTooltip, hoverTooltip]);

  const currentPage = {};
  if (pageLangs.lang === 'default') {
    pageLangs.lang = 'fr';
  }
  currentPage.lang = pageLangs.lang.substring(0, 2);
  currentPage.slug = pageLangs.slug;
  const availableLangs = {[currentPage.lang]: []};
  if (pageLangs.lang !== 'default') {
    availableLangs['fr'] = [];
    availableLangs['fr'].push({lang: 'fr', path: pageLangs.default_full_slug});
  }

  const pageTranslations = pageLangs.translations;
  pageTranslations.forEach((translation) => {
    const shortenTranslationLang = translation.lang.substring(0, 2);
    if (!availableLangs[shortenTranslationLang]) {
      availableLangs[shortenTranslationLang] = [];
    }
    availableLangs[shortenTranslationLang].push(translation);
  });

  const languages = [];
  for (const [key, value] of Object.entries(availableLangs)) {
    languages.push({lang: key, translations: value});
  }

  const currentlySelected = {lang: pageLangs.lang};

  return (
    <div
      className={classNames(styles.languageTooltipComponent, styles[displayPosition])}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      ref={tooltip}
    >
      <div className={classNames(styles.languageTooltipInnerWrapper)}>
        {languages[0] &&
          languages.map((language, i) => {
            const availableLangs = {};
            availableLangs[language.lang] = language.translations;
            return (
              <div
                key={'languageSelectOption' + i}
                className={classNames(styles.languageSelectOptionWrapper)}
              >
                <LanguageSelectOption
                  availableLangs={availableLangs}
                  currentlySelected={currentlySelected}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LanguageTooltip;

LanguageTooltip.propTypes = {
  displayTooltip: PropTypes.bool,
  pageLangs: PropTypes.object,
  displayPosition: PropTypes.string,
};
