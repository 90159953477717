import classNames from 'classnames';
import {gsap, TimelineLite} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';

import BackgroundCanvas from '@/components/BackgroundCanvas/BackgroundCanvas';
import CardNumber from '@/components/CardNumber/CardNumber';

import * as styles from './DoubleCardNumbers.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

const DoubleCardNumbers = ({
  lang,
  cardsNumber,
  canvasPathPoints,
  noPaddingTop,
  noPaddingBottom,
  backgroundColor,
}) => {
  const [canvasPoints, setCanvasPoints] = useState([]);

  const cardsWrappers = useRef([]);
  const intransitionHover = useRef([]);

  const section = useRef(null);
  const cardContainer = useRef(null);
  const apparitionAnimPlayed = useRef(false);

  const storeCardsContainer = (el) => {
    if (el && !cardsWrappers.current.includes(el)) {
      cardsWrappers.current.push(el);
      intransitionHover.current.push(false);
    }
  };

  const handleMouseEnter = (i) => {
    let tl = new TimelineLite();
    tl.to(cardsWrappers.current[i], 0.3, {scale: 1.07, ease: 'power4.inOut'});
  };

  const handleMouseLeave = (i) => {
    let tl = new TimelineLite();
    tl.to(cardsWrappers.current[i], 0.3, {scale: 1, ease: 'power4.inOut'});
  };

  useEffect(() => {
    if (canvasPoints.length === 0) {
      if (canvasPathPoints.tbody) {
        const pointsTable = canvasPathPoints.tbody;
        let pathPoints = [];
        for (let i = 0; i < pointsTable.length; i++) {
          pathPoints.push({x: pointsTable[i].body[0].value, y: pointsTable[i].body[1].value});
        }
        setCanvasPoints(pathPoints);
      }
    }
  }, [canvasPoints]);

  useEffect(() => {
    gsap.to(cardContainer.current, 0, {y: '+=50', opacity: 0});

    ScrollTrigger.create({
      trigger: section.current,
      start: 'top bottom-=300',
      // markers: true,
      onEnter: function () {
        if (!apparitionAnimPlayed.current) {
          let tl = new TimelineLite();
          tl.to(cardContainer.current, 0.4, {y: '0', opacity: 1, ease: 'power4.inOut'});
          tl.add(function () {
            apparitionAnimPlayed.current = true;
          });
        }
      },
    });
  }, []);

  return (
    <section
      className={classNames(
        styles.doubleCardComponent,
        {[styles.noPaddingTop]: noPaddingTop},
        {[styles.noPaddingBottom]: noPaddingBottom},
        styles['background' + backgroundColor]
      )}
      ref={section}
    >
      <div
        className={classNames('container', styles.doubleCardComponentContainer)}
        ref={cardContainer}
      >
        <div className={classNames('row', styles.doubleCardComponentRow)}>
          {cardsNumber.map((cardNumber, i) => {
            const isHidden = cardNumber.isHidden;
            if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
              return null;
            } else {
              return (
                <div
                  key={i}
                  className={classNames('col-8 col-md-3', styles.cardNumberWrapper)}
                  onMouseEnter={() => handleMouseEnter(i)}
                  onMouseLeave={() => handleMouseLeave(i)}
                  ref={storeCardsContainer}
                >
                  <CardNumber {...cardNumber} />
                </div>
              );
            }
          })}
        </div>
      </div>

      <div className={classNames(styles.canvasWrapper)}>
        <BackgroundCanvas pathPoints={canvasPoints} fillStyle='#FFF7F0' />
      </div>
    </section>
  );
};

export default DoubleCardNumbers;

DoubleCardNumbers.propTypes = {
  lang: PropTypes.string,
  cardsNumber: PropTypes.array,
  canvasPathPoints: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

DoubleCardNumbers.defaultProps = {
  cardsNumber: [],
  canvasPathPoints: {},
  backgroundColor: '',
};
