import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import {Helmet} from 'react-helmet';
import SbEditable from 'storyblok-react';

import CarouselProduit from '@/components/CarouselProduit/CarouselProduit';
import CGUFormLabel from '@/components/CGUFormLabel/CGUFormLabel';
import ContactSignup from '@/components/ContactSignup/ContactSignup';
import Content404 from '@/components/Content404/Content404';
import CtaBannerSection from '@/components/CtaBannerSection/CtaBannerSection';
import CtaLogos from '@/components/CtaLogos/CtaLogos';
import DoubleCardIcons from '@/components/DoubleCardIcons/DoubleCardIcons';
import DoubleCardImages from '@/components/DoubleCardImages/DoubleCardImages';
import DoubleCardNumbers from '@/components/DoubleCardNumbers/DoubleCardNumbers';
import FaqSection from '@/components/FaqSection/FaqSection';
import Footer from '@/components/Footer/Footer';
import GlobalFeatures from '@/components/GlobalFeatures/GlobalFeatures';
import GlobalTag from '@/components/GlobalTag/GlobalTag';
import Hero from '@/components/Hero/Hero';
import HeroPricing from '@/components/HeroPricing/HeroPricing';
import HomeMeta from '@/components/HomeMeta/HomeMeta';
import HtmlCode from '@/components/HtmlCode/HtmlCode';
import IconsSection from '@/components/IconsSection/IconsSection';
import InvalidSentForm from '@/components/InvalidSentForm/InvalidSentForm';
import KeyFigures from '@/components/KeyFigures/KeyFigures';
import Layout from '@/components/Layout/Layout';
import LogoLarge from '@/components/LogoLarge/LogoLarge';
import LogoLittle from '@/components/LogoLittle/LogoLittle';
import Nav from '@/components/Nav/Nav';
import Parallax from '@/components/Parallax/Parallax';
import PrincingPlansSection from '@/components/PrincingPlansSection/PrincingPlansSection';
import RichText from '@/components/RichText/RichText';
import SendingForm from '@/components/SendingForm/SendingForm';
import Seo from '@/components/Seo/Seo';
import SimpleText from '@/components/SimpleText/SimpleText';
import Split5050 from '@/components/Split5050/Split5050';
import Split5050Carousel from '@/components/Split5050Carousel/Split5050Carousel';
import Testimonials from '@/components/Testimonials/Testimonials';
import SimpleTitle from '@/components/Title/Title';
import ValidSentForm from '@/components/ValidSentForm/ValidSentForm';

const Components = {
  nav: Nav,
  hero: Hero,
  footer: Footer,
  simpleTitle: SimpleTitle,
  split5050: Split5050,
  split5050Carousel: Split5050Carousel,
  doubleCardIcons: DoubleCardIcons,
  doubleCardImages: DoubleCardImages,
  doubleCardNumbers: DoubleCardNumbers,
  keyFigures: KeyFigures,
  iconsSection: IconsSection,
  logoLittle: LogoLittle,
  testimonials: Testimonials,
  ctaBanner: CtaBannerSection,
  ctaLogos: CtaLogos,
  logoLarge: LogoLarge,
  parallax: Parallax,
  contactSignup: ContactSignup,
  faqSection: FaqSection,
  heroPricing: HeroPricing,
  pricingPlansSection: PrincingPlansSection,
  homeMeta: HomeMeta,
  content404: Content404,
  sendingForm: SendingForm,
  invalidSentForm: InvalidSentForm,
  validSentForm: ValidSentForm,
  carouselProduit: CarouselProduit,
  simpleText: SimpleText,
  globalFeatures: GlobalFeatures,
  tag: GlobalTag,
  richText: RichText,
  CGUFormLabel: CGUFormLabel,
  htmlCode: HtmlCode,
};

const Storyblok = ({data, pageContext}) => {
  const {
    title = '',
    metaDescription = '',
    backgroundColorTopComponent = '',
    blocks = [],
    fixedNav = true,
    openGraphImage = '',
    lang = '',
    slug = '',
  } = pageContext;

  let dataGlobalElements = {};
  let pageLangs = {};
  let translatedStrings = {};
  let hideFooterLang = false;

  if (data && data.dataGlobalElements) {
    dataGlobalElements = data.dataGlobalElements.edges;
  }

  if (data && data.translatedslugs) {
    pageLangs.lang = data.currentpage.lang;
    pageLangs.slug = data.currentpage.slug;
    pageLangs.default_full_slug = data.currentpage.slug;

    // TO DO - Patch until NL, IT and DE pages are ready
    // pageLangs.translations = data.translatedslugs.edges[0].node.translated_slugs;
    pageLangs.translations = data.translatedslugs.edges[0].node.translated_slugs.filter(
      ({lang}) =>
        !(
          lang.startsWith('nl') ||
          lang.startsWith('it') ||
          lang.startsWith('de') ||
          lang.startsWith('en')
        )
    );
  }

  if (data) {
    let fr = {};
    let en = {};
    let es = {};
    let nl = {};
    let it = {};
    if (data.defaultStrings) {
      data.defaultStrings.edges.forEach((x) => {
        if (x.node.dimension_value) {
          fr[x.node.name] = x.node.dimension_value;
        } else {
          fr[x.node.name] = x.node.value;
        }
      });
    }

    if (data.englishStrings) {
      data.englishStrings.edges.forEach((x) => {
        if (x.node.dimension_value) {
          en[x.node.name] = x.node.dimension_value;
        } else {
          en[x.node.name] = x.node.value;
        }
      });
    }

    if (data.spanishStrings) {
      data.spanishStrings.edges.forEach((x) => {
        if (x.node.dimension_value) {
          es[x.node.name] = x.node.dimension_value;
        } else {
          es[x.node.name] = x.node.value;
        }
      });
    }

    if (data.dutchStrings) {
      data.dutchStrings.edges.forEach((x) => {
        if (x.node.dimension_value) {
          nl[x.node.name] = x.node.dimension_value;
        } else {
          nl[x.node.name] = x.node.value;
        }
      });
    }

    if (data.italianStrings) {
      data.italianStrings.edges.forEach((x) => {
        if (x.node.dimension_value) {
          it[x.node.name] = x.node.dimension_value;
        } else {
          it[x.node.name] = x.node.value;
        }
      });
    }

    translatedStrings.fr = fr;
    translatedStrings.en = en;
    translatedStrings.es = es;
    translatedStrings.nl = nl;
    translatedStrings.it = it;
  }

  if (slug && slug === 'global') {
    hideFooterLang = true;
  }

  const filteredBlocks = blocks
    .map((block) => {
      if (block) {
        if (lang && block.isHidden && block.isHidden.length !== 0 && block.isHidden.includes(lang))
          return null;
        if (block.component !== 'global') return block;
        if (block.reference?.content && Array.isArray(block.reference.content.blocks))
          return block.reference.content.blocks[0];
      }
      return;
    })
    .filter((i) => !!i);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel='sitemap' type='application/xml' title='Sitemap' href='/sitemap.xml' />
      </Helmet>

      <Seo title={title} description={metaDescription} image={openGraphImage} />

      <Layout fixedNav={fixedNav} layoutBackgroundColor={backgroundColorTopComponent}>
        {filteredBlocks.map((block) => {
          if (block.component === 'footer' || block.component === 'nav') {
            const Component = Components[block.component];
            return (
              <SbEditable key={block._uid} content={block}>
                <Component
                  {...block}
                  pageLangs={pageLangs}
                  lang={lang}
                  hideFooterLang={hideFooterLang}
                />
              </SbEditable>
            );
          } else if (
            typeof Components[block.component] !== 'undefined' &&
            block.component !== 'contactSignup'
          ) {
            const Component = Components[block.component];
            return (
              <SbEditable key={block._uid} content={block}>
                <Component {...block} lang={lang} />
              </SbEditable>
            );
          } else if (block.component === 'contactSignup') {
            const Component = Components[block.component];
            return (
              <SbEditable key={block._uid} content={block}>
                <Component
                  {...block}
                  lang={lang}
                  dataGlobalElements={dataGlobalElements}
                  translatedStrings={translatedStrings}
                />
              </SbEditable>
            );
          }
        })}
      </Layout>
    </>
  );
};

export const query = graphql`
  query pageContext($lang: String, $slug: String, $fullslug: String) {
    dataGlobalElements: allStoryblokEntry(
      filter: {
        lang: {eq: $lang}
        slug: {
          in: [
            "valid-form-contact"
            "valid-form-register"
            "invalid-form"
            "sending-form"
            "cgu-form"
          ]
        }
      }
    ) {
      edges {
        node {
          content
        }
      }
    }

    currentpage: storyblokEntry(full_slug: {eq: $fullslug}) {
      lang
      slug
      default_full_slug
    }

    translatedslugs: allStoryblokEntry(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          translated_slugs {
            lang
            path
          }
        }
      }
    }

    defaultStrings: allStoryblokDatasourceEntry(
      filter: {
        data_source: {eq: "translatedstrings"}
        data_source_dimension: {nin: ["es", "en", "nl", "it"]}
      }
    ) {
      edges {
        node {
          name
          dimension_value
          value
          data_source
          data_source_dimension
        }
      }
    }

    englishStrings: allStoryblokDatasourceEntry(
      filter: {data_source: {eq: "translatedstrings"}, data_source_dimension: {eq: "en"}}
    ) {
      edges {
        node {
          name
          dimension_value
          value
          data_source
          data_source_dimension
        }
      }
    }

    spanishStrings: allStoryblokDatasourceEntry(
      filter: {data_source: {eq: "translatedstrings"}, data_source_dimension: {eq: "es"}}
    ) {
      edges {
        node {
          name
          dimension_value
          value
          data_source
          data_source_dimension
        }
      }
    }

    dutchStrings: allStoryblokDatasourceEntry(
      filter: {data_source: {eq: "translatedstrings"}, data_source_dimension: {eq: "nl"}}
    ) {
      edges {
        node {
          name
          dimension_value
          value
          data_source
          data_source_dimension
        }
      }
    }

    italianStrings: allStoryblokDatasourceEntry(
      filter: {data_source: {eq: "translatedstrings"}, data_source_dimension: {eq: "it"}}
    ) {
      edges {
        node {
          name
          dimension_value
          value
          data_source
          data_source_dimension
        }
      }
    }
  }
`;

Storyblok.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

Storyblok.defaultProps = {
  pageContext: {
    title: '',
    blocks: [],
  },
};

export default Storyblok;
