// extracted by mini-css-extract-plugin
export var ctaBannerPrimary = "CtaBannerPrimary-module--ctaBannerPrimary--pzH2d";
export var wrapper = "CtaBannerPrimary-module--wrapper--bNip8";
export var innerWrapper = "CtaBannerPrimary-module--innerWrapper--JENYh";
export var backgroundtransparent = "CtaBannerPrimary-module--backgroundtransparent--1Wh8T";
export var backgroundblack = "CtaBannerPrimary-module--backgroundblack--1uzFB";
export var backgroundwhite = "CtaBannerPrimary-module--backgroundwhite--3dAah";
export var backgroundbeige = "CtaBannerPrimary-module--backgroundbeige--35ZuI";
export var backgroundgrey = "CtaBannerPrimary-module--backgroundgrey--2R12-";
export var backgroundgreyLabel = "CtaBannerPrimary-module--backgroundgrey-label--3QR2S";
export var backgroundblue = "CtaBannerPrimary-module--backgroundblue--wKpZ5";
export var backgrounddarkBlue = "CtaBannerPrimary-module--backgrounddark-blue--2aSA4";
export var backgroundlightBlue = "CtaBannerPrimary-module--backgroundlight-blue--1dzPj";
export var backgroundpressedBlue = "CtaBannerPrimary-module--backgroundpressed-blue--2dGzE";
export var backgroundorangeProduit = "CtaBannerPrimary-module--backgroundorange-produit--1x72p";
export var backgroundjauneProduit = "CtaBannerPrimary-module--backgroundjaune-produit--14u1z";
export var backgroundvertProduit = "CtaBannerPrimary-module--backgroundvert-produit--2uDZW";
export var backgroundrougeDistrib = "CtaBannerPrimary-module--backgroundrouge-distrib--3k5jI";
export var contentWrapper = "CtaBannerPrimary-module--contentWrapper--1HW9D";
export var listWrapper = "CtaBannerPrimary-module--listWrapper--1GKz-";
export var title = "CtaBannerPrimary-module--title--3i788";
export var subtitle = "CtaBannerPrimary-module--subtitle--1jPO3";
export var list = "CtaBannerPrimary-module--list--kmqqf";
export var plusList = "CtaBannerPrimary-module--plusList--2byWq";
export var tickList = "CtaBannerPrimary-module--tickList--1atCf";
export var borderList = "CtaBannerPrimary-module--borderList--2TEgh";
export var blue = "CtaBannerPrimary-module--blue--3htnK";
export var vertProduit = "CtaBannerPrimary-module--vertProduit--2C-2e";
export var jauneProduit = "CtaBannerPrimary-module--jauneProduit--28DFG";
export var orangeProduit = "CtaBannerPrimary-module--orangeProduit--21YqP";
export var rougeDistrib = "CtaBannerPrimary-module--rougeDistrib--3kuCV";
export var cta = "CtaBannerPrimary-module--cta--zKtyJ";
export var circleSvg = "CtaBannerPrimary-module--circleSvg--2bHEx";
export var lightMode = "CtaBannerPrimary-module--lightMode--3X80y";