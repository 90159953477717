export const getTag = (tag) => {
  if (
    tag &&
    Array.isArray(tag) &&
    tag[0] &&
    tag[0].reference?.content?.blocks &&
    Array.isArray(tag[0].reference.content.blocks) &&
    tag[0].reference?.content?.blocks[0]
  ) {
    const title = tag[0].reference?.content?.blocks[0].title;
    const mode = tag[0].reference?.content?.blocks[0].mode;
    const icon = tag[0].reference?.content?.blocks[0].icon;
    const color = tag[0].reference?.content?.blocks[0].color;
    return {title: title, mode: mode, icon: icon, color: color};
  }
};

export const getColorHex = (colorName) => {
  const colors = {
    transparent: 'transparent',
    black: '#000',
    white: '#FFF',
    beige: '#FFF7F0',
    grey: '#F5F6F6',
    greyLabel: '#C1C1C1',
    blue: '#00425D',
    darkBlue: '#003951',
    lightBlue: '#588FE1',
    pressedBlue: '#042736',
    orangeProduit: '#FF7E55',
    jauneProduit: '#FB3',
    vertProduit: '#1DCDA3',
    rougeDistrib: '#FF414D',
  };

  return colors[colorName];
};

export const getTranslatedString = (lang, string, translatedStrings) => {
  let language;
  if (lang === 'default') {
    language = 'fr';
  } else {
    language = lang.substring(lang.length - 2, lang.length);
  }

  if (
    translatedStrings &&
    string &&
    translatedStrings[language] &&
    translatedStrings[language][string]
  ) {
    return translatedStrings[language][string];
  }
};
