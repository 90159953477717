// extracted by mini-css-extract-plugin
export var ctaLogosComponent = "CtaLogos-module--ctaLogosComponent--19ySk";
export var backgroundwhite = "CtaLogos-module--backgroundwhite--25RQk";
export var backgroundbeige = "CtaLogos-module--backgroundbeige--3ev2w";
export var backgroundblue = "CtaLogos-module--backgroundblue--3Lzgg";
export var backgrounddarkblue = "CtaLogos-module--backgrounddarkblue--HIxj6";
export var backgroundvertProduit = "CtaLogos-module--backgroundvertProduit--2Cs8t";
export var backgroundjauneProduit = "CtaLogos-module--backgroundjauneProduit--zHDe4";
export var backgroundorangeProduit = "CtaLogos-module--backgroundorangeProduit--3rUFc";
export var backgroundrougeDistrib = "CtaLogos-module--backgroundrougeDistrib--1ashh";
export var noPaddingTop = "CtaLogos-module--noPaddingTop--21lYn";
export var noPaddingBottom = "CtaLogos-module--noPaddingBottom--u_lPf";
export var ctaLogosComponentRow = "CtaLogos-module--ctaLogosComponentRow--18NOc";
export var preTitle = "CtaLogos-module--preTitle--2dKRg";
export var title = "CtaLogos-module--title--3SlPC";
export var textContent = "CtaLogos-module--textContent--1gkXW";
export var cta = "CtaLogos-module--cta--3UTYQ";
export var ctaLogosComponentLogosRow = "CtaLogos-module--ctaLogosComponentLogosRow--2acji";
export var ctaLogosComponentCol = "CtaLogos-module--ctaLogosComponentCol--3vCLo";