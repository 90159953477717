import classNames from 'classnames';
import Img from 'gatsby-image';
import {getFluidGatsbyImage} from 'gatsby-storyblok-image';
import {gsap, TimelineLite, TweenMax} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import * as styles from './Parallax.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

const Parallax = ({title, content, images}) => {
  const [imagesWrappersLoaded, setImagesWrappersLoaded] = useState(false);

  const imgWrappers = useRef([]);
  const section = useRef(null);
  const parallaxImgsRow = useRef(null);

  // const trigger = useRef(null);
  const textContent = useRef(null);
  const apparitionAnimPlayed = useRef(false);

  const addImgWrappers = (el) => {
    if (el && !imgWrappers.current.includes(el)) {
      imgWrappers.current.push(el);
      if (images.length === imgWrappers.current.length) {
        setImagesWrappersLoaded(true);
      }
    }
  };

  useEffect(() => {
    if (imagesWrappersLoaded) {
      TweenMax.to(textContent.current, 0, {y: '+=100', opacity: 0});
      TweenMax.to(imgWrappers.current, 0, {opacity: 0});
      for (let i = 0; i < imgWrappers.current.length; i++) {
        if (i < 2) {
          TweenMax.to(imgWrappers.current[i], 0, {x: '-=200'});
        } else {
          TweenMax.to(imgWrappers.current[i], 0, {x: '+=200'});
        }
      }

      ScrollTrigger.create({
        trigger: section.current,
        start: 'top bottom-=300',
        // markers: true,
        onEnter: function () {
          if (!apparitionAnimPlayed.current) {
            let tl = new TimelineLite();
            tl.to(textContent.current, 0.6, {y: '-=100', opacity: 1});
            tl.to(imgWrappers.current, 0.6, {x: '0', opacity: 1}, '-=0.6');
            tl.add(function () {
              apparitionAnimPlayed.current = true;
            });
          }
        },
      });

      gsap.fromTo(
        parallaxImgsRow.current,
        {
          y: '10vh',
        },
        {
          y: '-10vh',
          scrollTrigger: {
            trigger: section.current,
            scrub: true,
            // markers: true,
            start: 'top center',
            end: 'bottom center',
            snap: {
              snapTo: 0.5, // 0.5 'cause the scroll animation range is 200vh for parallax effect
              duration: 1,
              ease: 'power4.inOut',
            },
          },
          ease: 'none',
        }
      );
    }
  }, [imagesWrappersLoaded]);

  return (
    <section className={classNames(styles.parallaxComponent)} ref={section}>
      <div className={classNames('container', styles.parallaxTextContainer)} ref={textContent}>
        <div className={classNames('row', styles.rowTitle)}>
          <div className={classNames('col-12 col-md-8 col-lg-6', styles.titleCol)}>
            {render(title)}
          </div>
        </div>
        <div className={classNames('row', styles.rowBodyText)}>
          <div className={classNames('col-12 col-md-8 col-lg-6', styles.contentCol)}>
            {render(content)}
          </div>
        </div>
      </div>
      <div className={classNames(styles.parallaxImgsContainer)}>
        <div className={classNames(styles.parallaxImgsRow)} ref={parallaxImgsRow}>
          {images[0] &&
            images.map(({id, filename, alt}, i) => {
              const fluidImage = getFluidGatsbyImage(filename, {maxWidth: 360});
              if (fluidImage) {
                return (
                  <div
                    key={'parallax-image' + id}
                    className={classNames(styles.imageWrapper, styles['image' + i])}
                    ref={addImgWrappers}
                  >
                    <Img fluid={fluidImage} alt={alt} objectFit={'cover'} />
                  </div>
                );
              }
            })}
        </div>
      </div>
    </section>
  );
};

export default Parallax;

Parallax.propTypes = {
  title: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  images: PropTypes.array,
};
