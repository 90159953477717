import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './HtmlCode.module.scss';

const HtmlCode = ({html, backgroundColor}) => {
  return (
    <section className={classNames(styles.section, styles['background' + backgroundColor])}>
      <div className={styles.wrapper}>
        <div dangerouslySetInnerHTML={{__html: html}}></div>
      </div>
    </section>
  );
};

export default HtmlCode;

HtmlCode.propTypes = {
  html: PropTypes.string,
  backgroundColor: PropTypes.string,
};
