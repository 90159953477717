import PropTypes from 'prop-types';
import React from 'react';
import {components} from 'react-select';
import countryList from 'react-select-country-list';

import * as styles from './GlobalFeatures.module.scss';

export function getOptions(countries) {
  return countries.map((props) => ({
    value: props,
    label: countryList().getLabel(props.country),
  }));
}

const Flag = ({country}) =>
  country ? (
    <img className={styles.flag} src={'/flags/' + country.toUpperCase() + '.svg'} alt='flag-icon' />
  ) : null;

Flag.propTypes = {
  country: PropTypes.string,
};

export const Null = () => null;

export const Option = (props) => {
  const label = props.children;
  const country = props.data.value.country;

  return (
    <components.Option {...props}>
      <Flag country={country} /> {label}
    </components.Option>
  );
};

Option.propTypes = {
  children: PropTypes.string,
  data: PropTypes.object,
};

export const ValueContainer = (props) => {
  return (
    <div className={styles.valueContainer}>
      <Flag country={props.hasValue ? props.getValue()[0].value.country : undefined} />
      <components.ValueContainer {...props}>{props.children}</components.ValueContainer>
    </div>
  );
};

ValueContainer.propTypes = {
  children: PropTypes.node,
  hasValue: PropTypes.bool,
  getValue: PropTypes.func,
};
