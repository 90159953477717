import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import Button from '@/components/Button/Button';

import * as styles from './CtaLogos.module.scss';

const CtaLogos = ({
  preTitle,
  title,
  textContent,
  CTA,
  logos,
  noPaddingTop,
  noPaddingBottom,
  backgroundColor,
}) => {
  return (
    <section
      className={classNames(
        styles.ctaLogosComponent,
        {[styles.noPaddingTop]: noPaddingTop},
        {[styles.noPaddingBottom]: noPaddingBottom},
        styles['background' + backgroundColor]
      )}
    >
      <div className={'container'}>
        <div className={classNames('row', styles.ctaLogosComponentRow)}>
          <div className={'col-12 col-md-8 col-lg-6'}>
            {preTitle && preTitle.content[0].content && (
              <div className={styles.preTitle}>{render(preTitle)}</div>
            )}
            {title && title.content[0].content && (
              <div className={styles.title}>{render(title)}</div>
            )}
            {textContent && textContent.content[0].content && (
              <div className={styles.textContent}>{render(textContent)}</div>
            )}

            {CTA &&
              CTA[0].button.map((cta) => {
                return (
                  <div key={cta._uid} className={styles.cta}>
                    <Button {...cta} />
                  </div>
                );
              })}
          </div>
        </div>

        {Array.isArray(logos) && logos[0] && (
          <div
            className={classNames(
              'row',
              styles.ctaLogosComponentRow,
              styles.ctaLogosComponentLogosRow
            )}
          >
            {logos.map(({filename, alt, id}) => {
              return (
                <div key={id} className={classNames('col-8 col-md-4', styles.ctaLogosComponentCol)}>
                  <img src={filename} alt={alt} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default CtaLogos;

CtaLogos.propTypes = {
  preTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  CTA: PropTypes.array,
  logos: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

CtaLogos.defaultProps = {
  backgroundColor: 'white',
  logos: [],
};
