import PropTypes from 'prop-types';
import React, {useRef} from 'react';

import * as styles from './LanguageSelectorMini.module.scss';

const PageLangFlag = ({lang}) => {
  if (!lang) {
    return null;
  }
  let formattedLang = lang;
  if (lang === 'en') {
    formattedLang = 'gb';
  }
  return (
    <div className={styles.flagWrapper}>
      <img src={'/flags/' + formattedLang.toUpperCase() + '.svg'} alt='flag-icon' />
    </div>
  );
};
const LanguageSelectorMini = ({displayHideTooltip, pageLangs}) => {
  const dropdown = useRef(null);
  const handleMouseEnter = () => {
    displayHideTooltip(true);
  };
  const handleMouseLeave = () => {
    displayHideTooltip(false);
  };

  const currentPage = {};
  if (pageLangs.lang === 'default') {
    pageLangs.lang = 'fr';
  }
  currentPage.lang = pageLangs.lang.substring(0, 2);
  currentPage.slug = pageLangs.slug;
  const availableLangs = {[currentPage.lang]: []};
  if (currentPage.lang === 'fr') {
    availableLangs[currentPage.lang].push({lang: 'fr', path: pageLangs.default_full_slug});
  }

  const pageTranslations = pageLangs.translations;
  pageTranslations.forEach((translation) => {
    const shortenTranslationLang = translation.lang.substring(0, 2);
    if (shortenTranslationLang === currentPage.lang) {
      availableLangs[currentPage.lang].push(translation);
    }
  });

  const languages = [];
  for (const [key, value] of Object.entries(availableLangs)) {
    languages.push({lang: key, translations: value});
  }

  return (
    <div className={styles.languageSelectorMiniComponent}>
      <div
        className={styles.languageSelectorInnerWrapper}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
        ref={dropdown}
      >
        {languages &&
          languages.map(({lang}, i) => {
            return (
              <div key={'oneLangOption' + i} className={styles.oneLangOption}>
                <PageLangFlag lang={lang} />
              </div>
            );
          })}
        <div className={styles.dropdownIconWrapper}>
          <svg
            width='12'
            height='7'
            viewBox='0 0 12 7'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M5.60156 6.45312C5.8125 6.66406 6.16406 6.66406 6.375 6.45312L10.9453 1.90625C11.1562 1.67188 11.1562 1.32031 10.9453 1.10938L10.4062 0.570312C10.1953 0.359375 9.84375 0.359375 9.60938 0.570312L6 4.17969L2.36719 0.570312C2.13281 0.359375 1.78125 0.359375 1.57031 0.570312L1.03125 1.10938C0.820312 1.32031 0.820312 1.67188 1.03125 1.90625L5.60156 6.45312Z'
              fill='#00425D'
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelectorMini;

LanguageSelectorMini.propTypes = {
  displayHideTooltip: PropTypes.func,
  pageLangs: PropTypes.object,
};
PageLangFlag.propTypes = {
  lang: PropTypes.string,
};
