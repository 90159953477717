// extracted by mini-css-extract-plugin
export var carouselProduitComponent = "CarouselProduit-module--carouselProduitComponent--Lo-3T";
export var backgroundtransparent = "CarouselProduit-module--backgroundtransparent--1-Z1u";
export var backgroundblack = "CarouselProduit-module--backgroundblack--80eDG";
export var backgroundwhite = "CarouselProduit-module--backgroundwhite--2O_Rp";
export var backgroundbeige = "CarouselProduit-module--backgroundbeige--3XsRv";
export var backgroundgrey = "CarouselProduit-module--backgroundgrey--3dqB8";
export var backgroundgreyLabel = "CarouselProduit-module--backgroundgrey-label--J3XjL";
export var backgroundblue = "CarouselProduit-module--backgroundblue--3dV1h";
export var backgrounddarkBlue = "CarouselProduit-module--backgrounddark-blue--271ou";
export var backgroundlightBlue = "CarouselProduit-module--backgroundlight-blue--3yg0F";
export var backgroundpressedBlue = "CarouselProduit-module--backgroundpressed-blue--3otCr";
export var backgroundorangeProduit = "CarouselProduit-module--backgroundorange-produit--2vWpn";
export var backgroundjauneProduit = "CarouselProduit-module--backgroundjaune-produit--2w7k8";
export var backgroundvertProduit = "CarouselProduit-module--backgroundvert-produit--2qZnp";
export var backgroundrougeDistrib = "CarouselProduit-module--backgroundrouge-distrib--23C4t";
export var sliderIndexesWrapper = "CarouselProduit-module--sliderIndexesWrapper--2A46i";
export var oneSlideIndex = "CarouselProduit-module--oneSlideIndex--1vv9G";
export var slidesWrapper = "CarouselProduit-module--slidesWrapper--5pb2Y";
export var oneSlide = "CarouselProduit-module--oneSlide--2ZpA-";
export var imgContent = "CarouselProduit-module--imgContent--h2wWe";
export var imgInnerWrapper = "CarouselProduit-module--imgInnerWrapper--3C3c0";
export var circleWrapper = "CarouselProduit-module--circleWrapper--3n129";
export var txtContent = "CarouselProduit-module--txtContent--3n3M8";
export var title = "CarouselProduit-module--title--1BnnR";
export var rappelProduit = "CarouselProduit-module--rappelProduit--15ZQ3";
export var cta = "CarouselProduit-module--cta--3RqyP";