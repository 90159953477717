// extracted by mini-css-extract-plugin
export var split5050Component = "Split5050-module--split5050Component--1CClX";
export var noPaddingTop = "Split5050-module--noPaddingTop--1pPI3";
export var noPaddingBottom = "Split5050-module--noPaddingBottom---pnZS";
export var contentWrapper = "Split5050-module--contentWrapper--2BE1W";
export var row = "Split5050-module--row--2ss7x";
export var imgCol = "Split5050-module--imgCol--1tc8n";
export var imgWrapper = "Split5050-module--imgWrapper--F_oU6";
export var shadow = "Split5050-module--shadow--FMknu";
export var roundSvgWrapper = "Split5050-module--roundSvgWrapper--Fx6UI";
export var blue = "Split5050-module--blue--1RIEh";
export var vertProduit = "Split5050-module--vertProduit--2ykX4";
export var jauneProduit = "Split5050-module--jauneProduit--11yLU";
export var orangeProduit = "Split5050-module--orangeProduit--1KejV";
export var circlePostop = "Split5050-module--circlePostop--3hmB0";
export var circlePoscenter = "Split5050-module--circlePoscenter--oH-ty";
export var circlePosbottom = "Split5050-module--circlePosbottom--5ffpf";
export var spacer = "Split5050-module--spacer--1gzKw";
export var textRight = "Split5050-module--textRight--2VUv7";
export var textLeft = "Split5050-module--textLeft--gUv42";
export var textCol = "Split5050-module--textCol--6GOeO";
export var title = "Split5050-module--title--23zq-";
export var content = "Split5050-module--content--1i_7f";
export var bigTitle = "Split5050-module--bigTitle--2Tter";
export var smallTitle = "Split5050-module--smallTitle--3xHzm";
export var plusList = "Split5050-module--plusList--G_r53";
export var tickList = "Split5050-module--tickList--2TwUA";
export var borderList = "Split5050-module--borderList--Rllba";
export var rougeDistrib = "Split5050-module--rougeDistrib--3D1YC";
export var cta = "Split5050-module--cta--2WYEz";
export var canvasWrapper = "Split5050-module--canvasWrapper--1bSW4";