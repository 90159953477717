import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import BackgroundCanvas from '@/components/BackgroundCanvas/BackgroundCanvas';
import CardIcon from '@/components/CardIcon/CardIcon';

import * as styles from './DoubleCardIcons.module.scss';

const DoubleCardIcons = ({
  lang,
  cardsIcon,
  canvasPathPoints,
  noPaddingTop,
  noPaddingBottom,
  backgroundColor,
}) => {
  const [canvasPoints, setCanvasPoints] = useState([]);

  useEffect(() => {
    if (canvasPoints.length === 0) {
      if (canvasPathPoints.tbody) {
        const pointsTable = canvasPathPoints.tbody;
        let pathPoints = [];
        for (let i = 0; i < pointsTable.length; i++) {
          pathPoints.push({x: pointsTable[i].body[0].value, y: pointsTable[i].body[1].value});
        }
        setCanvasPoints(pathPoints);
      }
    }
  }, [canvasPoints]);

  return (
    <section
      className={classNames(
        styles.doubleCardComponent,
        {[styles.noPaddingTop]: noPaddingTop},
        {[styles.noPaddingBottom]: noPaddingBottom},
        styles['background' + backgroundColor]
      )}
    >
      <div className={classNames('container', styles.doubleCardComponentContainer)}>
        <div className={classNames('row', styles.doubleCardComponentRow)}>
          {cardsIcon.map(({isHidden, cardTheme, cardBackgroundColor, oneLottieCard}, i) => {
            if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
              return null;
            } else {
              return (
                <div key={i} className={'col-12 col-md-4 col-lg-3'}>
                  <CardIcon
                    cardContent={oneLottieCard[0]}
                    cardBackgroundColor={cardBackgroundColor}
                    cardTheme={cardTheme}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>

      <div className={classNames(styles.canvasWrapper)}>
        <BackgroundCanvas pathPoints={canvasPoints} fillStyle='#FFF7F0' />
      </div>
    </section>
  );
};

export default DoubleCardIcons;

DoubleCardIcons.propTypes = {
  lang: PropTypes.string,
  cardsIcon: PropTypes.array,
  canvasPathPoints: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

DoubleCardIcons.defaultProps = {
  cardsIcon: [],
  canvasPathPoints: {},
  backgroundColor: '',
};
