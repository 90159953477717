import classNames from 'classnames';
import Img from 'gatsby-image';
import {getFluidGatsbyImage} from 'gatsby-storyblok-image';
import {TimelineLite} from 'gsap';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import CircleSvg from '@/assets/assetsJsx/CircleSvg.js';
import {getTag} from '@/common/utils';
import ButtonAndLink from '@/components/ButtonAndLink/ButtonAndLink';
import GlobalTag from '@/components/GlobalTag/GlobalTag';

import * as styles from './Hero.module.scss';

export const HeroImg = ({shadowHeroToggle, heroImage}) => {
  if (!heroImage || !heroImage.filename) {
    return null;
  }
  const fluidImage = getFluidGatsbyImage(heroImage.filename, {
    maxWidth: 600,
  });
  const shadowClass = shadowHeroToggle ? 'shadow' : '';

  return (
    <div className={classNames(styles.innerwrapper, styles.heroImageWrapper, styles[shadowClass])}>
      <Img fluid={fluidImage} />
    </div>
  );
};

export const Hero = ({
  tagToggle,
  tag,
  title,
  text,
  heroImage,
  shadowHeroToggle,
  colorTheme,
  backgroundColor,
  buttonAndLink,
}) => {
  const selectedTag = getTag(tag);
  const textContent = useRef(null);
  const imgContent = useRef(null);
  const circleSvg = useRef(null);
  const apparitionAnimPlayed = useRef(false);

  useEffect(() => {
    if (!apparitionAnimPlayed.current) {
      let tl = new TimelineLite();
      tl.fromTo(
        textContent.current,
        0.4,
        {
          y: '200',
          opacity: 0,
          ease: 'power4.inOut',
        },
        {
          y: '0',
          opacity: 1,
          ease: 'power4.inOut',
        }
      );
      tl.fromTo(
        imgContent.current,
        0.4,
        {
          y: '200',
          opacity: 0,
          ease: 'power4.inOut',
        },
        {
          y: '0',
          opacity: 1,
          ease: 'power4.inOut',
        },
        '-=0.4'
      );
      tl.fromTo(
        circleSvg.current,
        0.6,
        {
          x: '+=50vw',
          opacity: 0,
          ease: 'power4.inOut',
        },
        {
          x: '0',
          opacity: 1,
          ease: 'power4.inOut',
        },
        '-=0.4'
      );
      tl.add(function () {
        apparitionAnimPlayed.current = true;
      });
    }
  }, []);

  return (
    <section className={classNames(styles.heroComponent, styles['background' + backgroundColor])}>
      <div className={classNames('container', styles.heroComponentContainer)}>
        <div className={classNames('row', styles.heroComponentRow)}>
          <div className={`col-12 col-md-5 offset-md-1 ${styles.contentCol}`} ref={textContent}>
            {tagToggle && tag && (
              <div className={classNames(styles.innerwrapper, styles.tagwrapper)}>
                <GlobalTag {...selectedTag} />
              </div>
            )}
            {title && (
              <span className={classNames(styles.titleRichText, styles[colorTheme])}>
                {render(title)}
              </span>
            )}
            {text && <p className={styles.introTxt}>{text}</p>}

            {buttonAndLink[0] &&
              buttonAndLink.map((item) => <ButtonAndLink key={item._uid} {...item} />)}
          </div>

          {heroImage && (
            <div className={classNames('col-12 col-md-6', styles.heroImageCol)} ref={imgContent}>
              <HeroImg {...{shadowHeroToggle, heroImage}} />
            </div>
          )}
        </div>
      </div>
      <div className={styles.backgroundContent}>
        <div className='container'>
          <div className='row'>
            <div
              className={classNames('col-12', styles.roundSvgWrapper, styles[colorTheme])}
              ref={circleSvg}
            >
              <CircleSvg />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

Hero.propTypes = {
  tagToggle: PropTypes.bool,
  tag: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
  buttonAndLink: PropTypes.array,
  heroImage: PropTypes.object,
  shadowHeroToggle: PropTypes.bool,
  colorTheme: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Hero.defaultProps = {
  tagToggle: false,
  buttonAndLink: [],
  heroImage: {},
  shadowHeroToggle: false,
  colorTheme: 'rougeDistrib',
  backgroundColor: 'white',
};

HeroImg.propTypes = {
  mode: PropTypes.string,
  shadowHeroToggle: PropTypes.bool,
  heroImage: PropTypes.object,
};
