import classNames from 'classnames';
import {gsap, TimelineLite} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';

// import circleS from '@/assets/components/circles/red-circle-s.svg';
import CircleSvg from '@/assets/assetsJsx/CircleSvg.js';
import BackgroundCanvas from '@/components/BackgroundCanvas/BackgroundCanvas';
import CardImage from '@/components/CardImage/CardImage';

import * as styles from './DoubleCardImages.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

const DoubleCardImages = ({
  lang,
  cardsImage,
  circleColor,
  canvasPathPoints,
  noPaddingTop,
  backgroundColor,
}) => {
  const [canvasPoints, setCanvasPoints] = useState([]);

  const section = useRef(null);
  const cardsElements = useRef([]);
  const addCardElement = (el) => {
    if (el && !cardsElements.current.includes(el)) {
      cardsElements.current.push(el);
    }
  };
  const apparitionAnimPlayed = useRef(false);

  useEffect(() => {
    if (canvasPoints.length === 0) {
      if (canvasPathPoints.tbody) {
        const pointsTable = canvasPathPoints.tbody;
        let pathPoints = [];
        for (let i = 0; i < pointsTable.length; i++) {
          pathPoints.push({x: pointsTable[i].body[0].value, y: pointsTable[i].body[1].value});
        }
        setCanvasPoints(pathPoints);
      }
    }
  }, [canvasPoints]);

  useEffect(() => {
    gsap.to(cardsElements.current, 0, {y: '+=50', opacity: 0});

    ScrollTrigger.create({
      trigger: section.current,
      start: 'top bottom-=300',
      // markers: true,
      onEnter: function () {
        if (!apparitionAnimPlayed.current) {
          let tl = new TimelineLite();
          tl.to(cardsElements.current, 0.3, {
            y: '0',
            opacity: 1,
            stagger: 0.1,
            ease: 'power4.inOut',
          });
          tl.add(function () {
            apparitionAnimPlayed.current = true;
          });
        }
      },
    });
  }, []);

  return (
    <section
      className={classNames(
        styles.doubleCardComponent,
        {[styles.noPaddingTop]: noPaddingTop},
        styles['background' + backgroundColor]
      )}
      ref={section}
    >
      <div className={classNames('container', styles.doubleCardComponentContainer)}>
        <div className={classNames('row', styles.doubleCardComponentRow)}>
          {cardsImage.map(({isHidden, text, linkText, linkTarget, cardImage}, i) => {
            if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
              return null;
            } else {
              return (
                <div
                  key={i}
                  className={classNames('col-12 col-md-6 col-lg-5', styles.cardImageWrapper)}
                  ref={addCardElement}
                >
                  <CardImage
                    text={text}
                    linkText={linkText}
                    linkTarget={linkTarget}
                    cardImage={cardImage}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>

      <div className={styles.backgroundCircleContainer}>
        <div>
          <div className={classNames('col-12', styles.circleWrapper)}>
            <div
              className={classNames(styles.circleInnerWrapper, styles[circleColor])}
              ref={addCardElement}
            >
              <CircleSvg />
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(styles.canvasWrapper)}>
        <BackgroundCanvas pathPoints={canvasPoints} fillStyle='#FFF7F0' />
      </div>
    </section>
  );
};

export default DoubleCardImages;

DoubleCardImages.propTypes = {
  lang: PropTypes.string,
  cardsImage: PropTypes.array,
  circleColor: PropTypes.string,
  canvasPathPoints: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noPaddingTop: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

DoubleCardImages.defaultProps = {
  cardsImage: [],
  canvasPathPoints: {},
  backgroundColor: '',
};
