import classNames from 'classnames';
import {Link as GatsbyLink} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import * as styles from './GlobalFeaturesItem.module.scss';
import {Tag} from './GlobalFeaturesItemHelpers';

export const Link = ({label, link}) => {
  if (!label || !link) return null;

  const sharedClassName = styles.link;

  if (link.linktype === 'story') {
    return (
      <GatsbyLink className={sharedClassName} to={link.cached_url}>
        {label}
      </GatsbyLink>
    );
  }

  return (
    <a className={sharedClassName} href={link.url}>
      {label}
    </a>
  );
};

Link.propTypes = {
  label: PropTypes.string,
  link: PropTypes.object,
};

const GlobalFeaturesItem = ({tag, status, description, linkLabel, link, isDisabled}) => {
  return (
    <div className={classNames(styles.container, {[styles.isDisabled]: isDisabled})}>
      {tag && <Tag tag={tag} mode={isDisabled ? 'basicDarkBlue' : undefined} />}
      {status && (
        <div>
          <p className={styles.status}>{status}</p>
        </div>
      )}
      {description && <div className={styles.description}>{render(description)}</div>}
      {link && (
        <div className={styles.linkContainer}>
          <Link link={link} label={linkLabel} />
        </div>
      )}
    </div>
  );
};

export default GlobalFeaturesItem;

GlobalFeaturesItem.propTypes = {
  tag: PropTypes.string,
  status: PropTypes.string,
  description: PropTypes.object,
  linkLabel: PropTypes.string,
  link: PropTypes.object,
  isDisabled: PropTypes.bool,
};
