import 'react-phone-input-2/lib/style.css';

import {yupResolver} from '@hookform/resolvers/yup';
import classNames from 'classnames';
import {gsap} from 'gsap';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import {useDebouncedCallback} from 'use-debounce';
import * as Yup from 'yup';

import {getTranslatedString} from '@/common/utils';
import InvalidSentForm from '@/components/InvalidSentForm/InvalidSentForm';
import RichText from '@/components/RichText/RichText';
import SendingForm from '@/components/SendingForm/SendingForm';
import ValidSentForm from '@/components/ValidSentForm/ValidSentForm';

import countries from './countries.json';
import * as styles from './Form.module.scss';

const Form = ({lang, typeOfForm, dataGlobalElements, translatedStrings}) => {
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [validationPhoneTriggered, setValidationPhoneTriggered] = useState(false);
  const [formWasValid, setFormWasValid] = useState(false);
  const [formWasInValid, setFormWasInValid] = useState(false);
  const [formisSending, setFormisSending] = useState(false);

  const [isSirenValid, setIsSirenValid] = useState(false);
  const [validationSirenTriggered, setValidationSirenTriggered] = useState(false);

  const form = useRef(null);
  const validSentForm = useRef(null);
  const invalidSentForm = useRef(null);
  const currentlyBeingSent = useRef(null);
  const hubspotfield = useRef(null);
  const parsedDataGlobalElements = {};

  let api_url = process.env.GATSBY_API_URL;
  if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined')
    api_url = window.location.origin.replace(window.location.host, `api.${window.location.host}`);

  if (dataGlobalElements && Object.keys(dataGlobalElements).length > 0) {
    dataGlobalElements = dataGlobalElements.map((x) => {
      const parsedContent = JSON.parse(x.node.content);
      return parsedContent.blocks[0];
    });

    dataGlobalElements.forEach((data) => {
      if (data.component === 'sendingForm') {
        parsedDataGlobalElements.sendingForm = data;
      } else if (data.component === 'invalidSentForm') {
        parsedDataGlobalElements.invalidSentForm = data;
      } else if (data.component === 'validSentForm' && data.form === 'contact') {
        parsedDataGlobalElements.validSentFormContact = data;
      } else if (data.component === 'validSentForm' && data.form != 'contact') {
        parsedDataGlobalElements.validSentFormRegister = data;
      } else if (data.component === 'CGUFormLabel' && data.form != 'contact') {
        parsedDataGlobalElements.cgu = data;
      }
    });
  }

  //add Phone regex
  Yup.addMethod(Yup.string, 'phoneValidator', function () {
    return this.test({
      name: 'phone',
      message: 'Ce télephone est invalide',
      test: () => {
        return isPhoneValid;
      },
    });
  });

  Yup.addMethod(Yup.string, 'sirenValidator', function () {
    return this.test({
      name: 'siren',
      message: 'Ce siren est invalide',
      test: () => {
        return isSirenValid;
      },
    });
  });

  const defaultCountries = {
    default: [{label: 'France', value: 'France'}],
    'fr-en': [{label: 'France', value: 'France'}],
    es: [{label: 'Spain', value: 'Spain'}],
    'es-en': [{label: 'Spain', value: 'Spain'}],
    'be-fr': [{label: 'Belgium', value: 'Belgium'}],
    'be-nl': [{label: 'Belgium', value: 'Belgium'}],
    de: [{label: 'Germany', value: 'Germany'}],
    'de-en': [{label: 'Germany', value: 'Germany'}],
    it: [{label: 'Germany', value: 'Germany'}],
    'it-en': [{label: 'Germany', value: 'Germany'}],
    nl: [{label: 'Netherlands', value: 'Netherlands'}],
    'nl-en': [{label: 'Netherlands', value: 'Netherlands'}],
    en: [{label: 'England', value: 'England'}],
  };

  // form validation rules
  let validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Ce champ est obligatoire'),
    lastName: Yup.string().required('Ce champ est obligatoire'),
    email: Yup.string()
      .required('Ce champ est obligatoire')
      .email('Cette addresse email est invalide'),
    phone: Yup.string().required('Ce champ est obligatoire').phoneValidator(),
    company: Yup.string().required('Ce champ est obligatoire'),
    siren: Yup.string(),
    revenue: Yup.string().required('Ce champ est obligatoire'),
    activity: Yup.string().required('Ce champ est obligatoire'),
    tos: Yup.bool().oneOf([true], "Veuillez accepter les CGU d'Alma"),
  });
  if (typeOfForm === 'signup') {
    validationSchema = Yup.object().shape({
      firstName: Yup.string().required('Ce champ est obligatoire'),
      lastName: Yup.string().required('Ce champ est obligatoire'),
      email: Yup.string()
        .required('Ce champ est obligatoire')
        .email('Cette addresse email est invalide'),
      phone: Yup.string().required('Ce champ est obligatoire').phoneValidator(),
      company: Yup.string().required('Ce champ est obligatoire'),
      country_of_incorporation: Yup.string()
        .required('Ce champ est obligatoire')
        .default(defaultCountries[lang][0]['value']),
      country_of_operations: Yup.string().default(defaultCountries[lang][0]['value']),
      business_volume: Yup.string().required('Ce champ est obligatoire'),
      cms: Yup.string().required('Ce champ est obligatoire'),
      tos: Yup.bool().oneOf([true], "Veuillez accepter les CGU d'Alma"),
    });
  }
  const formOptions = {resolver: yupResolver(validationSchema)};

  // get functions to build form with useForm() hook
  const {register, trigger, handleSubmit, control, formState} = useForm(formOptions);
  const {errors} = formState;

  const handlePhoneChange = (v, country, _, formattedNumber) => {
    let phoneValid = new RegExp(country.format.replace('+', '^\\+').replace(/\./g, '\\d')).test(
      formattedNumber
    );
    // meant to be temporary, PhoneInput seems to handle La Réunion badly
    phoneValid = true;
    setIsPhoneValid(phoneValid);
  };

  const handleSirenChange = useDebouncedCallback(function (event) {
    function isValidSiren(value) {
      // implementation of https://portal.hardis-group.com/pages/viewpage.action?pageId=120357227
      if (value.length !== 9) return false;
      let chars = [...value];
      var total = 0;
      chars.forEach(function (char, index) {
        let num = parseInt(char);
        if (isNaN(num)) return false;
        if (index % 2 !== 0) num *= 2;
        total += (num % 10) + Math.floor(num / 10);
      });
      return total % 10 === 0;
    }

    let value = event.target.value.replace(/\s/g, '');
    if (isValidSiren(value)) {
      fetch(`https://entreprise.data.gouv.fr/api/sirene/v3/unites_legales/${value}`).then(
        (response) => setIsSirenValid(response.status !== 404)
      );
    } else {
      setIsSirenValid(false);
    }
  }, 150); // debounce 150ms, gouv api limits to 7 requests / second

  useEffect(() => {
    if (invalidSentForm.current && validSentForm.current && currentlyBeingSent.current) {
      gsap.to([invalidSentForm.current, validSentForm.current, currentlyBeingSent.current], 0, {
        display: 'none',
        opacity: 0,
      });
    }
    var hubspot_cookie_match = document.cookie.match(new RegExp('(^| )hubspotutk=([^;]+)'));
    hubspotfield.current = hubspot_cookie_match ? hubspot_cookie_match[2] : '';

    const urlParams = new URLSearchParams(window.location.search);
    // This request will set a cookie containing referral and affiliation data
    fetch(`${api_url}/v1/signup/affiliate_cookie`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(Object.fromEntries(urlParams)),
    });
  }, []);

  useEffect(() => {
    if (validationPhoneTriggered) {
      trigger(['phone']);
    } else if (errors.phone) {
      trigger(['phone']);
      setValidationPhoneTriggered(true);
    }
  }, [isPhoneValid]);

  useEffect(() => {
    if (formisSending) {
      let tl = gsap.timeline();
      tl.to(form.current, 0.1, {opacity: 0, ease: 'power4.inOut'});
      tl.to(currentlyBeingSent.current, 0, {display: 'block'});
      tl.to(currentlyBeingSent.current, 0.1, {opacity: 1, ease: 'power4.inOut'});
    }

    if (formWasValid) {
      let tl = gsap.timeline();
      tl.to([form.current, currentlyBeingSent.current], 0.1, {
        opacity: 0,
        ease: 'power4.inOut',
      });
      tl.to(currentlyBeingSent.current, 0, {display: 'none'});
      tl.to(validSentForm.current, 0, {display: 'block'});
      tl.to(validSentForm.current, 0.1, {opacity: 1, ease: 'power4.inOut'});
    }

    if (formWasInValid) {
      let tl = gsap.timeline();
      tl.to([form.current, currentlyBeingSent.current], 0.1, {
        opacity: 0,
        ease: 'power4.inOut',
      });
      tl.to(currentlyBeingSent.current, 0, {display: 'none'});
      tl.to(invalidSentForm.current, 0, {display: 'block'});
      tl.to(invalidSentForm.current, 0.1, {opacity: 1, ease: 'power4.inOut'});
    }
  }, [formisSending, formWasValid, formWasInValid]);

  useEffect(() => {
    if (validationSirenTriggered) {
      trigger(['siren']);
    } else if (errors.siren) {
      trigger(['siren']);
      setValidationSirenTriggered(true);
    }
  }, [isSirenValid]);

  const onSubmit = (data) => {
    data['full_name'] = data['firstName'] + ' ' + data['lastName'];
    setFormisSending(true);
    window.dataLayer = window.dataLayer || [];
    if (typeOfForm === 'contact') {
      fetch(`${api_url}/v1/signup/demo-request`, {
        method: 'POST',
        body: JSON.stringify({data}),
        headers: {'Content-Type': 'application/json'},
      }).then((response) => {
        window.dataLayer.push({event: 'response', data: response});
        if (response.status == 200) {
          setFormWasValid(true);
        } else {
          setFormWasInValid(true);
        }
      });
    } else if (typeOfForm === 'signup') {
      fetch(`${api_url}/v1/signup/merchant`, {
        method: 'POST',
        body: JSON.stringify({data}),
        headers: {'Content-Type': 'application/json'},
      }).then((response) => {
        window.dataLayer.push({event: 'response', data: response});
        if (response.status == 200) {
          setFormWasValid(true);
        } else {
          setFormWasInValid(true);
        }
      });
    }
  };

  let sirenRegister = register('siren');
  let countryOfIncorporationRegister = register('country_of_incorporation');
  let countryOfOperationsRegister = register('country_of_operations');

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '46px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided) => ({
      ...provided,
      minHeight: '46px',
    }),

    input: (provided) => ({
      ...provided,
      height: '28px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      minHeight: '46px',
    }),
  };

  let phoneCountryMapping = {
    default: 'fr',
    en: 'gb',
  };
  let phoneCountry = phoneCountryMapping[lang.split('-')[0]] || lang.split('-')[0];

  return (
    <div className={styles.formWrapper}>
      <form onSubmit={handleSubmit(onSubmit)} ref={form}>
        <div className={classNames('col-12 col-md-12 col-lg-12')}>
          <h4>{getTranslatedString(lang, 'vos_coordonnees', translatedStrings)}</h4>
        </div>

        {/* First Name */}
        <div
          className={classNames('col-6 col-md-6', styles.inputTxtWrapper, {
            [styles.error]: errors.firstName,
          })}
        >
          <div className={styles.label}>
            <span>
              {getTranslatedString(lang, 'prenom', translatedStrings)
                ? getTranslatedString(lang, 'prenom', translatedStrings) + '*'
                : 'Prénom*'}
            </span>
            <input {...register('firstName', {required: true})} />
          </div>
          <div className={styles.errorMessage}>{errors.firstName?.message}</div>
        </div>
        {/* Last Name */}
        <div
          className={classNames('col-12 col-md-6', styles.inputTxtWrapper, {
            [styles.error]: errors.lastName,
          })}
        >
          <div className={styles.label}>
            <span>
              {getTranslatedString(lang, 'nom', translatedStrings)
                ? getTranslatedString(lang, 'nom', translatedStrings) + '*'
                : 'Nom*'}
            </span>
            <input {...register('lastName', {required: true})} />
          </div>
          <div className={styles.errorMessage}>{errors.lastName?.message}</div>
        </div>
        {/* Email */}
        <div
          className={classNames('col-12 col-md-6', styles.inputTxtWrapper, {
            [styles.error]: errors.email,
          })}
        >
          <div className={styles.label}>
            <span>
              {getTranslatedString(lang, 'email', translatedStrings)
                ? getTranslatedString(lang, 'email', translatedStrings) + '*'
                : 'Email*'}
            </span>
            <input {...register('email')} />
          </div>
          <div className={styles.errorMessage}>{errors.email?.message}</div>
        </div>
        {/* Téléphone */}
        <div
          className={classNames('col-12 col-md-6', styles.telephoneWrapper, {
            [styles.error]: errors.phone,
          })}
        >
          <div className={styles.label}>
            <span>
              {getTranslatedString(lang, 'telephone', translatedStrings)
                ? getTranslatedString(lang, 'telephone', translatedStrings) + '*'
                : 'Téléphone*'}
            </span>
            <Controller
              control={control}
              name='phone'
              render={({
                field: {onChange, ref},
                // field: {onChange, onBlur, value, name, ref},
                // fieldState: {invalid, isTouched, isDirty, error},
              }) => (
                <PhoneInput
                  country={phoneCountry}
                  autoFormat={false}
                  onChange={(value, format, _, formattedNumber) => {
                    onChange(value);
                    handlePhoneChange(value, format, _, formattedNumber);
                  }}
                  inputRef={ref}
                  countryCodeEditable={false}
                  inputClass={classNames(styles.input, {[styles.error]: errors.phone})}
                  buttonClass={classNames(styles.dropdown, {[styles.error]: errors.phone})}
                />
              )}
            />
          </div>
          <div className={styles.errorMessage}>{errors.phone?.message}</div>
        </div>
        <div className={classNames('col-12 col-md-12 col-lg-12')}>
          <h4>{getTranslatedString(lang, 'votre_entreprise', translatedStrings)}</h4>
        </div>
        {/* Nom de l'entreprise */}
        <div
          className={classNames('col-12 col-md-6', styles.inputTxtWrapper, {
            [styles.error]: errors.company,
          })}
        >
          <div className={styles.label}>
            <span>
              {getTranslatedString(lang, 'nomentreprise', translatedStrings)
                ? getTranslatedString(lang, 'nomentreprise', translatedStrings) + '*'
                : "Nom de l'entreprise"}
            </span>
            <input {...register('company')} />
          </div>
          <div className={styles.errorMessage}>{errors.company?.message}</div>
        </div>
        {/* Site Web */}
        <>
          <div
            className={classNames('col-12 col-md-6', styles.inputTxtWrapper, {
              [styles.error]: errors.website,
            })}
          >
            <div className={styles.label}>
              <span>
                {getTranslatedString(lang, 'siteweb', translatedStrings)
                  ? getTranslatedString(lang, 'siteweb', translatedStrings)
                  : 'Website'}
              </span>
              <input {...register('website')} />
            </div>
          </div>
        </>
        {/* Contact : Activité, CA & Type de client */}
        {typeOfForm === 'contact' && (
          <>
            <div
              className={classNames('col-12 col-md-6', styles.inputTxtWrapper, {
                [styles.error]: errors.siren,
              })}
            >
              <div className={styles.label}>
                <span>{getTranslatedString(lang, 'siren', translatedStrings)}</span>
                <input
                  {...sirenRegister}
                  onChange={(event) => {
                    event.persist();
                    sirenRegister.onChange(event);
                    handleSirenChange(event);
                  }}
                />
              </div>
              <div className={styles.errorMessage}>{errors.siren?.message}</div>
            </div>

            <div
              className={classNames('col-12 col-md-6', styles.inputSelectWrapper, {
                [styles.error]: errors.activity,
              })}
            >
              <div className={styles.label}>
                <span>
                  {getTranslatedString(lang, 'activite', translatedStrings)
                    ? getTranslatedString(lang, 'activite', translatedStrings) + '*'
                    : 'Activité'}
                </span>

                <select {...register('activity')} defaultValue={''}>
                  <option value='' disabled>
                    {getTranslatedString(lang, 'activite', translatedStrings)
                      ? getTranslatedString(lang, 'activite', translatedStrings) + '*'
                      : 'Votre activité'}
                  </option>
                  <option value='personal_equipement'>
                    {getTranslatedString(lang, 'personal_equipement', translatedStrings)}
                  </option>
                  <option value='home_and_garden'>
                    {getTranslatedString(lang, 'home_and_garden', translatedStrings)}
                  </option>
                  <option value='services'>
                    {getTranslatedString(lang, 'services', translatedStrings)}
                  </option>
                  <option value='healthcare'>
                    {getTranslatedString(lang, 'healthcare', translatedStrings)}
                  </option>
                  <option value='travel_and_leisure'>
                    {getTranslatedString(lang, 'travel_and_leisure', translatedStrings)}
                  </option>
                  <option value='other'>
                    {getTranslatedString(lang, 'other', translatedStrings)}
                  </option>
                </select>
              </div>
              <div className={styles.errorMessage}>{errors.activity?.message}</div>
            </div>

            <div
              className={classNames('col-12', styles.inputSelectWrapper, {
                [styles.error]: errors.revenue,
              })}
            >
              <div className={styles.label}>
                <span>
                  {getTranslatedString(lang, 'volumedaffaire', translatedStrings)
                    ? getTranslatedString(lang, 'volumedaffaire', translatedStrings) + '*'
                    : "Volume d'affaires"}
                </span>
                <select {...register('revenue')} defaultValue={''}>
                  <option value='' disabled>
                    {getTranslatedString(lang, 'your_business_volume', translatedStrings)}
                  </option>
                  <option value='0'>
                    {getTranslatedString(lang, 'business_volume_0', translatedStrings)}
                  </option>
                  <option value='0-0.5'>
                    {getTranslatedString(lang, 'business_volume_0-0.5', translatedStrings)}
                  </option>
                  <option value='0.5-2'>
                    {getTranslatedString(lang, 'business_volume_0.5-2', translatedStrings)}
                  </option>
                  <option value='2-10'>
                    {getTranslatedString(lang, 'business_volume_2-10', translatedStrings)}
                  </option>
                  <option value='10-50'>
                    {getTranslatedString(lang, 'business_volume_10-50', translatedStrings)}
                  </option>
                  <option value='50-150'>
                    {getTranslatedString(lang, 'business_volume_50-150', translatedStrings)}
                  </option>
                  <option value='150+'>
                    {getTranslatedString(lang, 'business_volume_150+', translatedStrings)}
                  </option>
                </select>
              </div>
              <div className={styles.errorMessage}>{errors.revenue?.message}</div>
            </div>
            {/* <div
              className={classNames('col-12', styles.inputCheckboxWrapper, {
                [styles.error]: errors.clientType,
              })}
            >
              <div className={styles.label}>
                <span>Vos clients sont</span>
                <div className={styles.checkboxesInnerWrapper}>
                  <div className={styles.oneCheckboxWrapper}>
                    <label>
                      <span>Des Particuliers</span>
                      <input type='checkbox' {...register('individual')} />
                      <div className={styles.check}></div>
                    </label>
                  </div>
                  <div className={styles.oneCheckboxWrapper}>
                    <label>
                      <span>Des Professionnels</span>
                      <input type='checkbox' {...register('professional')} />
                      <div className={styles.check}></div>
                    </label>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
        {/* Signup : Plateforme Ecommerce */}
        {typeOfForm === 'signup' && (
          <div>
            <div
              className={classNames('col-12', styles.inputSelectWrapper, {
                [styles.error]: errors.country_of_incorporation,
              })}
            >
              <div className={styles.label}>
                <span>{getTranslatedString(lang, 'pays_domiciliation', translatedStrings)}*</span>
                <Select
                  defaultValue={defaultCountries[lang]}
                  name='country_of_incorporation'
                  options={countries}
                  styles={customStyles}
                  onChange={(event) => {
                    countryOfIncorporationRegister.onChange({
                      target: {name: 'country_of_incorporation', value: event.value},
                    });
                  }}
                />
              </div>
              <div className={styles.errorMessage}>{errors.country_of_incorporation?.message}</div>
            </div>

            <div
              className={classNames('col-12', styles.inputSelectWrapper, {
                [styles.error]: errors.countries_of_operations,
              })}
            >
              <div className={styles.label}>
                <span>{getTranslatedString(lang, 'pays_vente', translatedStrings)}</span>
                <Select
                  defaultValue={defaultCountries[lang]}
                  isMulti
                  name='countries_of_operations'
                  options={countries}
                  styles={customStyles}
                  onChange={(event) => {
                    let value = '';
                    for (const item of event) {
                      value += item.value + ',';
                    }
                    countryOfOperationsRegister.onChange({
                      target: {name: 'country_of_operations', value: value.slice(0, -1)},
                    });
                  }}
                />
              </div>
              <div className={styles.errorMessage}>{errors.countries_of_operations?.message}</div>
            </div>

            <div
              className={classNames('col-12', styles.inputSelectWrapper, {
                [styles.error]: errors.business_volume,
              })}
            >
              <div className={styles.label}>
                <span>{getTranslatedString(lang, 'volumedaffaire', translatedStrings)}*</span>
                <select {...register('business_volume')} defaultValue={''}>
                  <option value='' disabled>
                    {getTranslatedString(lang, 'your_business_volume', translatedStrings)}
                  </option>
                  <option value='0'>
                    {getTranslatedString(lang, 'business_volume_0', translatedStrings)}
                  </option>
                  <option value='0-0.5'>
                    {getTranslatedString(lang, 'business_volume_0-0.5', translatedStrings)}
                  </option>
                  <option value='0.5-2'>
                    {getTranslatedString(lang, 'business_volume_0.5-2', translatedStrings)}
                  </option>
                  <option value='2-10'>
                    {getTranslatedString(lang, 'business_volume_2-10', translatedStrings)}
                  </option>
                  <option value='10-50'>
                    {getTranslatedString(lang, 'business_volume_10-50', translatedStrings)}
                  </option>
                  <option value='50-150'>
                    {getTranslatedString(lang, 'business_volume_50-150', translatedStrings)}
                  </option>
                  <option value='150+'>
                    {getTranslatedString(lang, 'business_volume_150+', translatedStrings)}
                  </option>
                </select>
              </div>
              <div className={styles.errorMessage}>{errors.business_volume?.message}</div>
            </div>

            <div
              className={classNames('col-12', styles.inputSelectWrapper, {
                [styles.error]: errors.cms,
              })}
            >
              <div className={styles.label}>
                <span>
                  {getTranslatedString(lang, 'plateformeecommerce', translatedStrings)
                    ? getTranslatedString(lang, 'plateformeecommerce', translatedStrings) + '*'
                    : 'Plateforme E-commerce*'}
                </span>

                <select {...register('cms')} defaultValue={''}>
                  <option value='' disabled>
                    {getTranslatedString(lang, 'plateformeecommerce', translatedStrings)
                      ? getTranslatedString(lang, 'plateformeecommerce', translatedStrings)
                      : 'Plateforme E-commerce'}
                  </option>
                  <option value='prestashop'>{'Prestashop'}</option>
                  <option value='shopify'>{'Shopify'}</option>
                  <option value='magento'>{'Magento'}</option>
                  <option value='woocommerce'>{'WooCommerce'}</option>
                  <option value='wee-commerce'>{'wee-commerce'}</option>
                  <option value='salesforce_commerce_cloud'>{'Salesforce Commerce Cloud'}</option>
                  <option value='sylius'>{'Sylius'}</option>
                  <option value='wizishop'>{'Wizishop'}</option>
                  <option value='other'>
                    {getTranslatedString(lang, 'other', translatedStrings)
                      ? getTranslatedString(lang, 'other', translatedStrings)
                      : 'Autre'}
                  </option>
                  <option value='custom'>
                    {getTranslatedString(lang, 'jai_un_site_personalise', translatedStrings)
                      ? getTranslatedString(lang, 'jai_un_site_personalise', translatedStrings)
                      : "J'ai un site personnalisé"}
                  </option>
                  <option value='does_not_know'>
                    {getTranslatedString(lang, 'je_ne_sais_pas', translatedStrings)
                      ? getTranslatedString(lang, 'je_ne_sais_pas', translatedStrings)
                      : 'Je ne sais pas'}
                  </option>
                </select>
              </div>
              <div className={styles.errorMessage}>{errors.cms?.message}</div>
            </div>
            <div
              className={classNames('col-12', styles.tosWrapper, {
                [styles.error]: errors.tos,
              })}
            >
              <label>
                {parsedDataGlobalElements &&
                parsedDataGlobalElements.cgu &&
                parsedDataGlobalElements.cgu.content ? (
                  <span>
                    <RichText content={parsedDataGlobalElements.cgu.content} />
                  </span>
                ) : (
                  <>
                    <span>
                      {"J'accepte les"}{' '}
                      <a href='https://getalma.eu/legal/terms/cgv' target='_blank' rel='noreferrer'>
                        {"CGU d'Alma"}
                      </a>
                      {
                        ", je l'approuve et déclare être habilité à engager l'entreprise à ses termes. Je reconnais que la création d'un compte constitue une acceptation irrévocable et sans réserve du Contrat."
                      }
                    </span>
                    <span>
                      {"Dès l'acceptation, vous recevrez un email pour confirmer votre identité."}
                    </span>
                  </>
                )}
                <input type='checkbox' {...register('tos')} />
                <div className={styles.check}></div>
              </label>
              <div className={styles.errorMessage}>{errors.tos?.message}</div>
            </div>
          </div>
        )}
        {/* Hidden Form */}
        {typeOfForm === 'signup' && (
          <input
            id='hubspotVisitorIdInput'
            type='hidden'
            name='hubspot_visitor_id'
            ref={hubspotfield}
            value={hubspotfield.current?.value}
          />
        )}
        {/* Submit */}
        <div className={'col-12'}>
          {typeOfForm === 'signup' && (
            <input
              type='submit'
              value={
                getTranslatedString(lang, 'creeruncompte', translatedStrings)
                  ? getTranslatedString(lang, 'creeruncompte', translatedStrings)
                  : 'Créer un compte'
              }
            />
          )}
          {typeOfForm === 'contact' && (
            <input
              type='submit'
              value={
                getTranslatedString(lang, 'contacteznous', translatedStrings)
                  ? getTranslatedString(lang, 'contacteznous', translatedStrings)
                  : 'Contactez Nous'
              }
            />
          )}
        </div>
      </form>

      {formisSending && !formWasValid && parsedDataGlobalElements && (
        <SendingForm
          currentlyBeingSent={currentlyBeingSent}
          title={parsedDataGlobalElements.sendingForm.title}
          subtitle={parsedDataGlobalElements.sendingForm.subtitle}
        />
      )}

      {parsedDataGlobalElements && formWasValid && typeOfForm === 'signup' && (
        <ValidSentForm
          validSentForm={validSentForm}
          title={parsedDataGlobalElements.validSentFormRegister.title}
          subtitle={parsedDataGlobalElements.validSentFormRegister.subtitle}
          CTA={parsedDataGlobalElements.validSentFormRegister.CTA}
        />
      )}

      {parsedDataGlobalElements && formWasValid && typeOfForm === 'contact' && (
        <ValidSentForm
          validSentForm={validSentForm}
          title={parsedDataGlobalElements.validSentFormContact.title}
          subtitle={parsedDataGlobalElements.validSentFormContact.subtitle}
          CTA={parsedDataGlobalElements.validSentFormContact.CTA}
        />
      )}

      {parsedDataGlobalElements && formWasInValid && (
        <InvalidSentForm
          invalidSentForm={invalidSentForm}
          title={parsedDataGlobalElements.invalidSentForm.title}
          subtitle={parsedDataGlobalElements.invalidSentForm.subtitle}
          CTA={parsedDataGlobalElements.invalidSentForm.CTA}
        />
      )}
    </div>
  );
};

export default Form;

Form.propTypes = {
  lang: PropTypes.string,
  translatedStrings: PropTypes.object,
  typeOfForm: PropTypes.string,
  dataGlobalElements: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
