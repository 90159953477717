import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './CardNumber.module.scss';

export const CardNumber = ({number, unit, cardContent, cardTheme}) => {
  return (
    <div className={classNames(styles.oneCardNumber)}>
      <p className={classNames(styles.cardTitle, styles[cardTheme])}>
        <span className={styles.figure}>{number}</span>
        <span className={styles.percent}>{unit}</span>
      </p>
      <p className={classNames(styles.cardContent)}>
        <span>{cardContent}</span>
      </p>
    </div>
  );
};
export default CardNumber;

CardNumber.propTypes = {
  number: PropTypes.string,
  unit: PropTypes.string,
  cardTheme: PropTypes.string,
  cardContent: PropTypes.string,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
};

CardNumber.defaultProps = {
  number: '+20',
  cardTheme: 'orangeProduit',
  cardContent: '',
};
