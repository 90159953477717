// extracted by mini-css-extract-plugin
export var heroComponent = "Hero-module--heroComponent--1l9Pu";
export var backgroundwhite = "Hero-module--backgroundwhite--3Plp5";
export var backgroundbeige = "Hero-module--backgroundbeige--2bjri";
export var backgroundblue = "Hero-module--backgroundblue--X8xZA";
export var backgrounddarkblue = "Hero-module--backgrounddarkblue--1HPHZ";
export var backgroundvertProduit = "Hero-module--backgroundvertProduit--2pAyO";
export var backgroundjauneProduit = "Hero-module--backgroundjauneProduit--2nWG6";
export var backgroundorangeProduit = "Hero-module--backgroundorangeProduit--ORXEt";
export var backgroundrougeDistrib = "Hero-module--backgroundrougeDistrib--3mQwx";
export var heroComponentContainer = "Hero-module--heroComponentContainer--CEL_F";
export var heroComponentRow = "Hero-module--heroComponentRow--1ly2L";
export var contentCol = "Hero-module--contentCol--18Y8a";
export var titleRichText = "Hero-module--titleRichText--XxFaZ";
export var blue = "Hero-module--blue--3Jell";
export var vertProduit = "Hero-module--vertProduit--22Dvk";
export var jauneProduit = "Hero-module--jauneProduit---mPLe";
export var orangeProduit = "Hero-module--orangeProduit--GGCFM";
export var rougeDistrib = "Hero-module--rougeDistrib--1x45u";
export var introTxt = "Hero-module--introTxt--1WD-Y";
export var innerwrapper = "Hero-module--innerwrapper--Acx4Y";
export var tagwrapper = "Hero-module--tagwrapper--16Sv2";
export var buttonlinkWrapper = "Hero-module--buttonlinkWrapper--17z8Z";
export var heroimageCol = "Hero-module--heroimageCol--2cUH6";
export var backgroundContent = "Hero-module--backgroundContent--NI_fo";
export var roundSvgWrapper = "Hero-module--roundSvgWrapper--1GD7o";
export var heroImageCol = "Hero-module--heroImageCol--1aO7K";
export var heroImageWrapper = "Hero-module--heroImageWrapper--QEuKS";
export var shadow = "Hero-module--shadow--13wIm";