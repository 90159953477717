// extracted by mini-css-extract-plugin
export var globalTagComponent = "GlobalTag-module--globalTagComponent--2dFtR";
export var backgroundtransparent = "GlobalTag-module--backgroundtransparent--2FFt7";
export var backgroundblack = "GlobalTag-module--backgroundblack--2wU3I";
export var backgroundwhite = "GlobalTag-module--backgroundwhite--QZiGc";
export var backgroundbeige = "GlobalTag-module--backgroundbeige--2IWWM";
export var backgroundgrey = "GlobalTag-module--backgroundgrey--3oEOY";
export var backgroundgreyLabel = "GlobalTag-module--backgroundgrey-label--2nxd5";
export var backgroundblue = "GlobalTag-module--backgroundblue--3fBJv";
export var backgrounddarkBlue = "GlobalTag-module--backgrounddark-blue--1wg7K";
export var backgroundlightBlue = "GlobalTag-module--backgroundlight-blue--12cKO";
export var backgroundpressedBlue = "GlobalTag-module--backgroundpressed-blue--3hJdB";
export var backgroundorangeProduit = "GlobalTag-module--backgroundorange-produit--1DJqo";
export var backgroundjauneProduit = "GlobalTag-module--backgroundjaune-produit--1bV7a";
export var backgroundvertProduit = "GlobalTag-module--backgroundvert-produit--1CUst";
export var backgroundrougeDistrib = "GlobalTag-module--backgroundrouge-distrib--1d1jn";
export var withIcon = "GlobalTag-module--withIcon--2l9pm";
export var icon = "GlobalTag-module--icon--glIQb";
export var title = "GlobalTag-module--title--1TLkM";
export var textcolortransparent = "GlobalTag-module--textcolortransparent--39e_4";
export var textcolorblack = "GlobalTag-module--textcolorblack--1gpYB";
export var textcolorwhite = "GlobalTag-module--textcolorwhite--20fDX";
export var textcolorbeige = "GlobalTag-module--textcolorbeige--1PbgJ";
export var textcolorgrey = "GlobalTag-module--textcolorgrey--kjRY1";
export var textcolorgreyLabel = "GlobalTag-module--textcolorgrey-label--3meSI";
export var textcolorblue = "GlobalTag-module--textcolorblue--2tHX8";
export var textcolordarkBlue = "GlobalTag-module--textcolordark-blue--3OqgR";
export var textcolorlightBlue = "GlobalTag-module--textcolorlight-blue--2Njdz";
export var textcolorpressedBlue = "GlobalTag-module--textcolorpressed-blue--2SJwZ";
export var textcolororangeProduit = "GlobalTag-module--textcolororange-produit--2X3JL";
export var textcolorjauneProduit = "GlobalTag-module--textcolorjaune-produit--25FzF";
export var textcolorvertProduit = "GlobalTag-module--textcolorvert-produit--2mveT";
export var textcolorrougeDistrib = "GlobalTag-module--textcolorrouge-distrib--1cKo-";
export var filled = "GlobalTag-module--filled--_sUmo";
export var outlined = "GlobalTag-module--outlined--NXqob";
export var bordertransparent = "GlobalTag-module--bordertransparent--3AybR";
export var borderblack = "GlobalTag-module--borderblack--1e17W";
export var borderwhite = "GlobalTag-module--borderwhite--26lqX";
export var borderbeige = "GlobalTag-module--borderbeige--3ezEa";
export var bordergrey = "GlobalTag-module--bordergrey--2tSVc";
export var bordergreyLabel = "GlobalTag-module--bordergrey-label--2YHGE";
export var borderblue = "GlobalTag-module--borderblue--1snPQ";
export var borderdarkBlue = "GlobalTag-module--borderdark-blue--3XILV";
export var borderlightBlue = "GlobalTag-module--borderlight-blue--3GC-7";
export var borderpressedBlue = "GlobalTag-module--borderpressed-blue--2bAW5";
export var borderorangeProduit = "GlobalTag-module--borderorange-produit--1TcEN";
export var borderjauneProduit = "GlobalTag-module--borderjaune-produit--3Jxku";
export var bordervertProduit = "GlobalTag-module--bordervert-produit--2tDnk";
export var borderrougeDistrib = "GlobalTag-module--borderrouge-distrib--2IgTp";
export var neutral = "GlobalTag-module--neutral--11Hpz";
export var selected = "GlobalTag-module--selected--26FSE";