import classNames from 'classnames';
import {gsap} from 'gsap';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import HalfCircleOutlinedSvg from '@/assets/assetsJsx/HalfCircleOutlinedSvg';
import HalfCircleSvg from '@/assets/assetsJsx/HalfCircleSvg';
import Button from '@/components/Button/Button';

import * as styles from './CtaBannerSecondary.module.scss';

const CtaBannerSecondary = ({
  backgroundColorBanner,
  title,
  subtitle,
  CTA,
  themeColor,
  circleStyle,
}) => {
  const svgCircle = useRef(null);
  const handleMouseEnter = () => {
    gsap.to(svgCircle.current, 0.4, {scale: 1.4, x: '-=40', ease: 'power4.inOut'});
  };
  const handleMouseLeave = () => {
    gsap.to(svgCircle.current, 0.4, {scale: 1, x: 0, ease: 'power4.inOut'});
  };
  const lightThemes = ['beige', 'white'];

  return (
    <div
      className={classNames(styles.ctaBannerPrimary, {
        [styles.lightMode]: lightThemes.includes(backgroundColorBanner),
      })}
    >
      <div className={styles.wrapper}>
        <div
          className={classNames(styles.innerWrapper, styles['background' + backgroundColorBanner])}
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          {(title || subtitle) && (
            <div className={styles.contentWrapper}>
              <div className={classNames(styles.title)}>{render(title)}</div>
              <div className={classNames(styles.subtitle)}>{render(subtitle)}</div>
            </div>
          )}

          {CTA[0] && (
            <div className={styles.ctaWrapper}>
              {CTA[0].button.map((cta) => (
                <Button key={cta._uid} {...cta} />
              ))}
            </div>
          )}
          {circleStyle === 'filled' ? (
            <div
              className={classNames(styles.circleSvg, styles.filled, styles[themeColor])}
              ref={svgCircle}
            >
              <HalfCircleSvg />
            </div>
          ) : (
            <div
              className={classNames(styles.circleSvg, styles.outlined, styles[themeColor])}
              ref={svgCircle}
            >
              <HalfCircleOutlinedSvg />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CtaBannerSecondary;

CtaBannerSecondary.propTypes = {
  backgroundColorBanner: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.object.isRequired,
  CTA: PropTypes.array,
  list: PropTypes.object,
  listType: PropTypes.string,
  circleStyle: PropTypes.string,
  themeColor: PropTypes.string,
};
