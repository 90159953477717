// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--3mBiF";
export var backgroundwhite = "Footer-module--backgroundwhite--1p91q";
export var backgroundbeige = "Footer-module--backgroundbeige--2dnFF";
export var backgroundblue = "Footer-module--backgroundblue--1ypNC";
export var backgrounddarkblue = "Footer-module--backgrounddarkblue--32k2A";
export var backgroundvertProduit = "Footer-module--backgroundvertProduit--1ajWh";
export var backgroundjauneProduit = "Footer-module--backgroundjauneProduit--K-vcC";
export var backgroundorangeProduit = "Footer-module--backgroundorangeProduit--e0eXm";
export var backgroundrougeDistrib = "Footer-module--backgroundrougeDistrib--3IAAK";
export var footerContainer = "Footer-module--footerContainer--1joKE";
export var footerLogoCol = "Footer-module--footerLogoCol--3Emq4";
export var logoLink = "Footer-module--logoLink--2PgrM";
export var logoContainer = "Footer-module--logoContainer--VvUFt";
export var addressCol = "Footer-module--addressCol--2B-sp";
export var footerCircleSvgRow = "Footer-module--footerCircleSvgRow--3hjDH";
export var footerCircleSvgCol = "Footer-module--footerCircleSvgCol--3nWyr";
export var white = "Footer-module--white--V1Dtf";
export var beige = "Footer-module--beige--3IwU8";
export var blue = "Footer-module--blue--3-OAL";
export var darkBlue = "Footer-module--darkBlue--P_bb7";
export var orangeProduit = "Footer-module--orangeProduit--1jICA";
export var jauneProduit = "Footer-module--jauneProduit--1DdA1";
export var vertProduit = "Footer-module--vertProduit--YjVn-";
export var rougeDistrib = "Footer-module--rougeDistrib--l7Yj-";
export var trustpilot_wrapper = "Footer-module--trustpilot_wrapper--1XEOy";
export var languageCol = "Footer-module--languageCol--1bw1F";