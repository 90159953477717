import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@/components/Button/Button';

import * as styles from './ButtonAndLink.module.scss';

export const ButtonAndLink = ({
  additionalTxt,
  additionalLinkLabel,
  additionalLinkHref: {cached_url, linktype},
  additionnalLinkNofollowNoopener,
  additionnalLinkTargetBlank,
  button,
}) => {
  if (cached_url && linktype === 'story' && cached_url.startsWith('/')) {
    cached_url = cached_url.substring(1);
  }

  if (cached_url && cached_url.includes('home')) {
    cached_url.replace('home', '');
  }
  return (
    <div className={styles.buttonLinkComponent}>
      <div className={styles.buttonWrapper}>
        {button[0] &&
          button.map((button) => {
            return <Button key={button._uid} {...button} />;
          })}
      </div>
      <div className={styles.infosWrapper}>
        <div className={styles.question}>{additionalTxt}</div>
        <div className={styles.link}>
          {linktype === 'story' && <Link to={`/${cached_url}`}>{additionalLinkLabel}</Link>}
          {linktype === 'url' &&
            (additionnalLinkTargetBlank ? (
              <a
                href={cached_url}
                rel={`noreferrer ${additionnalLinkNofollowNoopener}`}
                target='_blank'
              >
                {additionalLinkLabel}
              </a>
            ) : (
              <a href={cached_url} rel={`${additionnalLinkNofollowNoopener}`}>
                {additionalLinkLabel}
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ButtonAndLink;

ButtonAndLink.propTypes = {
  button: PropTypes.array,
  additionalTxt: PropTypes.string,
  additionalLinkLabel: PropTypes.string,
  additionalLinkHref: PropTypes.object,
  additionnalLinkNofollowNoopener: PropTypes.string,
  additionnalLinkTargetBlank: PropTypes.bool,
};

ButtonAndLink.defaultProps = {
  buttonMode: 'primary',
  additionnalLinkNofollowNoopener: '',
  additionnalLinkTargetBlank: false,
};
