// extracted by mini-css-extract-plugin
export var contactSignupSection = "ContactSignup-module--contactSignupSection--2mwAJ";
export var backgroundwhite = "ContactSignup-module--backgroundwhite--qUcb4";
export var backgroundbeige = "ContactSignup-module--backgroundbeige--2YXpr";
export var backgroundblue = "ContactSignup-module--backgroundblue--Haupg";
export var backgrounddarkblue = "ContactSignup-module--backgrounddarkblue--14Vgs";
export var backgroundvertProduit = "ContactSignup-module--backgroundvertProduit--3X0Qk";
export var backgroundjauneProduit = "ContactSignup-module--backgroundjauneProduit--nYeTf";
export var backgroundorangeProduit = "ContactSignup-module--backgroundorangeProduit--_hCt6";
export var backgroundrougeDistrib = "ContactSignup-module--backgroundrougeDistrib--3Xneu";
export var formContainer = "ContactSignup-module--formContainer--31Q1c";
export var formCol = "ContactSignup-module--formCol--1iMOy";
export var titleWrapper = "ContactSignup-module--titleWrapper--3ljju";
export var blue = "ContactSignup-module--blue--1WOIx";
export var rougeDistrib = "ContactSignup-module--rougeDistrib--2SCdS";
export var vertProduit = "ContactSignup-module--vertProduit--3f7tc";
export var jauneProduit = "ContactSignup-module--jauneProduit--Q39S6";
export var orangeProduit = "ContactSignup-module--orangeProduit--1hdAY";
export var beige = "ContactSignup-module--beige--2TTmE";
export var link = "ContactSignup-module--link--r8-to";
export var formWrapper = "ContactSignup-module--formWrapper--2BnKj";
export var carouselCol = "ContactSignup-module--carouselCol--j9MZK";
export var carouselWrapper = "ContactSignup-module--carouselWrapper--1PUql";
export var paginationWrapper = "ContactSignup-module--paginationWrapper--2ogHc";
export var paginationDot = "ContactSignup-module--paginationDot--7HPHo";
export var currentSlide = "ContactSignup-module--currentSlide--3lacf";
export var carouselInnerWrapper = "ContactSignup-module--carouselInnerWrapper--2RQO_";
export var cardIconWrapper = "ContactSignup-module--cardIconWrapper--3nZZ0";
export var sidePannelWrapper = "ContactSignup-module--sidePannelWrapper--3ZGcz";
export var blueWrapper = "ContactSignup-module--blueWrapper--tmN7I";
export var circleWrapper = "ContactSignup-module--circleWrapper---CKA1";
export var white = "ContactSignup-module--white--1U1RT";
export var darkBlue = "ContactSignup-module--darkBlue--2NL2C";