// extracted by mini-css-extract-plugin
export var logoLargeComponent = "LogoLarge-module--logoLargeComponent--1nDCC";
export var backgroundwhite = "LogoLarge-module--backgroundwhite--1Co_u";
export var backgroundbeige = "LogoLarge-module--backgroundbeige--3LHA7";
export var backgroundblue = "LogoLarge-module--backgroundblue--3OMbI";
export var backgrounddarkblue = "LogoLarge-module--backgrounddarkblue--3voED";
export var backgroundvertProduit = "LogoLarge-module--backgroundvertProduit--3BQYl";
export var backgroundjauneProduit = "LogoLarge-module--backgroundjauneProduit--2kC4x";
export var backgroundorangeProduit = "LogoLarge-module--backgroundorangeProduit--r1Ho7";
export var backgroundrougeDistrib = "LogoLarge-module--backgroundrougeDistrib--10L0n";
export var noPaddingTop = "LogoLarge-module--noPaddingTop--1fozE";
export var noPaddingBottom = "LogoLarge-module--noPaddingBottom--12csn";
export var logoLargeComponentContainer = "LogoLarge-module--logoLargeComponentContainer--20w9h";
export var logoLargeComponentRow = "LogoLarge-module--logoLargeComponentRow--1awXY";
export var contentCol = "LogoLarge-module--contentCol--2Zr-Z";
export var titleRichText = "LogoLarge-module--titleRichText--TE-is";
export var buttonlinkWrapper = "LogoLarge-module--buttonlinkWrapper--Fk7tj";
export var logosCol = "LogoLarge-module--logosCol--3ewoa";
export var oneLogo = "LogoLarge-module--oneLogo--lMzsq";