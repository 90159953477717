import classNames from 'classnames';
import Img from 'gatsby-image';
import {getFluidGatsbyImage} from 'gatsby-storyblok-image';
import {gsap, Power3, TimelineLite} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import CircleSvg from '@/assets/assetsJsx/CircleSvg.js';
import BackgroundCanvas from '@/components/BackgroundCanvas/BackgroundCanvas';

import * as styles from './Split5050Carousel.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

const Split5050Carousel = ({
  layout,
  title,
  titleSize,
  slides,
  subtitle,
  carouselType,
  contentColorTheme,
  shadowToggle,
  circleToggle,
  backgroundCirclePosition,
  canvasPathPoints,
  automaticSlide,
  noPaddingTop,
  noPaddingBottom,
}) => {
  const [canvasPoints, setCanvasPoints] = useState([]);
  const [slidesImagesWrappersLoaded, setSlidesImagesWrappersLoaded] = useState(false);
  const [autoSlide, setAutoSlide] = useState(false);
  const [isPause, setPause] = useState(false);
  const [timer, setTimer] = useState(null);
  const [filteredSlides, setFilteredSlides] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const slideRef = useRef([0, 1]);
  const imgsWrapper = useRef(null);
  const slideImgWrappersRef = useRef([]);
  const imgRatios = useRef([]);
  const carouselTabWrapper = useRef(null);
  const carouselTabs = useRef([]);
  const timelineElement = useRef(null);

  const trigger = useRef(null);
  const textContent = useRef(null);
  const circleSvg = useRef(null);
  const apparitionAnimPlayed = useRef(false);

  const addSlideImgWrappers = (el) => {
    if (el && !slideImgWrappersRef.current.includes(el)) {
      slideImgWrappersRef.current.push(el);
      if (filteredSlides.length === slideImgWrappersRef.current.length) {
        setSlidesImagesWrappersLoaded(true);
      }
    }
  };
  const addSlideImgsRatios = (aspectRatio) => {
    imgRatios.current.push(aspectRatio);
  };
  const addCarouselTabs = (el) => {
    if (el && !carouselTabs.current.includes(el)) {
      carouselTabs.current.push(el);
    }
  };
  const switchSlide = (target, currentIndex) => {
    if (carouselType === 'timeline') {
      const wrapperYpos = carouselTabWrapper.current.getBoundingClientRect().y;
      const currentYpos =
        carouselTabs.current[currentIndex].getBoundingClientRect().y - wrapperYpos;
      const nextYpos = carouselTabs.current[target].getBoundingClientRect().y - wrapperYpos;

      const distanceY = nextYpos - currentYpos;
      gsap.to(timelineElement.current, 0.2, {height: '+=' + distanceY, ease: Power3.easeOut});
    }

    let tl = new TimelineLite();
    tl.add(function () {
      setCurrentTabIndex(target);
    });
    tl.to(slideImgWrappersRef.current[currentIndex], 0.4, {opacity: 0, ease: Power3.easeOut});
    tl.to(slideImgWrappersRef.current[target], 0.4, {opacity: 1, ease: Power3.easeOut}, '-=.2');
  };
  const handleClickTab = (event, target) => {
    event.preventDefault();
    const prevIndex = slideRef.current[0];
    slideRef.current = [target, prevIndex];
    switchSlide(slideRef.current[0], slideRef.current[1]);
  };
  const autoSwitchTab = () => {
    const prevIndex = slideRef.current[0];
    let target = parseInt(slideRef.current[0] + 1);
    if (target > filteredSlides.length - 1) {
      target = 0;
    }
    slideRef.current = [target, prevIndex];
    switchSlide(slideRef.current[0], slideRef.current[1]);
  };

  useEffect(() => {
    ScrollTrigger.create({
      trigger: trigger.current,
      start: 'top bottom-=300',
      // markers: true,
      onEnter: function () {
        if (!apparitionAnimPlayed.current) {
          let tl = new TimelineLite();
          tl.fromTo(
            textContent.current,
            0.4,
            {
              y: '200',
              opacity: 0,
              ease: 'power4.inOut',
            },
            {
              y: '0',
              opacity: 1,
              ease: 'power4.inOut',
            }
          );
          tl.fromTo(
            imgsWrapper.current,
            0.4,
            {
              y: '200',
              opacity: 0,
              ease: 'power4.inOut',
            },
            {
              y: '0',
              opacity: 1,
              ease: 'power4.inOut',
            },
            '-=0.4'
          );
          if (circleToggle) {
            tl.fromTo(
              circleSvg.current,
              0.6,
              {
                scale: 0.6,
                opacity: 0,
                ease: 'power4.inOut',
              },
              {
                scale: 1,
                opacity: 1,
                ease: 'power4.inOut',
              },
              '-=0.4'
            );
          }
          tl.add(function () {
            apparitionAnimPlayed.current = true;
          });
        }
      },
    });
  }, []);

  useEffect(() => {
    slideImgWrappersRef.current = [];
    setFilteredSlides(slides.filter((slide) => slide.image && slide.image.filename));
  }, [slides]);

  useEffect(() => {
    if (!filteredSlides[0]) {
      setSlidesImagesWrappersLoaded(false);
    }
  }, [filteredSlides]);

  useEffect(() => {
    if (slidesImagesWrappersLoaded && filteredSlides[0]) {
      const ratios = imgRatios.current;
      ratios.sort(function (a, b) {
        return a - b;
      });
      const heightImg = Math.ceil(slideImgWrappersRef.current[0].clientWidth / ratios[0]);

      gsap.to(imgsWrapper.current, 0, {height: heightImg});
      gsap.to(slideImgWrappersRef.current[0], 0, {position: 'relative'});
      gsap.to(slideImgWrappersRef.current[0], 0.1, {opacity: '1'});
    }
  }, [slidesImagesWrappersLoaded]);

  useEffect(() => {
    if (circleToggle) {
      gsap.to(circleSvg.current, 0, {
        opacity: 1,
      });
    } else {
      gsap.to(circleSvg.current, 0, {
        opacity: 0,
      });
    }
  }, [circleToggle]);

  useEffect(() => {
    if (canvasPoints.length === 0) {
      if (canvasPathPoints.tbody) {
        const pointsTable = canvasPathPoints.tbody;
        let pathPoints = [];
        for (let i = 0; i < pointsTable.length; i++) {
          pathPoints.push({x: pointsTable[i].body[0].value, y: pointsTable[i].body[1].value});
        }
        setCanvasPoints(pathPoints);
      }
    }
  }, [canvasPoints]);

  useEffect(() => {
    if (autoSlide) {
      if (isPause) {
        clearInterval(timer);
      } else {
        const recurrent = setInterval(() => {
          autoSwitchTab();
        }, 2000);
        setTimer(recurrent);
      }
      return () => clearInterval(timer);
    }
  }, [isPause, autoSlide]);

  useEffect(() => {
    if (automaticSlide && !isPause) {
      setAutoSlide(true);
    } else if (automaticSlide && isPause) {
      setPause(false);
    } else if (!automaticSlide && !isPause) {
      setPause(true);
    }
  }, [automaticSlide]);

  return (
    <section
      className={classNames(
        styles.split5050CarouselComponent,
        {[styles.noPaddingTop]: noPaddingTop},
        {[styles.noPaddingBottom]: noPaddingBottom}
      )}
      ref={trigger}
    >
      <div className={classNames('container', styles.contentWrapper)}>
        <div className={classNames('row', styles.row, styles[layout])}>
          <div
            className={classNames(
              'col-12 col-md-6',
              styles.imgCol,
              styles['circlePos' + backgroundCirclePosition]
            )}
          >
            <div
              className={classNames(styles.imgsWrapper, {[styles.shadow]: shadowToggle})}
              ref={imgsWrapper}
            >
              {filteredSlides[0] &&
                filteredSlides.map(({_uid, image}, i) => {
                  const fluidImage = getFluidGatsbyImage(image.filename, {maxWidth: 600});
                  if (fluidImage) {
                    i === 0 ? (imgRatios.current = []) : '';
                    addSlideImgsRatios(fluidImage.aspectRatio);
                    return (
                      <div
                        key={'image' + _uid}
                        className={styles.slideImageWrapper}
                        ref={addSlideImgWrappers}
                      >
                        <Img fluid={fluidImage} />
                      </div>
                    );
                  }
                })}
            </div>

            <div
              className={classNames(styles.roundSvgWrapper, styles[contentColorTheme])}
              ref={circleSvg}
            >
              <CircleSvg />
            </div>
          </div>

          <div className={classNames('col-lg-1', styles.spacer)}></div>

          <div
            className={classNames(
              'col-12 col-md-6 col-lg-5',
              styles.textCol,
              styles[contentColorTheme]
            )}
            ref={textContent}
          >
            <div className={classNames(styles.title, styles[titleSize])}>{render(title)}</div>
            <div className={classNames(styles.subtitle)}>{render(subtitle)}</div>

            <div>
              <ul
                className={classNames(
                  styles.tabsInnerWrapper,
                  styles[carouselType],
                  styles[contentColorTheme]
                )}
                ref={carouselTabWrapper}
              >
                {carouselType === 'timeline' && (
                  <div className={styles.timelineElementWrapper}>
                    <div className={styles.timelineElementInnerWrapper}>
                      <div className={styles.timelineElement} ref={timelineElement}></div>
                    </div>
                  </div>
                )}

                {filteredSlides[0] &&
                  filteredSlides.map(({_uid, title, subtitle}, i) => {
                    return (
                      <li key={'tab' + _uid} ref={addCarouselTabs}>
                        <a
                          href='/'
                          onClick={(e) => handleClickTab(e, i)}
                          className={classNames(styles.carouselTab, {
                            [styles.selected]: currentTabIndex === i,
                          })}
                        >
                          {carouselType === 'timeline' && (
                            <span className={styles.indexTimeline}>{i + 1}</span>
                          )}
                          <span>
                            <span className={styles.contentTabTitle}>{title}</span>
                            <span className={styles.contentTabSubtitle}>{subtitle}</span>
                          </span>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(styles.canvasWrapper)}>
        <BackgroundCanvas pathPoints={canvasPoints} fillStyle='#FFF7F0' />
      </div>
    </section>
  );
};

export default Split5050Carousel;

Split5050Carousel.propTypes = {
  lang: PropTypes.string,
  layout: PropTypes.string,
  componentImage: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contentColorTheme: PropTypes.string,
  titleSize: PropTypes.string,
  carouselType: PropTypes.string,
  slides: PropTypes.array,
  automaticSlide: PropTypes.bool,
  shadowToggle: PropTypes.bool,
  circleToggle: PropTypes.bool,
  backgroundCirclePosition: PropTypes.string,
  canvasPathPoints: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
};
