import PropTypes from 'prop-types';
import React from 'react';
export const TagPaiement234 = ({mode}) => {
  let color = '#FFBB33';
  if (mode === 'selected') {
    color = '#ffffff';
  } else if (mode === 'basicDarkBlue' || mode === 'neutral') {
    color = '#003951';
  }
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='2.08334' y='2.08334' width='11.6667' height='11.6667' rx='5.83333' fill={color} />
      <rect x='15.8333' y='2.08334' width='11.6667' height='11.6667' rx='5.83333' fill={color} />
      <rect x='2.08334' y='15.8333' width='11.6667' height='11.6667' rx='5.83333' fill={color} />
      <rect
        x='16.5833'
        y='16.5833'
        width='10.1667'
        height='10.1667'
        rx='5.08333'
        stroke={color}
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default TagPaiement234;

TagPaiement234.propTypes = {
  mode: PropTypes.string,
};
