// extracted by mini-css-extract-plugin
export var doubleCardComponent = "DoubleCardIcons-module--doubleCardComponent--1yPat";
export var backgroundwhite = "DoubleCardIcons-module--backgroundwhite--13PIY";
export var backgroundbeige = "DoubleCardIcons-module--backgroundbeige---U8ZE";
export var backgroundblue = "DoubleCardIcons-module--backgroundblue--2dA_I";
export var backgrounddarkblue = "DoubleCardIcons-module--backgrounddarkblue--3u3HW";
export var backgroundvertProduit = "DoubleCardIcons-module--backgroundvertProduit--2uhcw";
export var backgroundjauneProduit = "DoubleCardIcons-module--backgroundjauneProduit--1Gcgc";
export var backgroundorangeProduit = "DoubleCardIcons-module--backgroundorangeProduit--SZ-Qz";
export var backgroundrougeDistrib = "DoubleCardIcons-module--backgroundrougeDistrib--1SCdq";
export var noPaddingTop = "DoubleCardIcons-module--noPaddingTop---19XR";
export var noPaddingBottom = "DoubleCardIcons-module--noPaddingBottom--Mq628";
export var doubleCardComponentContainer = "DoubleCardIcons-module--doubleCardComponentContainer--9TiRh";
export var doubleCardComponentRow = "DoubleCardIcons-module--doubleCardComponentRow--3_n7R";
export var canvasWrapper = "DoubleCardIcons-module--canvasWrapper--2aCLU";