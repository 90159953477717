import PropTypes from 'prop-types';
import React from 'react';

export const CircleSvg = ({className}) => {
  return (
    <svg
      viewBox='-3 -3 968 968'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        stroke-alignment='inner'
        d='M0 482.187C0 748.665 215.608 965 481.521 965C747.435 965 963 748.977 963 482.5C963 216.023 747.435 0 481.521 0C215.608 0 0 215.71 0 482.187ZM140.692 482.187C140.692 670.798 293.31 824.01 481.521 824.01C669.733 824.01 822.308 671.111 822.308 482.5C822.308 293.889 669.69 140.678 481.479 140.678C293.267 140.678 140.692 293.577 140.692 482.187Z'
      />
    </svg>
  );
};

CircleSvg.propTypes = {
  className: PropTypes.string,
};

export default CircleSvg;
