import classNames from 'classnames';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import countryList from 'react-select-country-list';

import * as styles from './LanguageSelectOption.module.scss';

const PageLangFlag = ({lang}) => {
  if (!lang) {
    return null;
  }
  let formattedLang = lang;
  if (lang === 'en') {
    formattedLang = 'gb';
  }
  return (
    <div className={styles.flagWrapper}>
      <img src={'/flags/' + formattedLang.toUpperCase() + '.svg'} alt='flag-icon' />
    </div>
  );
};
const PageLangFullName = ({lang}) => {
  if (!lang) {
    return null;
  }
  let formattedLang = lang;
  if (lang === 'en') {
    formattedLang = 'gb';
  }
  const fullname = useMemo(() => countryList().getLabel(formattedLang), []);
  return (
    <div className={styles.countryLangWrapper}>
      <p>{fullname}</p>
    </div>
  );
};
const AvailableLang = ({lang, path, currentlySelected}) => {
  if (!lang || !path) {
    return null;
  }
  const shortenLang = lang.slice(-2);
  let formattedLang = lang;
  if (formattedLang === 'fr') {
    formattedLang = '';
  } else {
    formattedLang = lang + '/';
  }

  let formattedPath = path;
  if (formattedPath === 'home') {
    formattedPath = '';
  }
  return (
    <div className={styles.oneAvailableLang}>
      {currentlySelected.lang !== lang ? (
        <Link className={styles.linkOtherLang} to={`/${formattedLang}${formattedPath}`}>
          {shortenLang}
        </Link>
      ) : (
        <span className={classNames(styles.linkOtherLang, styles.currentLang)}>{shortenLang}</span>
      )}
    </div>
  );
};
const LanguageSelectOption = ({availableLangs, currentlySelected}) => {
  const languages = [];
  for (const [key, value] of Object.entries(availableLangs)) {
    languages.push({lang: key, translations: value});
  }
  return (
    <div className={styles.LanguageSelectOptionComponent}>
      {languages &&
        languages.map(({lang, translations}, i) => {
          return (
            <div key={'oneLangOption' + i} className={styles.oneOption}>
              <PageLangFlag lang={lang} />
              <PageLangFullName lang={lang} />
              <div className={styles.availableLangWrapper}>
                {translations.map(({lang, path}, i) => {
                  return (
                    <AvailableLang
                      key={'availablelangs' + i}
                      lang={lang}
                      path={path}
                      currentlySelected={currentlySelected}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default LanguageSelectOption;

LanguageSelectOption.propTypes = {
  availableLangs: PropTypes.object,
  currentlySelected: PropTypes.object,
};
PageLangFlag.propTypes = {
  lang: PropTypes.string,
};
PageLangFullName.propTypes = {
  lang: PropTypes.string,
};
AvailableLang.propTypes = {
  lang: PropTypes.string,
  path: PropTypes.string,
  fullLang: PropTypes.string,
  currentlySelected: PropTypes.object,
};
