// extracted by mini-css-extract-plugin
export var titleComponent = "Title-module--titleComponent--3WyK6";
export var backgroundwhite = "Title-module--backgroundwhite--2T8jQ";
export var backgroundbeige = "Title-module--backgroundbeige--1oWr_";
export var backgroundblue = "Title-module--backgroundblue--VSfYn";
export var backgrounddarkblue = "Title-module--backgrounddarkblue--3PAkT";
export var backgroundvertProduit = "Title-module--backgroundvertProduit--3ZVRP";
export var backgroundjauneProduit = "Title-module--backgroundjauneProduit--38IjC";
export var backgroundorangeProduit = "Title-module--backgroundorangeProduit--3elL1";
export var backgroundrougeDistrib = "Title-module--backgroundrougeDistrib--3iuIq";
export var noPaddingTop = "Title-module--noPaddingTop--3Ibff";
export var noPaddingBottom = "Title-module--noPaddingBottom--27uBb";
export var rowBodyText = "Title-module--rowBodyText--1FC0U";
export var rowTitle = "Title-module--rowTitle--7MgSn";
export var secondary = "Title-module--secondary--3WoyX";