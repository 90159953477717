import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import * as styles from './SimpleText.module.scss';

const SimpleText = ({textContent, backgroundColor}) => {
  return (
    <div className={classNames(styles.simpleText, styles['background' + backgroundColor])}>
      <div className='container'>
        <div className={classNames(styles.contentRow, 'row')}>
          <div className={classNames(styles.contentCol, 'col-12 col-md-8')}>
            {render(textContent)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleText;

SimpleText.propTypes = {
  textContent: PropTypes.object,
  backgroundColor: PropTypes.string,
};
