import classNames from 'classnames';
import {Link} from 'gatsby';
import {gsap, Power3} from 'gsap';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import {render} from 'storyblok-rich-text-react-renderer';

import LogoAlma from '@/assets/assetsJsx/LogoAlma';
import {getTag} from '@/common/utils';
import Button from '@/components/Button/Button';
import GlobalTag from '@/components/GlobalTag/GlobalTag';
import LanguageSelectorMini from '@/components/LanguageSelectorMini/LanguageSelectorMini';
import LanguageTooltip from '@/components/LanguageTooltip/LanguageTooltip';
import useWindowSize from '@/hooks/UseWindowSize';

import * as styles from './Nav.module.scss';

const NavItem = ({
  tag,
  displayTag,
  itemDesc,
  href: {cached_url, linktype},
  nofollowNoopener,
  targetBlank,
  lengthItems,
}) => {
  if (cached_url && linktype === 'story' && cached_url.startsWith('/')) {
    cached_url = cached_url.substring(1);
  }

  if (cached_url && cached_url.includes('home')) {
    cached_url.replace('home', '');
  }
  const selectedTag = getTag(tag);
  return (
    <>
      {linktype === 'story' && (
        <Link
          to={`/${cached_url}`}
          className={classNames(
            'col-12',
            'col-lg-' + 12 / lengthItems,
            styles['items' + lengthItems],
            styles.itemDropdownOneCol
          )}
        >
          {displayTag && tag && <GlobalTag {...selectedTag} />}
          {render(itemDesc)}
        </Link>
      )}
      {linktype === 'url' &&
        (targetBlank ? (
          <a
            href={cached_url}
            rel={`noreferrer ${nofollowNoopener}`}
            target='_blank'
            className={classNames(
              'col-12',
              'col-lg-' + 12 / lengthItems,
              styles['items' + lengthItems],
              styles.itemDropdownOneCol
            )}
          >
            {displayTag && tag && <GlobalTag {...selectedTag} />}
            {render(itemDesc)}
          </a>
        ) : (
          <a
            href={cached_url}
            rel={`${nofollowNoopener}`}
            className={classNames(
              'col-12',
              'col-lg-' + 12 / lengthItems,
              styles['items' + lengthItems],
              styles.itemDropdownOneCol
            )}
          >
            {displayTag && tag && <GlobalTag {...selectedTag} />}
            {render(itemDesc)}
          </a>
        ))}
    </>
  );
};
const NavLink = ({label, href: {cached_url, linktype}, nofollowNoopener, targetBlank}) => {
  const sandboxUrl = 'https://sandbox.getalma.eu/';
  const [cachedUrl, setCachedUrl] = useState(cached_url);

  useEffect(() => {
    if (
      window &&
      window.location.href.startsWith(sandboxUrl) &&
      cached_url.startsWith('https://dashboard.getalma.eu/')
    ) {
      const replacedUrl = cached_url.replace(
        'https://dashboard.getalma.eu/',
        'https://dashboard.sandbox.getalma.eu/'
      );
      setCachedUrl(replacedUrl);
    } else if (cached_url && linktype === 'story' && cached_url.startsWith('/')) {
      setCachedUrl(cached_url.substring(1));
    }

    if (cached_url && cached_url.includes('home')) {
      setCachedUrl(cached_url.replace('home', ''));
    }
  }, []);

  return (
    <span className={styles.navLinkItem}>
      {linktype === 'story' && (
        <Link to={`/${cachedUrl}`}>
          <span className={styles.itemName}>{label}</span>
        </Link>
      )}
      {linktype === 'url' &&
        (targetBlank ? (
          <>
            <a href={cachedUrl} rel={`noreferrer ${nofollowNoopener}`} target='_blank'>
              <span className={styles.itemName}>{label}</span>
            </a>
          </>
        ) : (
          <>
            <a href={cachedUrl} rel={`${nofollowNoopener}`}>
              <span className={styles.itemName}>{label}</span>
            </a>
          </>
        ))}
    </span>
  );
};
const NavColumn = ({lang, label, items, indexCol, addNavCol, mobileToggleDropdown}) => {
  const windowSize = useWindowSize();
  const [displayDropDown, setdisplayDropdown] = useState(false);

  const hoverInDropdown = () => {
    if (windowSize.width >= 1440) {
      setdisplayDropdown(true);
    }
  };
  const hoverOutDropdown = () => {
    if (windowSize.width >= 1440) {
      setdisplayDropdown(false);
    }
  };
  const toggleDropdown = () => {
    if (windowSize.width < 1440) {
      mobileToggleDropdown(indexCol);
    }
  };

  return (
    <button
      className={classNames(styles.dropdownItem, {
        [styles.displayDropdownItem]: displayDropDown,
      })}
      onMouseEnter={() => hoverInDropdown()}
      onMouseLeave={() => hoverOutDropdown()}
      onClick={() => toggleDropdown()}
      ref={addNavCol}
    >
      <div className={styles.itemInnerWrapper}>
        <span className={styles.itemName}>{label}</span>
        <span className={styles.dropdownIcon}>
          <svg
            width='12'
            height='7'
            viewBox='0 0 12 7'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M5.60156 6.45312C5.8125 6.66406 6.16406 6.66406 6.375 6.45312L10.9453 1.90625C11.1562 1.67188 11.1562 1.32031 10.9453 1.10938L10.4062 0.570312C10.1953 0.359375 9.84375 0.359375 9.60938 0.570312L6 4.17969L2.36719 0.570312C2.13281 0.359375 1.78125 0.359375 1.57031 0.570312L1.03125 1.10938C0.820312 1.32031 0.820312 1.67188 1.03125 1.90625L5.60156 6.45312Z'
              fill='#00425D'
            />
          </svg>
        </span>
      </div>
      <div className={styles.itemDropdownWrapper}>
        <div className={styles.itemDropdownOuterWrapper}>
          <div className={classNames('container', styles.itemDropdownInnerWrapper)}>
            <div className='row'>
              {items[0] &&
                items.map((item) => {
                  const isHidden = item.isHidden;
                  if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
                    return null;
                  } else {
                    return <NavItem key={item._uid} lengthItems={items.length} {...item} />;
                  }
                })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.dropdownTip}></div>
    </button>
  );
};
const Nav = ({columns, lang, cta, pageLangs, hideFooterLang}) => {
  const windowSize = useWindowSize();
  const filteredColumns = (Array.isArray(columns) && columns.filter((i) => !!i)) || [];

  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [logoFilling, setLogoFilling] = useState({paths: '#00425D', rect: '#FF414D'});

  let navRef = useRef();
  let menuItemsRef = useRef();
  let menuInTransition = useRef(false);
  let mainNavInnerWrapper = useRef();
  let ctaCol = useRef();
  let languageRoot = lang === 'default' ? '' : lang + '/';

  const openedDropdown = useRef(null);
  const dropdowns = useRef([]);
  const dropdownWrappers = useRef([]);
  const dropdownOuterWrapper = useRef([]);
  const dropdownTips = useRef([]);

  const addNavCol = (el) => {
    if (el && !dropdowns.current.includes(el)) {
      dropdowns.current.push(el);
      dropdownWrappers.current.push(el.children[1]);
      dropdownTips.current.push(el.children[2]);
      dropdownOuterWrapper.current.push(el.children[1].children[0]);
    }
  };
  const openMenu = () => {
    menuInTransition.current = true;
    let tl = gsap.timeline();
    tl.add(function () {
      setMenuOpened(true);
    })
      .to(menuItemsRef.current, {opacity: 0, y: -20, duration: 0})
      .to(mainNavInnerWrapper.current, {
        opacity: 1,
        height: '100vh',
        duration: 0,
        ease: Power3.easeOut,
      })
      .to(navRef.current, {backgroundColor: '#00425D', duration: 0.05})
      .to(navRef.current, {height: '100vh', duration: 0.2, ease: Power3.easeOut})
      .to(menuItemsRef.current, {display: 'flex', duration: 0})
      .to(ctaCol.current, {display: 'flex', duration: 0})
      .to(menuItemsRef.current, {opacity: 1, y: 0, duration: 0.1, ease: Power3.easeOut})
      .to(ctaCol.current, {opacity: 1, duration: 0.1, ease: Power3.easeOut})

      .add(function () {
        menuInTransition.current = false;
      });
  };
  const closeMenu = () => {
    menuInTransition.current = true;
    let tl = gsap.timeline();
    tl.add(function () {
      setMenuOpened(false);
    });
    tl.to(menuItemsRef.current, {opacity: 0, y: 0, duration: 0.1, ease: Power3.easeOut});
    if (windowSize.width < 992) {
      tl.to(ctaCol.current, {opacity: 0, duration: 0.1, ease: Power3.easeOut});
      tl.to(ctaCol.current, {display: 'none', duration: 0});
    }
    tl.to(menuItemsRef.current, {display: 'none', duration: 0});
    tl.to(mainNavInnerWrapper.current, {
      opacity: 1,
      height: '80px',
      duration: 0.05,
      ease: Power3.easeOut,
    });
    tl.to(navRef.current, {height: '80px', duration: 0.2, ease: Power3.easeOut});
    tl.to(navRef.current, {backgroundColor: 'rgba(255, 255, 255, 0.4)', duration: 0}).add(
      function () {
        menuInTransition.current = false;
      }
    );
  };
  const burgerClick = () => {
    if (menuOpened) {
      if (menuInTransition.current === false) {
        closeMenu();
      }
    } else {
      if (menuInTransition.current === false) {
        openMenu();
      }
    }
  };
  const mobileToggleDropdown = (index) => {
    if (openedDropdown.current !== index && !dropdownOpened) {
      let tl = gsap.timeline();
      tl.to(dropdownWrappers.current[index], 0.02, {visibility: 'visible', ease: Power3.easeOut});
      tl.to(dropdownOuterWrapper.current[index], 0.05, {
        opacity: 1,
        rotateX: 0,
        ease: Power3.easeOut,
      });
      tl.to(dropdownTips.current[index], 0.05, {opacity: 1, ease: Power3.easeOut}, '-=0.02');
      tl.add(function () {
        openedDropdown.current = index;
      });
      tl.add(function () {
        setDropdownOpened(true);
      });
    } else if (openedDropdown.current !== index && dropdownOpened) {
      let tl = gsap.timeline();
      tl.to(dropdownTips.current, 0.02, {opacity: 0, ease: Power3.easeOut});
      tl.to(
        dropdownOuterWrapper.current,
        0.05,
        {opacity: 0, rotateX: -15, ease: Power3.easeOut},
        '-=0.05'
      );
      tl.to(dropdownWrappers.current, 0.05, {visibility: 'hidden', ease: Power3.easeOut});
      tl.to(dropdownWrappers.current[index], 0.05, {visibility: 'visible', ease: Power3.easeOut});
      tl.to(dropdownOuterWrapper.current[index], 0.05, {
        opacity: 1,
        rotateX: 0,
        ease: Power3.easeOut,
      });
      tl.to(dropdownTips.current[index], 0.05, {opacity: 1, ease: Power3.easeOut}, '-=0.05');
      tl.add(function () {
        openedDropdown.current = index;
      });
      tl.add(function () {
        setDropdownOpened(false);
      });
    } else if (openedDropdown.current === index && dropdownOpened) {
      let tl = gsap.timeline();
      tl.to(dropdownTips.current, 0.03, {opacity: 0, ease: Power3.easeOut});
      tl.to(
        dropdownOuterWrapper.current,
        0.05,
        {opacity: 0, rotateX: -15, ease: Power3.easeOut},
        '-=0.05'
      );
      tl.to(dropdownWrappers.current, 0.05, {visibility: 'hidden', ease: Power3.easeOut});
      tl.add(function () {
        openedDropdown.current = null;
      });
      tl.add(function () {
        setDropdownOpened(false);
      });
    } else {
      let tl = gsap.timeline();
      tl.to(dropdownTips.current, 0.03, {opacity: 0, ease: Power3.easeOut});
      tl.to(
        dropdownOuterWrapper.current,
        0.05,
        {opacity: 0, rotateX: 15, ease: Power3.easeOut},
        '-=0.05'
      );
      tl.to(dropdownWrappers.current, 0.05, {visibility: 'hidden', ease: Power3.easeOut});
    }
  };
  const displayHideTooltip = (showTootltip) => {
    if (showTootltip && !displayTooltip) {
      setDisplayTooltip(true);
    }
    if (!showTootltip && displayTooltip) {
      setDisplayTooltip(false);
    }
  };

  useEffect(() => {
    if (menuOpened) {
      setLogoFilling({paths: '#ffffff', rect: '#ffffff'});
    } else {
      setLogoFilling({paths: '#00425D', rect: '#FF414D'});
    }
  }, [menuOpened]);

  return (
    <>
      <Helmet>
        <body className={classNames({['menuOpened']: menuOpened})} />
      </Helmet>

      <nav
        className={classNames(styles.mainNav, {
          [styles.openedMenuBurger]: menuOpened,
        })}
        ref={navRef}
      >
        <div className={classNames(styles.wrapper)}>
          <div className={classNames(styles.innerWrapper)} ref={mainNavInnerWrapper}>
            {/* <div className={classNames(styles.navInnerWrapper)} ref={mainNavInnerWrapper}> */}
            <div className={styles.logoCol}>
              <Link to={`/${languageRoot}`} className={styles.logoLink}>
                <LogoAlma fillPaths={logoFilling.paths} fillRect={logoFilling.rect} />
              </Link>
              {!hideFooterLang && pageLangs && pageLangs.translations && (
                <>
                  <LanguageSelectorMini
                    displayHideTooltip={displayHideTooltip}
                    pageLangs={pageLangs}
                  />
                  <LanguageTooltip
                    displayTooltip={displayTooltip}
                    pageLangs={pageLangs}
                    displayPosition={'bottomLeft'}
                  />
                </>
              )}
            </div>

            <div className={styles.menuitemsCtaWrapper} ref={menuItemsRef}>
              <ul className={styles.menuitemsCol}>
                {filteredColumns.map((column, i) => {
                  const isHidden = column.isHidden;
                  switch (column.component) {
                    case 'nav_column': {
                      if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
                        return null;
                      } else {
                        return (
                          <NavColumn
                            key={column._uid}
                            {...column}
                            indexCol={i}
                            addNavCol={addNavCol}
                            mobileToggleDropdown={mobileToggleDropdown}
                            lang={lang}
                          />
                        );
                      }
                    }
                    case 'nav_link': {
                      if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
                        return null;
                      } else {
                        return <NavLink key={column._uid} {...column} />;
                      }
                    }
                    default:
                      <NavLink key={column._uid} {...column} />;
                  }
                })}
              </ul>
            </div>

            <div className={styles.hamburgerCol}>
              <button onClick={() => burgerClick()} className={classNames(styles.hamburgerButton)}>
                <div></div>
                <div></div>
                <div></div>
              </button>
            </div>

            <div className={styles.ctaCol} ref={ctaCol}>
              {cta && cta[0].button.map((cta) => <Button key={cta._uid} {...cta} />)}
              <p className={styles.mobileMenuDemo}>{`Besoin d'un peu d'infos ?`}</p>
              <a href='/' className={styles.mobileMenuDemo}>
                Demandez une démo.
              </a>
            </div>

            {/* </div> */}
          </div>
        </div>
      </nav>
    </>
  );
};
export default Nav;

Nav.propTypes = {
  lang: PropTypes.string,
  cta: PropTypes.array,
  columns: PropTypes.array,
  pageLangs: PropTypes.object,
  hideFooterLang: PropTypes.bool,
};
NavColumn.propTypes = {
  lang: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array,
  indexCol: PropTypes.number,
  addNavCol: PropTypes.func,
  mobileToggleDropdown: PropTypes.func,
};
NavItem.propTypes = {
  tag: PropTypes.array,
  displayTag: PropTypes.bool,
  itemDesc: PropTypes.object,
  href: PropTypes.object,
  nofollowNoopener: PropTypes.string,
  targetBlank: PropTypes.bool,
  lengthItems: PropTypes.number,
};
NavLink.propTypes = {
  label: PropTypes.string,
  href: PropTypes.object,
  nofollowNoopener: PropTypes.string,
  targetBlank: PropTypes.bool,
};
