// extracted by mini-css-extract-plugin
export var doubleCardComponent = "DoubleCardImages-module--doubleCardComponent--1uCS7";
export var backgroundwhite = "DoubleCardImages-module--backgroundwhite--237xH";
export var backgroundbeige = "DoubleCardImages-module--backgroundbeige--116M5";
export var backgroundblue = "DoubleCardImages-module--backgroundblue--22mmg";
export var backgrounddarkblue = "DoubleCardImages-module--backgrounddarkblue--Y2oq3";
export var backgroundvertProduit = "DoubleCardImages-module--backgroundvertProduit--2xN7s";
export var backgroundjauneProduit = "DoubleCardImages-module--backgroundjauneProduit--1O_wO";
export var backgroundorangeProduit = "DoubleCardImages-module--backgroundorangeProduit--QtYBd";
export var backgroundrougeDistrib = "DoubleCardImages-module--backgroundrougeDistrib--3qDhh";
export var noPaddingTop = "DoubleCardImages-module--noPaddingTop--2JArN";
export var backgroundCircleContainer = "DoubleCardImages-module--backgroundCircleContainer--37Q1P";
export var circleWrapper = "DoubleCardImages-module--circleWrapper--34IGd";
export var circleInnerWrapper = "DoubleCardImages-module--circleInnerWrapper--1C56L";
export var white = "DoubleCardImages-module--white--3ulXm";
export var beige = "DoubleCardImages-module--beige--2vY30";
export var darkBlue = "DoubleCardImages-module--darkBlue--2xoPQ";
export var lightBlue = "DoubleCardImages-module--lightBlue--2VW5T";
export var pressedBlue = "DoubleCardImages-module--pressedBlue--os5aX";
export var blue = "DoubleCardImages-module--blue--3-tHu";
export var vertProduit = "DoubleCardImages-module--vertProduit--3zFoi";
export var jauneProduit = "DoubleCardImages-module--jauneProduit--3oEBq";
export var orangeProduit = "DoubleCardImages-module--orangeProduit--19dSG";
export var doubleCardComponentContainer = "DoubleCardImages-module--doubleCardComponentContainer--VPOlj";
export var doubleCardComponentRow = "DoubleCardImages-module--doubleCardComponentRow--2FNtE";
export var cardImageWrapper = "DoubleCardImages-module--cardImageWrapper--20Up4";
export var canvasWrapper = "DoubleCardImages-module--canvasWrapper--1osUH";