import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import Button from '@/components/Button/Button';

import * as styles from './FaqSection.module.scss';

const FaqSection = ({lang, title, faqItems, CTA, backgroundColor}) => {
  return (
    <section className={classNames(styles.faqSection, styles['background' + backgroundColor])}>
      <div className='container'>
        {title && (
          <div className={classNames('row', styles.rowTitle)}>
            <div className={classNames('col-12 col-md-8 col-lg-6', styles.colTitle)}>
              {render(title)}
            </div>
          </div>
        )}

        {faqItems[0] && (
          <div className={classNames('row', styles.rowFaqItems)}>
            {faqItems.map(({isHidden, question, answer}, i) => {
              if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
                return null;
              } else {
                return (
                  <div
                    key={'faqitem' + i}
                    className={classNames('col-12 col-md-6 col-lg-5', styles.oneFaqItem)}
                  >
                    <div className={styles.oneFaqItemInnerWrapper}>
                      <span className={styles.faqItemQuestion}>{render(question)}</span>
                      <span className={styles.faqItemAnswer}>{render(answer)}</span>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}

        {CTA[0] && CTA[0].button[0] && (
          <div className={classNames('row', styles.rowCTA)}>
            <div className={classNames('col-12 col-md-8', styles.colCTA)}>
              {CTA[0].button.map((cta) => (
                <div key={cta._uid}>
                  <Button {...cta} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default FaqSection;

FaqSection.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.object,
  CTA: PropTypes.array,
  faqItems: PropTypes.array,
  backgroundColor: PropTypes.string,
};
