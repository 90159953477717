import PropTypes from 'prop-types';
import React from 'react';
export const TagFaq = ({mode}) => {
  let color = '#FF414D';
  if (mode === 'selected') {
    color = '#ffffff';
  } else if (mode === 'basicDarkBlue' || mode === 'neutral') {
    color = '#003951';
  }
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23.25 22.026C22.9066 22.4151 21.0302 21.7012 20.625 22.026M23.25 22.026H20.625M23.25 22.026C23.6446 21.579 22.4955 20.0194 22.796 19.5C23.5617 18.1762 24 16.6393 24 15C24 10.0294 19.9706 6 15 6C10.0294 6 6 10.0294 6 15C6 19.9706 10.0294 24 15 24C17.1284 24 19.0842 23.2612 20.625 22.026'
        stroke={color}
        strokeWidth='1.67'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.76 11.9657C11.76 11.9657 11.8119 11.3322 11.9083 11.04C12.3212 9.7881 13.5492 8.88 15 8.88C16.7894 8.88 18.24 10.2615 18.24 11.9657C18.24 13.6699 16.7894 15.0514 15 15.0514V17.52'
        stroke={color}
        strokeWidth='1.67'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='15.54'
        y='21.3'
        width='1.08'
        height='1.08'
        rx='0.54'
        transform='rotate(-180 15.54 21.3)'
        fill={color}
      />
      <rect
        x='15.54'
        y='21.3'
        width='1.08'
        height='1.08'
        rx='0.54'
        transform='rotate(-180 15.54 21.3)'
        stroke={color}
        strokeWidth='1.08'
      />
    </svg>
  );
};

export default TagFaq;

TagFaq.propTypes = {
  mode: PropTypes.string,
};
