import classNames from 'classnames';
import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import CircleSvg from '@/assets/assetsJsx/CircleSvg.js';
import Button from '@/components/Button/Button';

import * as styles from './HeroPricing.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollToPlugin);
}

const HeroPricing = ({heroPricingItems, backgroundColor}) => {
  const section = useRef(null);

  const scrollToTarifs = () => {
    const height = section.current.clientHeight;
    const top = section.current.getBoundingClientRect().top;
    gsap.to(window, {
      duration: 0.2,
      scrollTo: {y: height + top + 50},
      overwrite: true,
    });
  };
  return (
    <section
      className={classNames(styles.heroPricingSection, styles['background' + backgroundColor])}
      ref={section}
    >
      <div className='container'>
        {heroPricingItems[0] && (
          <div className={classNames('row no-gutters', styles.rowHeroPricingItems)}>
            {heroPricingItems.map(
              ({preTitle, title, textContent, listContent, CTA, anchorCtaText, colorTheme}, i) => {
                return (
                  <div
                    key={'heroPricingItem' + i}
                    className={classNames('col-12 col-md-6 col-lg-5', styles.onePricingItem)}
                  >
                    <div
                      className={classNames(styles.pricingItemInnerWrapper, styles[colorTheme], {
                        [styles.even]: i % 2 === 0,
                      })}
                    >
                      {preTitle && preTitle.content[0].content && (
                        <div className={styles.preTitle}>{render(preTitle)}</div>
                      )}
                      {title && title.content[0].content && (
                        <div className={styles.title}>{render(title)}</div>
                      )}
                      {listContent && listContent[0] && listContent[0].listItems && (
                        <ul className={styles.listContent}>
                          {listContent[0].listItems.map(({icon, textContent, _uid}) => {
                            return (
                              <li key={_uid}>
                                <span className={styles.icon}>
                                  <img src={icon.filename} alt={icon.alt} />
                                </span>
                                <span className={styles.textContent}>{textContent}</span>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {textContent && textContent.content[0].content && (
                        <div className={styles.textContent}>{render(textContent)}</div>
                      )}
                      {anchorCtaText && (
                        <div className={styles.anchorCta}>
                          <button onClick={() => scrollToTarifs()}>{anchorCtaText}</button>
                        </div>
                      )}
                      {CTA[0] &&
                        !anchorCtaText &&
                        CTA[0].button.map((cta) => (
                          <div key={cta._uid} className={styles.cta}>
                            <Button {...cta} />
                          </div>
                        ))}
                      <div className={classNames('col-12', styles.roundSvgWrapper)}>
                        <CircleSvg />
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default HeroPricing;

HeroPricing.propTypes = {
  backgroundColor: PropTypes.string,
  heroPricingItems: PropTypes.array,
};
