import classNames from 'classnames';
import Img from 'gatsby-image';
import {getFluidGatsbyImage} from 'gatsby-storyblok-image';
import {gsap} from 'gsap';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import useWindowSize from '@/hooks/UseWindowSize';

import * as styles from './LogoLittle.module.scss';

const OneLogo = ({logo, addLogoWrapper}) => {
  let fluidImage;
  if (logo.filename) {
    fluidImage = getFluidGatsbyImage(logo.filename);
  } else {
    return;
  }

  return (
    <div
      className={`d-block ${styles.oneLogoWrapper}`}
      style={{height: '60px', width: fluidImage.aspectRatio * 60 + 40}}
      ref={(el) => addLogoWrapper(el, fluidImage.aspectRatio)}
    >
      <Img fluid={fluidImage} style={{height: '100%', width: '100%'}} />
    </div>
  );
};

export const LogoLittle = ({title, allLogos, noPaddingTop, noPaddingBottom}) => {
  const windowSize = useWindowSize();

  const [logoWrappersLoaded, setLogoWrappersLoaded] = useState(false);
  const [enoughLogo, setEnoughLogo] = useState(false);
  const [propsInRef, setPropsInRef] = useState(false);
  const [newLogoArray, setNewLogoArray] = useState(0);

  const logos = useRef([]);
  const containerRef = useRef(null);
  const logoWrappers = useRef([]);
  const xPositions = useRef([]);

  const addLogoWrapper = (el) => {
    if (el && !logoWrappers.current.includes(el)) {
      logoWrappers.current.push(el);
    }
    if (logoWrappers.current.length === allLogos.length) {
      setLogoWrappersLoaded(true);
    }
  };

  useEffect(() => {
    if (!propsInRef) {
      logos.current = allLogos;
      setPropsInRef(true);
    } else {
      logos.current = [];
      logoWrappers.current = [];
      setNewLogoArray(newLogoArray + 1);
      setPropsInRef(false);
      setEnoughLogo(false);
      setLogoWrappersLoaded(false);
    }
  }, [allLogos, newLogoArray]);

  useEffect(() => {
    if (logoWrappersLoaded) {
      let xPosLogo = windowSize.width;
      let lengthLogos = 0;
      xPositions.current = [];
      logoWrappers.current.forEach((logo) => {
        xPosLogo = xPosLogo - logo.clientWidth;
        lengthLogos = lengthLogos + logo.clientWidth;
        xPositions.current.push(xPosLogo);
        gsap.set(logo, {x: () => xPosLogo, overwrite: 'auto'});
      });

      if (!enoughLogo) {
        if (xPosLogo > windowSize.width) {
          setEnoughLogo(true);
        } else {
          const spaceFilled = lengthLogos;
          const spaceToFill = windowSize.width - lengthLogos;
          const loopAmount = (1 + Math.ceil(spaceToFill / spaceFilled)) * 2;
          const oldLogoArray = logos.current;
          let newLogoArray = [];
          for (let i = 0; i < loopAmount; i++) {
            newLogoArray = newLogoArray.concat(oldLogoArray);
          }
          logos.current = newLogoArray;
          logoWrappers.current = [];
          setLogoWrappersLoaded(false);
          setEnoughLogo(true);
        }
      } else if (enoughLogo) {
        const threshold = logos.current.length / 2 - 1;
        const halfWay = xPositions.current[threshold] * -1 + windowSize.width;
        logoWrappers.current.forEach((logo) => {
          gsap.to(logo, 24, {x: `+=${halfWay}`, ease: 'none', repeat: -1});
        });
      }
    }
  }, [logoWrappersLoaded, newLogoArray]);

  return (
    <section
      className={classNames(
        styles.titleComponent,
        {[styles.noPaddingTop]: noPaddingTop},
        {[styles.noPaddingBottom]: noPaddingBottom}
      )}
    >
      <div className='container'>
        {title && (
          <div className={classNames('row', styles.rowTitle)}>
            <div className='col-12 col-md-8 col-lg-6'>
              <h3>{render(title)}</h3>
            </div>
          </div>
        )}
      </div>

      <div className={`d-flex flex-row ${styles.logosWrapper}`} ref={containerRef}>
        {propsInRef &&
          logos.current &&
          logos.current[0] &&
          logos.current.map((logo, i) => {
            return <OneLogo key={i} logo={logo} addLogoWrapper={addLogoWrapper} />;
          })}
      </div>
    </section>
  );
};

export default LogoLittle;

LogoLittle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  allLogos: PropTypes.array,
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
};

OneLogo.propTypes = {
  logo: PropTypes.object,
  addLogoWrapper: PropTypes.func,
  addLogo: PropTypes.func,
};
