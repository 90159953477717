import PropTypes from 'prop-types';
import React from 'react';

import RichText from '@/components/RichText/RichText';

import * as styles from './CGUFormLabel.module.scss';

const CGUFormLabel = ({content}) => {
  return (
    <div className={'container'}>
      <div className={'row'}>
        <div className={'col-12 col-md-6 offset-md-1'}>
          <div className={styles.tosWrapper}>
            <span>
              <RichText content={content} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CGUFormLabel;

CGUFormLabel.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
