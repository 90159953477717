// extracted by mini-css-extract-plugin
export var faqSection = "FaqSection-module--faqSection--1D_i8";
export var backgroundwhite = "FaqSection-module--backgroundwhite--1E6iK";
export var backgroundbeige = "FaqSection-module--backgroundbeige--Wrlbi";
export var backgroundblue = "FaqSection-module--backgroundblue--3x5pK";
export var backgrounddarkblue = "FaqSection-module--backgrounddarkblue--1ND1v";
export var backgroundvertProduit = "FaqSection-module--backgroundvertProduit--3oKBr";
export var backgroundjauneProduit = "FaqSection-module--backgroundjauneProduit--EaxU1";
export var backgroundorangeProduit = "FaqSection-module--backgroundorangeProduit--2-3UC";
export var backgroundrougeDistrib = "FaqSection-module--backgroundrougeDistrib--1Ldn1";
export var rowTitle = "FaqSection-module--rowTitle--h2rB9";
export var colTitle = "FaqSection-module--colTitle--2dysn";
export var rowFaqItems = "FaqSection-module--rowFaqItems--uVctC";
export var oneFaqItem = "FaqSection-module--oneFaqItem--1Xcbs";
export var oneFaqItemInnerWrapper = "FaqSection-module--oneFaqItemInnerWrapper--3Az5g";
export var faqItemQuestion = "FaqSection-module--faqItemQuestion--3f8af";
export var faqItemAnswer = "FaqSection-module--faqItemAnswer--3-rGt";
export var rowCTA = "FaqSection-module--rowCTA--bscAz";
export var colCTA = "FaqSection-module--colCTA--2IK6q";