import classNames from 'classnames';
import {gsap, TweenLite} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import Button from '@/components/Button/Button';
import ButtonAndLink from '@/components/ButtonAndLink/ButtonAndLink';
import useWindowSize from '@/hooks/UseWindowSize';

import * as styles from './PrincingPlansSection.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollToPlugin);
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

const PricingPlanIcon = ({pricingPlanIcon, pricingPlanColor, activeIcon = false}) => {
  let pricingPlanColorHex = '#ffffff';
  if (!activeIcon) {
    switch (pricingPlanColor) {
      case 'blue':
        pricingPlanColorHex = '#00425D';
        break;
      case 'vertProduit':
        pricingPlanColorHex = '#1DCDA3';
        break;
      case 'rougeDistrib':
        pricingPlanColorHex = '#FF414D';
        break;
      case 'jauneProduit':
        pricingPlanColorHex = '#FB3';
        break;
      case 'orangeProduit':
        pricingPlanColorHex = '#FF7E55';
        break;
      case 'lightBlue':
        pricingPlanColorHex = '#588FE1';
        break;
      default:
        pricingPlanColorHex = '#00425D';
    }
  }
  switch (pricingPlanIcon) {
    case 'payLater':
      return (
        <svg
          width='30'
          height='30'
          viewBox='0 0 30 30'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            y='-1.06066'
            width='8.23748'
            height='8.23748'
            rx='4.11874'
            transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 6.13525 28.1893)'
            stroke={pricingPlanColorHex}
            strokeWidth='1.5'
          />
          <rect
            y='-1.06066'
            width='8.23748'
            height='8.23748'
            rx='4.11874'
            transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 14.25 20.0751)'
            stroke={pricingPlanColorHex}
            strokeWidth='1.5'
          />
          <rect
            width='9.73748'
            height='9.73748'
            rx='4.86874'
            transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 23.1147 13.7705)'
            fill={pricingPlanColorHex}
          />
        </svg>
      );
    case 'paiement234':
      return (
        <svg
          width='30'
          height='30'
          viewBox='0 0 30 30'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='2.08334'
            y='2.08334'
            width='11.6667'
            height='11.6667'
            rx='5.83333'
            fill={pricingPlanColorHex}
          />
          <rect
            x='15.8333'
            y='2.08334'
            width='11.6667'
            height='11.6667'
            rx='5.83333'
            fill={pricingPlanColorHex}
          />
          <rect
            x='2.08334'
            y='15.8333'
            width='11.6667'
            height='11.6667'
            rx='5.83333'
            fill={pricingPlanColorHex}
          />
          <rect
            x='16.5833'
            y='16.5833'
            width='10.1667'
            height='10.1667'
            rx='5.08333'
            stroke={pricingPlanColorHex}
            strokeWidth='1.5'
          />
        </svg>
      );
    case 'paiement612':
      return (
        <svg
          width='33'
          height='30'
          viewBox='0 0 33 30'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            width='7.30435'
            height='7.30435'
            rx='3.65217'
            transform='matrix(0 -1 -1 0 7.30435 28)'
            fill={pricingPlanColorHex}
          />
          <rect
            width='7.30435'
            height='7.30435'
            rx='3.65217'
            transform='matrix(0 -1 -1 0 7.30435 9.3913)'
            fill={pricingPlanColorHex}
          />
          <rect
            width='7.30435'
            height='7.30435'
            rx='3.65217'
            transform='matrix(0 -1 -1 0 7.30435 18.6956)'
            fill={pricingPlanColorHex}
          />
          <rect
            width='7.30435'
            height='7.30435'
            rx='3.65217'
            transform='matrix(0 -1 -1 0 15.6087 28)'
            fill={pricingPlanColorHex}
          />
          <rect
            x='-0.5'
            y='-0.5'
            width='6.30435'
            height='6.30435'
            rx='3.15217'
            transform='matrix(0 -1 -1 0 14.6087 8.3913)'
            fill={pricingPlanColorHex}
          />
          <rect
            x='-0.5'
            y='-0.5'
            width='6.30435'
            height='6.30435'
            rx='3.15217'
            transform='matrix(0 -1 -1 0 14.6087 8.3913)'
            stroke={pricingPlanColorHex}
          />
          <rect
            width='7.30435'
            height='7.30435'
            rx='3.65217'
            transform='matrix(0 -1 -1 0 15.6087 18.6956)'
            fill={pricingPlanColorHex}
          />
          <rect
            x='-0.5'
            y='-0.5'
            width='6.30435'
            height='6.30435'
            rx='3.15217'
            transform='matrix(0 -1 -1 0 22.9131 27)'
            fill={pricingPlanColorHex}
          />
          <rect
            x='-0.5'
            y='-0.5'
            width='6.30435'
            height='6.30435'
            rx='3.15217'
            transform='matrix(0 -1 -1 0 22.9131 27)'
            stroke={pricingPlanColorHex}
          />
          <rect
            x='-0.5'
            y='-0.5'
            width='6.30435'
            height='6.30435'
            rx='3.15217'
            transform='matrix(0 -1 -1 0 22.9131 8.3913)'
            fill={pricingPlanColorHex}
          />
          <rect
            x='-0.5'
            y='-0.5'
            width='6.30435'
            height='6.30435'
            rx='3.15217'
            transform='matrix(0 -1 -1 0 22.9131 8.3913)'
            stroke={pricingPlanColorHex}
          />
          <rect
            x='-0.5'
            y='-0.5'
            width='6.30435'
            height='6.30435'
            rx='3.15217'
            transform='matrix(0 -1 -1 0 22.9131 17.6956)'
            fill={pricingPlanColorHex}
          />
          <rect
            x='-0.5'
            y='-0.5'
            width='6.30435'
            height='6.30435'
            rx='3.15217'
            transform='matrix(0 -1 -1 0 22.9131 17.6956)'
            stroke={pricingPlanColorHex}
          />
          <rect
            x='-0.5'
            y='-0.5'
            width='6.30435'
            height='6.30435'
            rx='3.15217'
            transform='matrix(0 -1 -1 0 31.2174 27)'
            fill={pricingPlanColorHex}
          />
          <rect
            x='-0.5'
            y='-0.5'
            width='6.30435'
            height='6.30435'
            rx='3.15217'
            transform='matrix(0 -1 -1 0 31.2174 27)'
            stroke={pricingPlanColorHex}
          />
          <rect
            x='-0.5'
            y='-0.5'
            width='6.30435'
            height='6.30435'
            rx='3.15217'
            transform='matrix(0 -1 -1 0 31.2174 8.3913)'
            stroke={pricingPlanColorHex}
          />
          <rect
            x='-0.5'
            y='-0.5'
            width='6.30435'
            height='6.30435'
            rx='3.15217'
            transform='matrix(0 -1 -1 0 31.2174 17.6956)'
            stroke={pricingPlanColorHex}
          />
        </svg>
      );
    case 'paiementComptant':
      return (
        <svg
          width='30'
          height='30'
          viewBox='0 0 30 30'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='2.2193'
            y='-2.2193'
            width='15.5614'
            height='15.5614'
            rx='7.7807'
            transform='matrix(1 0 0 -1 5 20.5614)'
            fill={pricingPlanColorHex}
          />
          <rect
            x='2.2193'
            y='-2.2193'
            width='15.5614'
            height='15.5614'
            rx='7.7807'
            transform='matrix(1 0 0 -1 5 20.5614)'
            stroke={pricingPlanColorHex}
            strokeWidth='4.4386'
          />
        </svg>
      );
    default:
      return (
        <svg
          width='30'
          height='30'
          viewBox='0 0 30 30'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='2.08334'
            y='2.08334'
            width='11.6667'
            height='11.6667'
            rx='5.83333'
            fill='#FFBB33'
          />
          <rect
            x='15.8333'
            y='2.08334'
            width='11.6667'
            height='11.6667'
            rx='5.83333'
            fill='#FFBB33'
          />
          <rect
            x='2.08334'
            y='15.8333'
            width='11.6667'
            height='11.6667'
            rx='5.83333'
            fill='#FFBB33'
          />
          <rect
            x='16.5833'
            y='16.5833'
            width='10.1667'
            height='10.1667'
            rx='5.08333'
            stroke='#FFBB33'
            strokeWidth='1.5'
          />
        </svg>
      );
  }
};
const PrincingPlansSection = ({lang, pricingPlans, buttonAndLink, backgroundColor}) => {
  const windowSize = useWindowSize();
  const section = useRef(null);
  const planSelectorWrapper = useRef(null);
  const planSelectorCol = useRef(null);
  const pricingPlansDetails = useRef([]);

  const [selectorsLoaded, setSelectorsLoaded] = useState(false);
  const [hightlightedSectionIndex, setHighlightedSectionIndex] = useState(0);

  let filteredPricingPlans = pricingPlans.filter((pricingPlan) => {
    if (pricingPlan.isHidden && !pricingPlan.isHidden.includes(lang)) {
      return pricingPlan;
    } else if (!pricingPlan.isHidden) {
      return pricingPlan;
    }
  });

  const addpricingPlansDetails = (el) => {
    if (el && !pricingPlansDetails.current.includes(el)) {
      pricingPlansDetails.current.push(el);
      if (pricingPlans.length === pricingPlansDetails.current.length) {
        setSelectorsLoaded(true);
      }
    }
  };
  const scrollToSection = (i) => {
    const selectedPricingPlanDetail = pricingPlansDetails.current[i];
    TweenLite.to(window, {
      duration: 0.2,
      scrollTo: {y: selectedPricingPlanDetail, offsetY: 100},
      overwrite: true,
    });
  };
  const enterSection = () => {
    for (let i = 0; i < pricingPlansDetails.current.length; i++) {
      if (pricingPlansDetails.current[i].classList.contains('highlighted')) {
        setHighlightedSectionIndex(i);
      }
    }
  };
  const enterBackSection = () => {
    for (let i = 0; i < pricingPlansDetails.current.length; i++) {
      if (pricingPlansDetails.current[i].classList.contains('highlighted') && i !== 4) {
        setHighlightedSectionIndex(i);
      }
    }
  };

  useEffect(() => {
    if (windowSize.width) {
      const heightPlanSelector = planSelectorWrapper.current.clientHeight + 100;
      if (windowSize.width >= 992) {
        ScrollTrigger.create({
          trigger: section.current,
          start: 'top top+=100',
          end: 'bottom top+=' + heightPlanSelector,
          pin: planSelectorWrapper.current,
          // markers: true,
        });
      } else {
        ScrollTrigger.create({
          trigger: section.current,
          start: 'top top',
          end: 'bottom bottom',
          pin: planSelectorCol.current,
          pinSpacing: false,
          onUpdate: (self) => {
            gsap.to(planSelectorWrapper.current, {
              duration: 0,
              scrollTo: {x: self.progress * 500},
              overwrite: true,
            });
          },
          // markers: true,
        });
      }
    }
  }, [windowSize]);

  useEffect(() => {
    if (selectorsLoaded) {
      for (let i = 0; i < pricingPlansDetails.current.length; i++) {
        let el = pricingPlansDetails.current[i];
        ScrollTrigger.create({
          trigger: el,
          start: 'top center-=150',
          end: 'bottom+=32 center-=150',
          toggleActions: 'play reverse play reverse',
          toggleClass: 'highlighted',
          onEnter: enterSection,
          onEnterBack: enterBackSection,
          // markers: true,
        });
      }
    }
  }, [selectorsLoaded]);

  return (
    <section
      className={classNames(styles.pricingPlansSection, styles['background' + backgroundColor])}
    >
      <div className='container' ref={section}>
        <div className={classNames('row', styles.rowPricingPlans)}>
          <div
            className={classNames('col-12 col-md-6 col-lg-4', styles.planSelectorCol)}
            ref={planSelectorCol}
          >
            <div className={styles.planSelectorWrapper} ref={planSelectorWrapper}>
              {filteredPricingPlans.map(
                ({isHidden, pricingPlanName, pricingPlanColor, pricingPlanIcon}, i) => {
                  if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
                    return null;
                  } else {
                    return (
                      <button
                        key={'onePricingPlanName' + i}
                        className={classNames(
                          styles.onePricingPlanName,
                          styles['pricingPlanColor' + pricingPlanColor],
                          {[styles.highlighted]: hightlightedSectionIndex === i}
                        )}
                        onClick={() => scrollToSection(i)}
                      >
                        <span className={styles.icon}>
                          <PricingPlanIcon
                            {...{pricingPlanIcon, pricingPlanColor}}
                            activeIcon={hightlightedSectionIndex === i ? true : false}
                          />
                        </span>
                        <span className={styles.name}>{render(pricingPlanName)}</span>
                      </button>
                    );
                  }
                }
              )}

              <div className={styles.planSelectorButtonLink}>
                {buttonAndLink[0] &&
                  buttonAndLink.map((item) => <ButtonAndLink key={item._uid} {...item} />)}
              </div>
            </div>
          </div>
          <div className={classNames('col-12 col-md-6 col-lg-6', styles.planDetailCol)}>
            {filteredPricingPlans.map(({_uid, pricingPlanColor, pricingPlanDetails, CTA}) => {
              return (
                <div
                  key={_uid}
                  className={classNames(
                    styles.onePricingPlanDetail,
                    styles['pricingPlanColor' + pricingPlanColor]
                  )}
                  ref={addpricingPlansDetails}
                >
                  {pricingPlanDetails.map(
                    ({_uid, isHidden, pricingPlanDetailItemName, pricingPlanDetailItemRows}) => {
                      if (lang && isHidden && isHidden.length > 0 && isHidden.includes(lang)) {
                        return null;
                      } else {
                        return (
                          <div key={_uid} className={styles.onePricingPlanDetailItem}>
                            <span className={styles.onePricingPlanDetailTag}>
                              {pricingPlanDetailItemName}
                            </span>
                            {pricingPlanDetailItemRows.map(
                              ({_uid, isHidden, content, keyfigure, subKeyFigureContent}) => {
                                if (
                                  lang &&
                                  isHidden &&
                                  isHidden.length > 0 &&
                                  isHidden.includes(lang)
                                ) {
                                  return null;
                                } else {
                                  return (
                                    <div key={_uid} className={styles.onePricingPlanDetailItemRow}>
                                      <div className={styles.contentCol}>{render(content)}</div>
                                      <div className={styles.keyFigureCol}>
                                        {keyfigure && (
                                          <span className={styles.keyFigure}>{keyfigure}</span>
                                        )}
                                        {subKeyFigureContent && (
                                          <span className={styles.subKeyFigureContent}>
                                            {render(subKeyFigureContent)}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            )}
                          </div>
                        );
                      }
                    }
                  )}

                  {CTA[0] &&
                    CTA[0].button.map((cta) => (
                      <div key={cta._uid}>
                        <Button {...cta} />
                      </div>
                    ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
export default PrincingPlansSection;

PrincingPlansSection.propTypes = {
  lang: PropTypes.string,
  pricingPlans: PropTypes.array,
  buttonAndLink: PropTypes.array,
  backgroundColor: PropTypes.string,
};

PricingPlanIcon.propTypes = {
  pricingPlanIcon: PropTypes.string,
  pricingPlanColor: PropTypes.string,
  activeIcon: PropTypes.bool,
};
