import classNames from 'classnames';
import {gsap, TimelineLite, TweenMax} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import * as styles from './Title.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

export const Title = ({mode, title, content, noPaddingTop, noPaddingBottom, backgroundColor}) => {
  const section = useRef(null);
  const textContainer = useRef(null);
  const apparitionAnimPlayed = useRef(false);

  useEffect(() => {
    TweenMax.to(textContainer.current, 0, {y: '+=20', opacity: 0});

    ScrollTrigger.create({
      trigger: section.current,
      start: 'top bottom-=300',
      // markers: true,
      onEnter: function () {
        if (!apparitionAnimPlayed.current) {
          let tl = new TimelineLite();
          tl.to(textContainer.current, 0.4, {y: '0', opacity: 1, ease: 'power4.inOut'});
          tl.add(function () {
            apparitionAnimPlayed.current = true;
          });
        }
      },
    });
  }, []);

  return (
    <section
      className={classNames(
        styles.titleComponent,
        {[styles.noPaddingTop]: noPaddingTop},
        {[styles.noPaddingBottom]: noPaddingBottom},
        styles['background' + backgroundColor]
      )}
      ref={section}
    >
      <div className='container' ref={textContainer}>
        <div className={classNames('row', styles.rowTitle, styles[mode])}>
          <div className='col-12 col-md-8 col-lg-6'>{render(title)}</div>
        </div>

        {content && (
          <div className={classNames('row', styles.rowBodyText)}>
            <div className='col-12 col-md-8 col-lg-6'>{render(content)}</div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Title;

Title.propTypes = {
  mode: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  backgroundColor: PropTypes.string,
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
};

Title.defaultProps = {
  mode: 'primary',
  title: '',
  backgroundColor: 'white',
};
