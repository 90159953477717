import PropTypes from 'prop-types';
import React from 'react';

export const HalfCircleSvg = ({className}) => {
  return (
    <svg
      width='307'
      height='614'
      viewBox='0 0 307 614'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M216.956 307C216.956 427.007 119.833 524.292 0.0270081 524.292V614C169.295 614 306.514 476.551 306.514 307C306.514 137.449 169.295 0 0.0270081 0V89.5089C119.821 89.5236 216.956 187.002 216.956 307Z'
        fill='#FF414D'
      />
    </svg>
  );
};

HalfCircleSvg.propTypes = {
  className: PropTypes.string,
};

export default HalfCircleSvg;
