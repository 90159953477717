import React from 'react';
import {Helmet} from 'react-helmet';

const HomeMeta = () => {
  return (
    <>
      <Helmet>
        <script src='https://tool.valorimetre.com/script.js' />
        {/* <script
          type='text/javascript'
          innerHTML={`
                function gtag(){dataLayer.push(arguments);}
                gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'send_to': 'DC-10774674/invmedia/alma-0+standard'
                });
            `}
        ></script> */}
        <noscript
          innerHTML={`<img src="https://ad.doubleclick.net/ddm/activity/src=10774674;type=invmedia;cat=alma-0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>`}
        ></noscript>
      </Helmet>
    </>
  );
};

export default HomeMeta;
