// extracted by mini-css-extract-plugin
export var formWrapper = "Form-module--formWrapper--2SMF9";
export var inputTxtWrapper = "Form-module--inputTxtWrapper--xnuy7";
export var label = "Form-module--label--h3y8v";
export var telephoneWrapper = "Form-module--telephoneWrapper--zM1pY";
export var input = "Form-module--input--3W4Z9";
export var dropdown = "Form-module--dropdown--V74bt";
export var inputSelectWrapper = "Form-module--inputSelectWrapper--3qZEV";
export var tosWrapper = "Form-module--tosWrapper--3DaeS";
export var inputCheckboxWrapper = "Form-module--inputCheckboxWrapper--2qTcy";
export var check = "Form-module--check--2eVG-";
export var checkboxesInnerWrapper = "Form-module--checkboxesInnerWrapper--1W7CJ";
export var oneCheckboxWrapper = "Form-module--oneCheckboxWrapper--263cT";
export var error = "Form-module--error--YgVUX";
export var errorMessage = "Form-module--errorMessage--3k8Ts";