import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const LogoAlma = ({cssClasses, fillPaths, fillRect}) => {
  return (
    <svg
      width='86'
      height='47'
      viewBox='0 0 86 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames(cssClasses)}
    >
      <path
        d='M13.7286 20.0247V19.8022H10.5895C9.1123 19.8022 8.0167 19.9506 7.30272 20.2472C6.61335 20.5192 6.26867 21.1249 6.26867 22.0643C6.26867 23.4735 7.25348 24.178 9.22309 24.178C10.8726 24.178 12.0298 23.8319 12.6945 23.1397C13.3839 22.4228 13.7286 21.3844 13.7286 20.0247ZM8.00439 28.2571C5.6901 28.2571 3.979 27.6885 2.87109 26.5513C1.76318 25.3894 1.20923 23.8937 1.20923 22.0643C1.20923 19.8641 1.92321 18.2819 3.35118 17.3177C4.77915 16.3288 6.95804 15.8344 9.88784 15.8344H13.7286V15.0186C13.7286 13.1891 12.5714 12.2744 10.2571 12.2744C8.18905 12.2744 7.04421 13.0655 6.82263 14.6477H1.65239C1.82473 12.7441 2.61258 11.1496 4.01593 9.86404C5.41928 8.5785 7.53661 7.93573 10.3679 7.93573C13.1746 7.93573 15.292 8.56614 16.7199 9.82696C18.1479 11.0878 18.8619 12.8678 18.8619 15.1669V27.8121H14.2087V25.6613C13.1008 27.3919 11.0327 28.2571 8.00439 28.2571Z'
        fill={fillPaths}
      />
      <path
        d='M30.649 23.288V27.7751C30.132 27.8492 29.5903 27.8863 29.0241 27.8863C26.9806 27.8863 25.528 27.4537 24.6663 26.5884C23.8046 25.6984 23.3737 24.2769 23.3737 22.3239V0H28.6178V21.9531C28.6178 22.4228 28.704 22.7689 28.8763 22.9914C29.0487 23.2139 29.4057 23.3251 29.9473 23.3251C30.0704 23.3251 30.2058 23.3251 30.3535 23.3251C30.5259 23.3004 30.6244 23.288 30.649 23.288Z'
        fill={fillPaths}
      />
      <path
        d='M51.3726 16.7615V27.8121H46.1285V16.2794C46.1285 13.733 45.0945 12.4598 43.0264 12.4598C41.82 12.4598 40.8844 12.8307 40.2197 13.5723C39.5796 14.314 39.2595 15.3399 39.2595 16.6502V27.8121H34.0154V8.38073H38.8533V10.754C39.8627 8.87517 41.7215 7.93573 44.4297 7.93573C46.9902 7.93573 48.8614 8.85044 50.0431 10.6799C51.6927 8.85044 53.8346 7.93573 56.469 7.93573C58.7341 7.93573 60.4698 8.64031 61.6762 10.0495C62.8825 11.4586 63.4857 13.3375 63.4857 15.6861V27.8121H58.2416V16.2794C58.2416 13.733 57.2076 12.4598 55.1395 12.4598C53.9085 12.4598 52.9729 12.843 52.3328 13.6094C51.6927 14.3511 51.3726 15.4017 51.3726 16.7615Z'
        fill={fillPaths}
      />
      <path
        d='M79.6188 20.0247V19.8022H76.4797C75.0025 19.8022 73.9069 19.9506 73.1929 20.2472C72.5035 20.5192 72.1589 21.1249 72.1589 22.0643C72.1589 23.4735 73.1437 24.178 75.1133 24.178C76.7628 24.178 77.92 23.8319 78.5847 23.1397C79.2741 22.4228 79.6188 21.3844 79.6188 20.0247ZM73.8946 28.2571C71.5803 28.2571 69.8692 27.6885 68.7613 26.5513C67.6534 25.3894 67.0994 23.8937 67.0994 22.0643C67.0994 19.8641 67.8134 18.2819 69.2414 17.3177C70.6693 16.3288 72.8482 15.8344 75.778 15.8344H79.6188V15.0186C79.6188 13.1891 78.4616 12.2744 76.1473 12.2744C74.0792 12.2744 72.9344 13.0655 72.7128 14.6477H67.5426C67.7149 12.7441 68.5028 11.1496 69.9061 9.86404C71.3095 8.5785 73.4268 7.93573 76.2581 7.93573C79.0648 7.93573 81.1822 8.56614 82.6101 9.82696C84.0381 11.0878 84.7521 12.8678 84.7521 15.1669V27.8121H80.0989V25.6613C78.991 27.3919 76.9229 28.2571 73.8946 28.2571Z'
        fill={fillPaths}
      />
      <rect y='40.5428' width='86' height='6.14286' fill={fillRect} />
    </svg>
  );
};

export default LogoAlma;

LogoAlma.propTypes = {
  cssClasses: PropTypes.string,
  fillPaths: PropTypes.string,
  fillRect: PropTypes.string,
};

LogoAlma.defaultProps = {
  cssClasses: '',
  fillPaths: '#00425D',
  fillRect: '#FF414D',
};
