import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import confirmIcon from '@/assets/components/form/confirmContact.svg';
import Button from '@/components/Button/Button';

import * as styles from './ValidSentForm.module.scss';

const ValidSentForm = ({validSentForm, title, subtitle, CTA}) => {
  return (
    <div className={styles.confirmForm} ref={validSentForm}>
      <div className={classNames('row')}>
        <div className={classNames('col-12', styles.confirmIconCol)}>
          <img src={confirmIcon} alt='confirmation icon' />
        </div>
        <div className={classNames('col-12', styles.confirmTextCol)}>
          {render(title)}
          {render(subtitle)}
        </div>
        <div className={classNames('col-12', styles.confirmButtonCol)}>
          {CTA &&
            CTA.map((cta) => (
              <div key={cta._uid}>
                <Button {...cta} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ValidSentForm;

ValidSentForm.propTypes = {
  validSentForm: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  CTA: PropTypes.array,
};
