import PropTypes from 'prop-types';
import React from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

const RichText = ({content}) => {
  return <>{render(content)}</>;
};

export default RichText;

RichText.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
