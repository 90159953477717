import PropTypes from 'prop-types';
import React from 'react';
export const TagEnligne = ({mode}) => {
  let color = '#FF414D';
  if (mode === 'selected') {
    color = '#ffffff';
  } else if (mode === 'basicDarkBlue' || mode === 'neutral') {
    color = '#003951';
  }
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='5.75'
        y='3.75'
        width='10.5'
        height='10.5'
        rx='5.25'
        stroke={color}
        strokeWidth='1.5'
      />
      <rect
        x='0.75'
        y='-0.75'
        width='6.5'
        height='6.5'
        rx='3.25'
        transform='matrix(1 0 0 -1 3 25.5)'
        fill={color}
      />
      <rect
        x='0.75'
        y='-0.75'
        width='6.5'
        height='6.5'
        rx='3.25'
        transform='matrix(1 0 0 -1 3 25.5)'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M10 8L27 15.0684L20.4277 18.4277L17.0684 25L10 8Z'
        stroke={color}
        strokeWidth='1.67'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TagEnligne;

TagEnligne.propTypes = {
  mode: PropTypes.string,
};
