import classNames from 'classnames';
import {gsap, TimelineLite} from 'gsap';
import {DrawSVGPlugin} from 'gsap/DrawSVGPlugin';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import Button from '@/components/Button/Button';
import useObserver from '@/hooks/useObserver';

import * as styles from './KeyFigures.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(DrawSVGPlugin);
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

const PatternCol = ({direction, backgroundColor}) => {
  const patternUnits = useRef([]);
  const patternCol = useRef(null);
  const [patternUnit, setPatternUnit] = useState([]);
  const [patternsLoaded, setPatternsLoaded] = useState(false);

  const resizeMain = () => {
    setPatternsLoaded(false);
  };
  useObserver({callback: resizeMain, element: patternCol});

  const addPatternUnit = (el) => {
    if (el && !patternUnits.current.includes(el)) {
      patternUnits.current.push(el);
      if (patternUnit.length === patternUnits.current.length) {
        // gsap.to(patternUnits.current, 0, {drawSVG: '0'});
        // gsap.to(patternUnits.current, 0, {r: '0', opacity: 0});
        gsap.to(patternUnits.current, 0, {r: '30', opacity: 0});
        setPatternsLoaded(true);
      }
    }
  };

  useEffect(() => {
    if (!patternsLoaded) {
      const widthCol = patternCol.current.clientWidth;
      const heightCol = patternCol.current.clientHeight;
      let widthUnit = Math.floor(heightCol / Math.floor(heightCol / 60));
      const radius = Math.floor(widthUnit / 2 - 8);
      const strokeWidth = 8;

      const repeatW = widthCol / widthUnit;
      const repeatH = heightCol / widthUnit;
      const patternUnits = [];

      for (let y = repeatH; y > 0; y--) {
        // const ypos = widthUnit * y - (radius + strokeWidth) - (repeatH - y) * 20;
        const ypos = widthUnit * y - (radius + strokeWidth);
        const xDecalage = (repeatH - y) * 20;

        if (direction === 'toLeft') {
          for (let x = repeatW; x > 0; x--) {
            const xpos = widthUnit * x - (radius + strokeWidth) - (repeatW - x) * 20 - xDecalage;
            if (xpos >= -widthUnit) {
              patternUnits.push({x: xpos, y: ypos, r: radius});
            }
          }
        } else {
          for (let x = 0; x < repeatW; x++) {
            const xpos = widthUnit * x + (radius + strokeWidth) + x * 20 + xDecalage;
            if (xpos <= widthCol + widthUnit) {
              patternUnits.push({x: xpos, y: ypos, r: radius});
            }
          }
        }
      }

      setPatternUnit(patternUnits);
    }
  }, [patternsLoaded]);

  useEffect(() => {
    if (patternsLoaded) {
      const units = patternUnits.current.sort(() => Math.random() - 0.5);
      // const units = patternUnits.current;
      let tl = new TimelineLite();
      // tl.to(units, 0.6, {
      //   drawSVG: '100%',
      //   stagger: 0.005,
      //   ease: 'power4.inOut',
      // });
      tl.to(units, 0.6, {
        r: '18',
        opacity: 0.2,
        stagger: 0.005,
        ease: 'power4.inOut',
      });
    }
  }, [patternsLoaded]);

  return (
    <div className={classNames(styles.patternCol)} ref={patternCol}>
      <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
        {patternUnit &&
          patternUnit[0] &&
          patternUnit.map(({x, y, r}, i) => {
            return (
              <circle
                key={i}
                opacity='1'
                strokeOpacity={
                  ['jauneProduit', 'vertProduit'].includes(backgroundColor) ? '0.8' : '0.4'
                }
                cx={x}
                cy={y}
                r={r}
                stroke={['beige', 'white'].includes(backgroundColor) ? '#FF414D' : '#FFFFFF'}
                strokeWidth='8'
                ref={addPatternUnit}
              />
            );
          })}
      </svg>
    </div>
  );
};

export const KeyFigures = ({
  mode,
  keyFigure,
  textColor,
  figureColor,
  title,
  subtitle,
  CTA,
  noPaddingTop,
  noPaddingBottom,
  backgroundColor,
}) => {
  const trigger = useRef(null);
  const content = useRef(null);
  const apparitionAnimPlayed = useRef(false);
  const [triggerApparitionPattern, setTriggerApparitionPattern] = useState(false);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: trigger.current,
      start: 'top bottom-=300',
      // markers: true,
      onEnter: function () {
        if (!apparitionAnimPlayed.current) {
          let tl = new TimelineLite();
          tl.add(() => {
            setTriggerApparitionPattern(true);
          });
          tl.fromTo(
            content.current,
            0.4,
            {
              y: '80',
              opacity: 0,
              ease: 'power4.inOut',
            },
            {
              y: '0',
              opacity: 1,
              ease: 'power4.inOut',
            }
          );
          tl.add(function () {
            apparitionAnimPlayed.current = true;
          });
        }
      },
    });
  }, []);

  return (
    <section
      className={classNames(
        styles.keyfiguresSection,
        styles[mode],
        styles['background' + backgroundColor]
      )}
      ref={trigger}
    >
      {mode === 'primary' ? (
        <div
          className={classNames(
            styles.primaryWrapper,
            {[styles.noPaddingTop]: noPaddingTop},
            {[styles.noPaddingBottom]: noPaddingBottom}
          )}
          ref={content}
        >
          <div className='container'>
            <div className={classNames('row', styles.keyfiguresRow)}>
              {keyFigure && (
                <div
                  className={classNames(
                    'col-12 col-md-4 col-lg-5',
                    styles.figureCol,
                    styles[figureColor]
                  )}
                >
                  <span className={styles.figure}>{keyFigure}</span>
                  <span className={styles.percent}>%</span>
                </div>
              )}
              <div
                className={classNames('col-12 col-md-8 col-lg-7', styles.txtCol, styles[textColor])}
              >
                {title && <div className={styles.titleWrapper}>{render(title)}</div>}
                {subtitle && <div className={styles.subtitleWrapper}>{render(subtitle)}</div>}
                {CTA && CTA[0] && CTA[0].button.map((cta) => <Button key={cta._uid} {...cta} />)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.secondaryWrapper} ref={content}>
          <div className={'container'}>
            {title && (
              <div className={classNames('row', styles.keyfiguresRow)}>
                <div className={classNames('col-12 col-md-4', styles.txtCol, styles[textColor])}>
                  <div className={styles.titleWrapper}>{render(title)}</div>
                </div>
              </div>
            )}

            {keyFigure && (
              <div className={classNames('row', styles.keyfiguresRow)}>
                <div
                  className={classNames('col-12 col-md-4', styles.figureCol, styles[figureColor])}
                >
                  <span className={styles.figure}>{keyFigure}</span>
                  <span className={styles.percent}>%</span>
                </div>
              </div>
            )}

            {subtitle && (
              <div className={classNames('row', styles.keyfiguresRow)}>
                <div className={classNames('col-12 col-md-4', styles.txtCol, styles[textColor])}>
                  <div className={styles.subtitleWrapper}>{render(subtitle)}</div>
                </div>
              </div>
            )}
          </div>

          <div className={classNames(styles.patternContainer)}>
            {triggerApparitionPattern && (
              <>
                <div className={classNames(styles.patternWrapper)}>
                  <PatternCol direction={'toLeft'} backgroundColor={backgroundColor} />
                </div>
                <div className={classNames(styles.patternWrapper)}>
                  <PatternCol backgroundColor={backgroundColor} />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default KeyFigures;

KeyFigures.propTypes = {
  mode: PropTypes.string,
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.object.isRequired,
  keyFigure: PropTypes.string.isRequired,
  figureColor: PropTypes.string,
  textColor: PropTypes.string,
  CTA: PropTypes.array,
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

PatternCol.propTypes = {
  direction: PropTypes.string,
  backgroundColor: PropTypes.string,
};

KeyFigures.defaultProps = {
  mode: 'primary',
  title: '',
  subtitle: '',
  figureColor: 'blue',
  textColor: 'blue',
  backgroundColor: 'white',
  buttonMode: 'primary',
  buttonLabel: 'Button',
};
