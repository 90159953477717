// extracted by mini-css-extract-plugin
export var section = "GlobalFeatures-module--section--39nTG";
export var header = "GlobalFeatures-module--header--2SlHj";
export var select = "GlobalFeatures-module--select--3KnXp";
export var features = "GlobalFeatures-module--features--1CIy0";
export var gridwrapper = "GlobalFeatures-module--gridwrapper--rnXif";
export var link = "GlobalFeatures-module--link--1dGrm";
export var heading = "GlobalFeatures-module--heading--1H3mE";
export var content = "GlobalFeatures-module--content--1ilXs";
export var valueContainer = "GlobalFeatures-module--valueContainer--3tZYU";
export var flag = "GlobalFeatures-module--flag--3bm5i";
export var arrow = "GlobalFeatures-module--arrow--4W_lb";
export var illustration = "GlobalFeatures-module--illustration--3e_HK";
export var shape = "GlobalFeatures-module--shape--3NV7P";
export var circleWrapper = "GlobalFeatures-module--circleWrapper--2tChq";
export var illustrationsWrapper = "GlobalFeatures-module--illustrationsWrapper--3nUiJ";
export var illustrationInnerWrapper = "GlobalFeatures-module--illustrationInnerWrapper--3a4Wd";