import classNames from 'classnames';
import {Link} from 'gatsby';
import Img from 'gatsby-image';
import {getFluidGatsbyImage} from 'gatsby-storyblok-image';
import {Bounce, TimelineLite} from 'gsap';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';

import chevronRight from '@/assets/glyphs/chevronRightWhite.svg';

import * as styles from './CardImage.module.scss';

export const CardImage = ({text, linkText, linkTarget: {cached_url, linktype}, cardImage}) => {
  let arrow = useRef(null);
  let hoverAnimationOn = useRef(false);

  const fluidImage = getFluidGatsbyImage(cardImage.filename, {
    maxWidth: 600,
  });

  if (cached_url && linktype === 'story' && cached_url.startsWith('/')) {
    cached_url = cached_url.substring(1);
  }

  if (cached_url && cached_url.includes('home')) {
    cached_url.replace('home', '');
  }

  const linkIsHovered = () => {
    if (!hoverAnimationOn.current) {
      hoverAnimationOn.current = true;
      let tl = new TimelineLite();
      tl.to(arrow, 0.1, {x: '+=20'})
        .to(arrow, 0.6, {x: '-=20', ease: Bounce.easeOut})
        .add(function () {
          hoverAnimationOn.current = false;
        });
    }
  };

  return (
    <>
      {linktype === 'story' && (
        <Link to={`/${cached_url}`} className={styles.oneCardImage} onMouseEnter={linkIsHovered}>
          <span className={classNames(styles.cardInnerWrapper)}>
            <span className={classNames(styles.cardText)}>
              <p className={classNames(styles.cardTitle)}>{text}</p>
              <p className={classNames(styles.cardCta)}>
                <span>{linkText}</span>
                <span>
                  <img src={chevronRight} alt='' ref={(el) => (arrow = el)} />
                </span>
              </p>
            </span>
            <span className={classNames(styles.gradientLayer)}></span>
            <div className={classNames(styles.imgLayer)}>
              <div className={styles.imgWrapper}>
                <Img
                  fluid={fluidImage}
                  style={{height: '100%', width: '100%'}}
                  imgStyle={{objectFit: 'cover'}}
                />
              </div>
            </div>
          </span>
        </Link>
      )}
      {linktype === 'url' && (
        <a href={cached_url} className={styles.oneCardImage} onMouseEnter={linkIsHovered}>
          <span className={classNames(styles.cardInnerWrapper)}>
            <span className={classNames(styles.cardText)}>
              <p className={classNames(styles.cardTitle)}>{text}</p>
              <p className={classNames(styles.cardCta)}>
                <span>{linkText}</span>
                <span>
                  <img src={chevronRight} alt='' ref={(el) => (arrow = el)} />
                </span>
              </p>
            </span>
            <span className={classNames(styles.gradientLayer)}></span>
            <div className={classNames(styles.imgLayer)}>
              <div className={styles.imgWrapper}>
                <Img
                  fluid={fluidImage}
                  style={{height: '100%', width: '100%'}}
                  imgStyle={{objectFit: 'cover'}}
                />
              </div>
            </div>
          </span>
        </a>
      )}
    </>
  );
};
export default CardImage;

CardImage.propTypes = {
  text: PropTypes.string,
  linkText: PropTypes.string,
  linkTarget: PropTypes.object,
  cardImage: PropTypes.object.isRequired,
};

CardImage.defaultProps = {
  text: '',
  linkText: '',
  linkTarget: {},
  cardImage: {},
};
