import classNames from 'classnames';
import Img from 'gatsby-image';
import {getFluidGatsbyImage} from 'gatsby-storyblok-image';
import {gsap, TimelineLite} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
// import LottieIcon from '@/components/LottieIcon/LottieIcon';
import Lottie from 'react-lottie-player';
import {render} from 'storyblok-rich-text-react-renderer';

import garantie from '@/assets/components/iconsSection/garantie.json';
import sablier from '@/assets/components/iconsSection/sablier.json';
import zen from '@/assets/components/iconsSection/zen.json';

import * as styles from './CardIcon.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

const StaticIcon = ({icon}) => {
  const fluidImage = getFluidGatsbyImage(icon.filename, {
    maxWidth: 80,
  });
  return (
    <div className={styles.staticIconWrapper}>
      <Img fluid={fluidImage} />
    </div>
  );
};
const OneIcon = ({lottieName, play}) => {
  const lottieData = useRef({});
  const [playingSegment, setPlayingSegment] = useState('intro');
  const [introPlayed, setIntroPlayed] = useState(false);

  const handleComplete = () => {
    if (!introPlayed) {
      setIntroPlayed(true);
    }
  };

  useEffect(() => {
    if (introPlayed && playingSegment === 'intro') {
      setPlayingSegment('loop');
    }
  }, [introPlayed, playingSegment]);

  switch (lottieName) {
    case 'sablier':
      lottieData.current.data = sablier;
      lottieData.current.sequence = {intro: [0, 120], loop: [120, 240]};
      break;
    case 'zen':
      lottieData.current.data = zen;
      lottieData.current.sequence = {intro: [0, 25], loop: [25, 500]};
      break;
    case 'garantie':
      lottieData.current.data = garantie;
      lottieData.current.sequence = {intro: [0, 180], loop: [180, 360]};
      break;
    default:
      lottieData.current.data = sablier;
      lottieData.current.sequence = {intro: [0, 120], loop: [120, 340]};
  }

  return (
    <div className={styles.lottieWrapper}>
      <Lottie
        animationData={lottieData.current.data}
        segments={lottieData.current.sequence[playingSegment]}
        onLoopComplete={() => handleComplete()}
        play={play}
        loop
      />
    </div>
  );
};
const CardIcon = ({cardTheme, cardBackgroundColor, cardContent}) => {
  const section = useRef(null);
  const apparitionAnimPlayed = useRef(false);
  const animatedElementsRefs = useRef([]);
  const [lottiePlay, setLottiePlay] = useState(false);

  const addElement = (el) => {
    if (el && !animatedElementsRefs.current.includes(el)) {
      animatedElementsRefs.current.push(el);
    }
  };

  useEffect(() => {
    ScrollTrigger.create({
      trigger: section.current,
      start: 'top bottom-=300',
      // markers: true,
      onEnter: function () {
        if (!apparitionAnimPlayed.current) {
          let tl = new TimelineLite();
          tl.to(animatedElementsRefs.current, 0.4, {
            y: '0',
            opacity: 1,
            stagger: 0.1,
            ease: 'power4.inOut',
          });
          tl.add(function () {
            apparitionAnimPlayed.current = true;
            setLottiePlay(true);
          });
        }
      },
    });
  }, []);

  if (!cardContent) {
    return null;
  }

  return (
    <div
      className={classNames(styles.oneCardIcon, styles['background' + cardBackgroundColor])}
      ref={section}
    >
      <div className={styles.cardIconWrapper} ref={addElement}>
        {cardContent.lottie && <OneIcon lottieName={cardContent.lottie} play={lottiePlay} />}
        {!cardContent.lottie && cardContent.icon && <StaticIcon icon={cardContent.icon} />}
      </div>
      <div className={styles.cardTitleWrapper}>{render(cardContent.title)}</div>
      <div className={classNames(styles.cardContentWrapper, styles['theme' + cardTheme])}>
        {render(cardContent.content)}
      </div>
    </div>
  );
};

export default CardIcon;

CardIcon.propTypes = {
  cardBackgroundColor: PropTypes.string,
  cardTheme: PropTypes.string,
  cardContent: PropTypes.object,
};
CardIcon.defaultProps = {
  cardBackground: 'blue',
  colorTheme: 'rougeDistrib',
};
OneIcon.propTypes = {
  lottieName: PropTypes.string,
  play: PropTypes.bool,
};
StaticIcon.propTypes = {
  icon: PropTypes.object,
};
