// extracted by mini-css-extract-plugin
export var parallaxComponent = "Parallax-module--parallaxComponent--3AAX0";
export var titleCol = "Parallax-module--titleCol--2l8Ek";
export var contentCol = "Parallax-module--contentCol--3pjZs";
export var rowBodyText = "Parallax-module--rowBodyText--iuWEv";
export var rowTitle = "Parallax-module--rowTitle--2G2OC";
export var secondary = "Parallax-module--secondary--3mqIl";
export var parallaxTextContainer = "Parallax-module--parallaxTextContainer--3dwmi";
export var parallaxImgsContainer = "Parallax-module--parallaxImgsContainer--1AesF";
export var parallaxImgsRow = "Parallax-module--parallaxImgsRow--1xGcn";
export var imageWrapper = "Parallax-module--imageWrapper--YGXHa";
export var image0 = "Parallax-module--image0--3urxa";
export var image1 = "Parallax-module--image1--1byQf";
export var image2 = "Parallax-module--image2--kxZGW";
export var image3 = "Parallax-module--image3--1K-uk";
export var image4 = "Parallax-module--image4--n4J5y";