// extracted by mini-css-extract-plugin
export var oneCardNumber = "CardNumber-module--oneCardNumber--1nGT8";
export var cardTitle = "CardNumber-module--cardTitle--3Eqda";
export var white = "CardNumber-module--white--37TWQ";
export var beige = "CardNumber-module--beige--2rcZc";
export var blue = "CardNumber-module--blue--1qCYl";
export var vertProduit = "CardNumber-module--vertProduit--ydFDC";
export var jauneProduit = "CardNumber-module--jauneProduit--Qul9L";
export var orangeProduit = "CardNumber-module--orangeProduit--128f3";
export var rougeDistrib = "CardNumber-module--rougeDistrib--V21q7";
export var figure = "CardNumber-module--figure--2bGlt";
export var percent = "CardNumber-module--percent--1XB1y";
export var cardContent = "CardNumber-module--cardContent--2g_Ny";