// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import classNames from 'classnames';
import {gsap} from 'gsap';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {render} from 'storyblok-rich-text-react-renderer';
// import SwiperCore, {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import CircleSvg from '@/assets/assetsJsx/CircleSvg.js';
import CardIcon from '@/components/CardIcon/CardIcon';
import Form from '@/components/Form/Form';
import useWindowSize from '@/hooks/UseWindowSize';

import * as styles from './ContactSignup.module.scss';

// install Swiper modules
// SwiperCore.use([Pagination]);

const Carousel = ({cardsIcon, themeColor}) => {
  const filteredCards = cardsIcon.filter(
    (cardIcon) => cardIcon.oneLottieCard[0].lottie || cardIcon.oneLottieCard[0].icon?.filename
  );

  const windowSize = useWindowSize();
  let [slidesPerView, setSlidePerView] = useState(1.1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const swiperRef = useRef(null);

  //slide to left
  const nextEnd = (s) => {
    const currentIndex = s.realIndex;
    if (currentIndex > 0) {
      const slidesToFadeoff = [];
      for (let i = 0; i < currentIndex; i++) {
        slidesToFadeoff.push(s.slides[i]);
      }
      gsap.to(slidesToFadeoff, 0.2, {opacity: 0, ease: 'power4.inOut'});
    }
    setCurrentSlide(s.activeIndex);
  };
  //slide to right
  const prevEnd = (s) => {
    const currentIndex = s.realIndex;
    const slidesToFadeoff = [];
    for (let i = currentIndex; i < s.slides.length; i++) {
      slidesToFadeoff.push(s.slides[i]);
    }
    gsap.to(slidesToFadeoff, 0.2, {opacity: 1, ease: 'power4.inOut'});
    setCurrentSlide(s.activeIndex);
  };
  //slide to right
  const handleSwitchSlide = (e, i) => {
    e.preventDefault();
    swiperRef.current?.swiper.slideTo(i);
  };

  useEffect(() => {
    if (windowSize.width) {
      if (windowSize.width >= 990) {
        setSlidePerView(1.6);
      } else if (window.width >= 1400) {
        setSlidePerView(2);
      }
    }
  }, [windowSize]);

  return (
    <>
      <div className={classNames(styles.paginationWrapper)}>
        {filteredCards.map((_, i) => {
          return (
            <button
              key={'pagination' + i}
              data-index={i}
              onClick={(event) => handleSwitchSlide(event, i)}
              className={classNames(styles.paginationDot, {
                [styles.currentSlide]: currentSlide === i,
              })}
            ></button>
          );
        })}
      </div>
      <Swiper
        ref={swiperRef}
        slidesPerView={slidesPerView}
        spaceBetween={30}
        centeredSlides={true}
        className={styles.carouselInnerWrapper}
        onSlideNextTransitionStart={(s) => nextEnd(s)}
        onSlidePrevTransitionStart={(s) => prevEnd(s)}
      >
        {filteredCards.map((card) => {
          return (
            <SwiperSlide
              className={classNames(styles.cardIconWrapper, styles[themeColor])}
              key={card._uid}
            >
              <CardIcon
                cardContent={card.oneLottieCard[0]}
                cardBackgroundColor={card.cardBackgroundColor}
                cardTheme={card.cardTheme}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

const ContactSignup = ({
  lang,
  title,
  individualsLinkText,
  typeOfForm,
  cardsIcon,
  themeColor,
  dataGlobalElements,
  translatedStrings,
}) => {
  const carouselCol = useRef(null);
  const blueWrapper = useRef(null);

  useEffect(() => {
    if (window.innerWidth < 990) {
      const heightCarousel = carouselCol.current.clientHeight;
      gsap.to(blueWrapper.current, 0, {height: heightCarousel, ease: 'power4.inOut'});
    }
  }, []);

  return (
    <section className={styles.contactSignupSection}>
      <div className={classNames('container', styles.formContainer)}>
        <div className={classNames('row')}>
          <div className={classNames('col-12 col-md-6 offset-md-1', styles.formCol)}>
            <div className={classNames(styles.titleWrapper, styles[themeColor])}>
              {render(title)}
            </div>
            {typeOfForm === 'contact' && (
              <>
                <div className={classNames(styles.link)}>
                  <br />
                  <p>{render(individualsLinkText)}</p>
                </div>
              </>
            )}
            <div className={styles.formWrapper}>
              <Form
                typeOfForm={typeOfForm}
                dataGlobalElements={dataGlobalElements}
                translatedStrings={translatedStrings}
                lang={lang}
              />
            </div>
          </div>
          <div
            className={classNames('col-12 col-md-4 offset-md-1', styles.carouselCol)}
            ref={carouselCol}
          >
            <div className={styles.carouselWrapper}>
              <Carousel cardsIcon={cardsIcon} themeColor={themeColor} />
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(styles.sidePannelWrapper)} ref={blueWrapper}>
        <div className={styles.blueWrapper}>
          <div className={classNames(styles.circleWrapper, styles[themeColor])}>
            <CircleSvg />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSignup;

ContactSignup.propTypes = {
  lang: PropTypes.string,
  themeColor: PropTypes.string,
  title: PropTypes.object,
  individualsLinkText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  typeOfForm: PropTypes.string,
  cardsIcon: PropTypes.array,
  dataGlobalElements: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  translatedStrings: PropTypes.object,
};

Carousel.propTypes = {
  cardsIcon: PropTypes.array,
  themeColor: PropTypes.string,
};
