// extracted by mini-css-extract-plugin
export var keyfiguresSection = "KeyFigures-module--keyfiguresSection--NwywB";
export var backgroundwhite = "KeyFigures-module--backgroundwhite--2HPEl";
export var backgroundbeige = "KeyFigures-module--backgroundbeige--2detM";
export var backgroundblue = "KeyFigures-module--backgroundblue--1n9Ih";
export var backgrounddarkblue = "KeyFigures-module--backgrounddarkblue--2WG8f";
export var backgroundvertProduit = "KeyFigures-module--backgroundvertProduit--8SwD2";
export var backgroundjauneProduit = "KeyFigures-module--backgroundjauneProduit--yYFR4";
export var backgroundorangeProduit = "KeyFigures-module--backgroundorangeProduit--3RY6P";
export var backgroundrougeDistrib = "KeyFigures-module--backgroundrougeDistrib--2xX6y";
export var keyfiguresRow = "KeyFigures-module--keyfiguresRow--3oBZ5";
export var txtCol = "KeyFigures-module--txtCol--1G632";
export var white = "KeyFigures-module--white--2GjVj";
export var beige = "KeyFigures-module--beige--2N5pj";
export var blue = "KeyFigures-module--blue--6qX5S";
export var vertProduit = "KeyFigures-module--vertProduit--2GJcn";
export var jauneProduit = "KeyFigures-module--jauneProduit--QtTRq";
export var orangeProduit = "KeyFigures-module--orangeProduit--y0hYp";
export var rougeDistrib = "KeyFigures-module--rougeDistrib--1XbgH";
export var titleWrapper = "KeyFigures-module--titleWrapper--38dXh";
export var subtitleWrapper = "KeyFigures-module--subtitleWrapper--3EdO6";
export var figureCol = "KeyFigures-module--figureCol--1xGa4";
export var figure = "KeyFigures-module--figure--1bYgK";
export var percent = "KeyFigures-module--percent--xK8G_";
export var primaryWrapper = "KeyFigures-module--primaryWrapper--ydKhr";
export var noPaddingTop = "KeyFigures-module--noPaddingTop--2YsnR";
export var noPaddingBottom = "KeyFigures-module--noPaddingBottom--1QcYv";
export var buttonWrapper = "KeyFigures-module--buttonWrapper--1q7iG";
export var secondaryWrapper = "KeyFigures-module--secondaryWrapper--1O2u4";
export var patternContainer = "KeyFigures-module--patternContainer--3_vMv";
export var patternWrapper = "KeyFigures-module--patternWrapper--2pKMC";
export var patternCol = "KeyFigures-module--patternCol--1Wfgo";