// extracted by mini-css-extract-plugin
export var oneCardIcon = "CardIcon-module--oneCardIcon--2WsQ8";
export var backgroundwhite = "CardIcon-module--backgroundwhite--3Hayy";
export var backgroundbeige = "CardIcon-module--backgroundbeige--1iXAZ";
export var backgroundblue = "CardIcon-module--backgroundblue--6Y7Bp";
export var backgrounddarkblue = "CardIcon-module--backgrounddarkblue--3tQz3";
export var backgroundvertProduit = "CardIcon-module--backgroundvertProduit--Jt0yd";
export var backgroundjauneProduit = "CardIcon-module--backgroundjauneProduit--1iSaZ";
export var backgroundorangeProduit = "CardIcon-module--backgroundorangeProduit--ichFx";
export var backgroundrougeDistrib = "CardIcon-module--backgroundrougeDistrib--3Llnz";
export var cardIconWrapper = "CardIcon-module--cardIconWrapper--1Ul82";
export var lottieWrapper = "CardIcon-module--lottieWrapper--1W4AC";
export var staticIconWrapper = "CardIcon-module--staticIconWrapper--1U-V5";
export var cardTitleWrapper = "CardIcon-module--cardTitleWrapper--2mTdD";
export var cardContentWrapper = "CardIcon-module--cardContentWrapper--3FM8j";
export var themebeige = "CardIcon-module--themebeige--1joal";
export var themeblue = "CardIcon-module--themeblue--2jrYl";
export var themedarkBlue = "CardIcon-module--themedarkBlue--FIsDr";
export var themeorangeProduit = "CardIcon-module--themeorangeProduit--3lx4Y";
export var themejauneProduit = "CardIcon-module--themejauneProduit--3CSiK";
export var themevertProduit = "CardIcon-module--themevertProduit--1-Z7e";
export var themerougeDistrib = "CardIcon-module--themerougeDistrib--3Ds7Z";